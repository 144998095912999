import styles from './AllInstallmentsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './AllInstallmentsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
function AllInstallmentsTable(props:any) {
    const {t}= useTranslation()
    const { items ,user } = props
    // const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    return (
        <>
            
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Product Name')}</th>
                    <th>{t('Current installment value')}</th>
                    <th>{t("Paid From Installment")}</th>
                    <th>{t("Remain Amount")}</th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        items &&items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.product_name} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.price} {t('AED')}</p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.paid_price} {t('AED')} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.remain_price} {t('AED')} </p>
                                </td>
                                <td>
                                    <Link to={`/our-clients/month-installments/${user?.id}?month_installment=${item?.id}`} className={styles['appointments__table-link']}>
                                        {t('More')}
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default AllInstallmentsTable
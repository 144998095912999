import { useTranslation } from 'react-i18next'
import styles from './Testments.module.css'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as PatientsMenuIcon} from 'assets/icons/patientsMenuIcon.svg'
import userImg from 'assets/imgs/userImg.png'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { ItemsListPagination } from 'components/Global/Elements/ItemsListPagination/ItemsListPagination'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import AllProductsTable from 'components/Tables/AllProductsTable/AllProductsTable'
import AllTestmentsTable from 'components/Tables/AllTestmentsTable/AllTestmentsTable'
function Testments() {
  const {t} =useTranslation()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const [searchTerm,setSearchTerm]=useState('')
  const [itemsCount,setItemsCount]=useState('0')
  const [items,setItems]=useState([])
  const [currentPage,setCurrentPage]=useState(1)
  const [pages,setPages]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  function getAllItems(){
      setIsLoading(true)
      axiosConfig.get(`/testment/all-testments-without-pagination?page=${currentPage}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            
        }
    }).then(res=>{
          setIsLoading(false)
          setItems(res.data?.data)
          // setPages(res.data?.data?.pages)
          let deletedNumber:any = [...Array(res.data.data.length)]
          setItemsCount(res.data.data.length)
          setIsDeleting(deletedNumber)
      }).catch(err=>{
          setIsLoading(false)
          console.log(err)
      })
  }
  function deleteItem(deltedId:String ,index:any){
    let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
      axiosConfig.delete(`/testment/delete-testment/${deltedId}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
            let modifiedDelete:[Boolean]=[...isDeleting]
            modifiedDelete[index]=false
            setIsDeleting(modifiedDelete)
            toast.success(t('Item Deleted Successfully'))
            getAllItems()
      }).catch(err=>{
          let modifiedDelete:[Boolean]=[...isDeleting]
          modifiedDelete[index]=false
          setIsDeleting(modifiedDelete)
          toast.error(err?.response?.data?.message)
          console.log(err)
      })
  }
  useEffect(()=>{
    getAllItems()
  },[currentPage,searchTerm])
  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          <div className={styles['page-header']}>
            <h1 className={styles['page-header__title']}>
              {t('Testments')}
              <span className={styles['page-header__title-count']}>{itemsCount}</span>
            </h1>
            
            {
              user?.permission_type=='admin' &&
                <Link to='add-testment' className={styles['page-header__link']}>
                  {t('Add New Testment')}
                  <PlusIcon/>
                </Link>
            }
          </div>
        </Container>
        <AllTestmentsTable items={items} deleteItem={deleteItem} isDeleting={isDeleting}/>

      </section>
    </>
  )
}

export default Testments
import styles from './AllTecnicnianTestamentsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import './AllTecnicnianTestamentsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
function AllTecnicnianTestamentsTable(props:any) {
    const { items, provider, deleteItem, isDeleting, receiveTestment } = props
    const {t}= useTranslation()
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const [receivingAmount ,setReceivingAmount] = useState<any>([])
    const [isLoading ,setIsLoading] = useState(false)
    const params = useParams()
    
    function handleChangePayingAmount(value:any,index:any,remainCount:any){
        if(Number(value) > Number(remainCount) ){ 
            value = remainCount
        }
        let modifiedReceivingAmount = [...receivingAmount]
        modifiedReceivingAmount[index]=value
        setReceivingAmount(modifiedReceivingAmount)
    }   
    
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Piece Name')}</th>
                    <th>{t('Pieces Count')}</th>
                    <th>{t("Remaining Count")}</th>
                    <th>{t("Return Count")}</th>
                    <th>{t('Used Count')}</th>
                    <th>{t("Received At")}</th>
                    {provider&&<th></th>}
                    {provider&&<th></th>}
                </thead>
                <tbody>
                    {
                        items &&items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.name} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.received_count} {t('Piece')} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.remain_count} {t('Piece')} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.return_count} {t('Piece')} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.used_count} {t('Piece')} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.received_date} </p>
                                </td>
                                {
                                    provider&&
                                    <>
                                        <td>
                                            {
                                                user?.permission_type=='admin' &&
                                                <>
                                                
                                                <button onClick={()=>{deleteItem(provider?.id,index,item?.id)}} disabled={isDeleting[index]}
                                                    className={styles['appointments__table-delete']}>
                                                    {
                                                        !isDeleting[index]?t('Delete')
                                                        :<ReactLoading type={"spin"} color={'red'} height={20} width={20} />
                                                    }
                                                </button>
                                                {/* {
                                                    item?.received_status=='received'?
                                                    <button onClick={()=>{receiveTestment(provider?.id,index,item?.id,'not_received',item?.received_count-item?.used_count)}}
                                                        className={styles['appointments__table-received']}>
                                                        {t('Received')}
                                                    </button>
                                                    :
                                                    <button onClick={()=>{receiveTestment(provider?.id,index,item?.id,'received',item?.received_count-item?.used_count)}}
                                                        className={styles['appointments__table-not-received']}>
                                                        {t('Not Received')}
                                                    </button>
                                                    } */}
                                                </>
                                            }
                                        </td>
                                        <td>
                                            {
                                                item?.remain_count > 0 &&
                                                <div className={styles['form__content-form-input-wrapper']}>
                                                    <input  
                                                        type='number'
                                                        className={styles['form__content-form-input']}
                                                        placeholder={`${t('Received Count')}`}
                                                        value={receivingAmount?.[index]}
                                                        onChange={(e)=>handleChangePayingAmount(e.target.value, index, item?.remain_count)}
                                                    />
                                                    <button onClick={()=>{receiveTestment(provider?.id,index,item?.id,'received',receivingAmount?.[index], item)}}
                                                        className={styles['appointments__table-received']}>
                                                        {t('Received')}
                                                    </button>
                                                </div>
                                            }
                                        </td>
                                    </>
                                }
                                
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default AllTecnicnianTestamentsTable
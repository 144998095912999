import { Navbar } from 'react-bootstrap'
import styles from './NavBar.module.css'
import './Navbar.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as DashboardIcon} from 'assets/icons/DashboardIcon.svg'
import {ReactComponent as PatientsMenuIcon} from 'assets/icons/patientsMenuIcon.svg'
import {ReactComponent as AppointmentIcon} from 'assets/icons/appointmentIcon.svg'
import {ReactComponent as ExaminationIcon} from 'assets/icons/examinationIcon.svg'
import {ReactComponent as LogoutIcon} from 'assets/icons/logoutIcon.svg'
import {ReactComponent as MedicineIcon} from 'assets/icons/medicineIcon.svg'
import {ReactComponent as PerspectionIcon} from 'assets/icons/perspectionIcon.svg'
import {ReactComponent as NotesIcon} from 'assets/icons/notesIcon.svg'
import {ReactComponent as PrecautionsIcon} from 'assets/icons/precautionsIcon.svg'
import {ReactComponent as FinancialsIcon} from 'assets/icons/financialsIcon.svg'
import {ReactComponent as PatientInfoIcon} from 'assets/icons/patientInfoIcon.svg'
import {ReactComponent as AlertIcon} from 'assets/icons/alertIcon.svg'
import AfyaLogo from 'assets/imgs/AfyaLogo.png'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import userImg from 'assets/imgs/userImg.png'
import {ReactComponent as MenuIcon} from 'assets/icons/menuIcon.svg'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { changeTokenAction, changeUserDetails } from 'reduxStore/Global/GlobalActions'
import { useSelector } from 'react-redux'
import blankImage from 'assets/imgs/blank.png'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'
import { useEffect, useState } from 'react'

interface CustomComponentPropsType{
    patient?:any,
    isNavbarOpen:boolean
}
function NavBar(props:CustomComponentPropsType) {
    const {isNavbarOpen ,patient} =props
    const {t} =useTranslation()
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const selector =useSelector((state:any)=>state?.GlobalReducer)
    const [notificationCount ,setNotificationCount] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    function logout(){
        navigate('/')
        localStorage.removeItem('token')
        dispatch(changeTokenAction(''))
        dispatch(changeUserDetails({}))
    }
    useEffect(()=>{
        setNotificationCount(selector?.notification_count)
    },[selector])
    return (
        <Navbar expand='lg' className={`${styles['navbar']} ${isNavbarOpen?styles['navbar--open']:''}`}>
            <ul className={`${styles['navbar__nav-list']} navbar__nav-list`}>
                <li>
                    <NavLink end to='/' className={styles['navbar__nav-link-wrapper']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Dashboard')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/our-clients' className={styles['navbar__nav-link-wrapper']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Customers')}</span>
                    </NavLink>
                </li>
                {/* {
                    user?.permission_type=='accounts' &&
                } */}
                <li>
                    <NavLink to='/technicians' className={styles['navbar__nav-link-wrapper']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Technicians')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/orders' className={styles['navbar__nav-link-wrapper']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Orders')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/payments' className={styles['navbar__nav-link-wrapper']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Payments')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/reports' className={styles['navbar__nav-link-wrapper']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Reports')}</span>
                    </NavLink>
                </li>
                {
                    user?.permission_type=='admin' &&
                    <>
                        <li>
                            <NavLink to='/admins' className={styles['navbar__nav-link-wrapper']}>
                                <span className={styles['navbar__nav-link-text']}>{t('Admins')}</span>
                            </NavLink>
                        </li>
                    </>
                }

                <li className={styles['navbar__nav-item--margin']}>
                    <ChangeLangueList/>
                </li>
                <li >
                    <NavLink end to='/notifications' className={styles['navbar__nav-link-wrapper']}>
                        <AlertIcon className={styles['navbar__nav-link-icon']}/> {notificationCount}
                    </NavLink>
                </li>
                {/* <li className={`${styles['navbar__nav-item--responsivess']} `}>
                    <div className={styles['sidebar__client-info-wrapper']}>
                        <div className={styles['sidebar__client-info-content-wrapper']}>
                            <p className={styles['sidebar__client-info-name']}>{user?.name}</p>
                            <p className={styles['sidebar__client-info-text']}>
                            {
                                user?.permission_type=='admin'?t('Admin'):
                                user?.permission_type=='editor'?t('Editor'):
                                user?.permission_type=='viewer'?t('Viewer'):''
                            }
                            </p>
                        </div>
                        <img src={patient?.image?patient?.image:blankImage} className={styles['sidebar__client-info-img']}/>
                    </div>
                </li> */}
                <li className={`${styles['navbar__nav-item--responsivess']} `}>
                    <div className={styles['navbar__nav-item-profile-list-wrapper']}>
                        <img src={patient?.image?patient?.image:blankImage} className={styles['sidebar__client-info-img']}/>
                        <ul className={styles['navbar__nav-item-profile-list']}>
                            <li className={`${styles['navbar__nav-item-profile-item']} ${styles['navbar__nav-item-profile-item--fixed-color']}`}>
                                <div className={styles['sidebar__client-info-content-wrapper']}>
                                <p className={styles['sidebar__client-info-name']}>{user?.name}</p>
                                <p className={styles['sidebar__client-info-text']}>
                                {
                                    user?.permission_type=='admin'?t('Admin'):
                                    user?.permission_type=='editor'?t('Editor'):
                                    user?.permission_type=='viewer'?t('Viewer'):
                                    user?.permission_type=='technical'?t('Technical Support'):
                                    user?.permission_type=='accounts'?t('Accounts'):''
                                }
                                </p>
                            </div>
                            </li>
                            
                            {
                                user?.permission_type=='admin' &&
                                <>
                                    <li className={styles['navbar__nav-item-profile-item']}>
                                        <NavLink to='/settings' className={styles['navbar__nav-link-wrapper']}>
                                            <span className={styles['navbar__nav-link-text']}>{t('Settings')}</span>
                                        </NavLink>
                                    </li>
                                </>
                            }
                            <li className={styles['navbar__nav-item-profile-item']}>
                                <NavLink to='/products' className={styles['navbar__nav-link-wrapper']}>
                                    <span className={styles['navbar__nav-link-text']}>{t('Products')}</span>
                                </NavLink>
                            </li>
                            <li className={styles['navbar__nav-item-profile-item']}>
                                <NavLink to='/messages' className={styles['navbar__nav-link-wrapper']}>
                                    <span className={styles['navbar__nav-link-text']}>{t('Messages')}</span>
                                </NavLink>
                            </li>
                            <li className={styles['navbar__nav-item-profile-item']}>
                                <NavLink to='/testments' className={styles['navbar__nav-link-wrapper']}>
                                    <span className={styles['navbar__nav-link-text']}>{t('Testments')}</span>
                                </NavLink>
                            </li>
                            <li className={styles['navbar__nav-item-profile-item']}>
                                <NavLink to='/states' className={styles['navbar__nav-link-wrapper']}>
                                    <span className={styles['navbar__nav-link-text']}>{t('States')}</span>
                                </NavLink>
                            </li>
                            <li className={styles['navbar__nav-item-profile-item']}>
                                <button type='button' onClick={logout} className={`${styles['navbar__nav-link-wrapper']} ${styles['navbar__nav-link-wrapper--logout']}`}>
                                    <span className={styles['navbar__nav-link-text']}>{t('Logout')}</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </Navbar>
    )
}

export default NavBar
import React, { useEffect } from 'react'
import i18next from 'i18next'
// import Cookies from 'js-cookie'
import { useDispatch} from 'react-redux'
import { changeLanguageAction } from 'reduxStore/Global/GlobalActions'
import styles from './ChangeLangueList.module.css'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'

type LanguageType = {
    code:string,
    name:string,
    country_code:string,
    dir:string
}
function ChangeLangueList(props:any) {
    const {type}=props
    const languages=[
        {
            code:'ar',
            name:'العربية',
            country_code:'sa',
            dir:'rtl'
        },
        {
            code:'en',
            name:'english',
            country_code:'gb',
            dir:'ltr'
        },
        
    ]
    const dispatch= useDispatch()
    const {t}= useTranslation()
    const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
    const currentLanguageCode:string = cookies.i18next || 'ar'
    const currentLanguage:(LanguageType|undefined) = languages.find(l=> l.code === currentLanguageCode)
    useEffect(()=>{
        document.body.dir=currentLanguage?.dir ||'rtl'
    },[currentLanguage])
    let changeLanguage=(code:string)=>{
        // console.log('cookies?.i18nextcookies?.i18next',cookies?.i18next)
        i18next.changeLanguage(code)
        setCookie('i18next',code)
        dispatch(changeLanguageAction(code))
        // changeLanguageAction(dispatch,code)
        window.location.reload()
    }
    // function closeNavbarLinks(){
    //     document.querySelector('.js-navbar__more-links-wrapper').classList.remove('navbar__more-links-wrapper--open')
    // }
    return (
        <div className={styles['language__items-wrapper']}>
            <button 
            className={`${styles['language__items']} ${type=='light'?styles['language__items-light']:''}`}
            onClick={()=>{changeLanguage(cookies?.i18next=='en'?'ar':'en')}}>
                {
                    cookies?.i18next=='en' ?'العربية' :'English'
                }
                {/* {t('Arabic')} */}
            </button>
            {/* <button 
            className={`${styles['language__items']} ${cookies?.i18next=='en'?styles['language__items--active']:''}`}
            onClick={()=>{changeLanguage('en')}}>
                {t('English')}
            </button> */}
        </div>
    )
}

export default ChangeLangueList
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './Footer.module.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { emailConnect, whtsAppConnect } from 'utils/features'
import './Footer.css'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'
function Footer() {
    const {t} =useTranslation()
  return (
    <>
        {/* <Subscribe/> */}
        <footer data-aos-duration='800' data-aos-once='true'>

        </footer>
    </>
  )
}

export default Footer
import styles from './AllOrdersTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './AllOrdersTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
function AllOrdersTable(props:any) {
    const {t}= useTranslation()
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const { items ,deleteItem,isDeleting,providers,assignOrderToProvider } = props
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Order No')}</th>
                    <th>{t('Client Name')}</th>
                    <th>{t('Order Price')}</th>
                    <th>{t('Order Status')}</th>
                    <th>{t("Receipt Number")}</th>
                    <th>{t('Whatsapp Number')}</th>
                    <th>{t('Address')}</th>
                    <th>{t('Technician')}</th>
                    {/* <th>{t('Testaments')}</th> */}
                    <th>{t('Created At')}</th>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <Link to={`/orders/order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                        {item?.item_number}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/our-clients/client-details/${item?.user?.id}`} className={styles['appointments__table-link']}>
                                        {item?.user?.name}
                                    </Link>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.order_required_price} {t('AED')}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>
                                        {
                                            item?.status ==1 ?t('New'):
                                            item?.status ==2 ?t('Completed'):
                                            item?.status ==3 ?t('Cancelled'):
                                            item?.status ==4 ?t('Reasigned'):
                                            ''
                                        }
                                    </p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.receipt_number}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.user?.whatsapp_number}</p>
                                </td>
                                <td>
                                    <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${item?.user_latitude},${item?.user_longitude}`} className={styles['form__content-form-input']}>
                                        <GoogleIcon className={styles['form__content-form-input-icon']}/>
                                    </a>
                                </td>
                                <td>
                                    <select className={styles['form__content-form-input']}
                                        onChange={(e)=>{assignOrderToProvider(item?.id,e.target.value,index)}}
                                        value={item?.provider?.id}
                                    >
                                        <option value=''>{t('Please Select Technician')}</option>
                                        {
                                            providers?.map((provider:any)=>(
                                                <option value={provider?.id}>{provider?.name}</option>
                                            ))
                                        }
                                    </select>
                                </td>
                                {/* <td>
                                    <Link to={`order-testments/${item?.id}`} className={styles['appointments__table-link']}>
                                        {t('Testaments')}
                                    </Link>
                                </td> */}
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.created_at}</p>
                                </td>
                                <td>
                                    <Link to={`order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                        {t('Order Details')}
                                    </Link>
                                </td>
                                <td>
                                    {
                                        user?.permission_type!='viewer' &&
                                        <Link to={`update-order/${item?.id}`} className={styles['appointments__table-link']}>
                                            <PencilIcon className={styles['appointments__table-link-icon']}/>
                                            {t('Update Order')}
                                        </Link>
                                    }
                                    {
                                        user?.permission_type=='admin' &&
                                        <button onClick={()=>{deleteItem(item?.id,index)}} disabled={isDeleting[index]}
                                            className={styles['appointments__table-delete']}>
                                            {
                                                !isDeleting[index]?t('Delete')
                                                :<ReactLoading type={"spin"} color={'red'} height={20} width={20} />
                                            }
                                        </button>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default AllOrdersTable
import styles from './ClientsReportsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './ClientsReportsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useState } from 'react'
function ClientsReportsTable(props:any) {
    const {month ,year,searchTerm} = props
    const {t} = useTranslation()
    const [items,setItems]=useState([])
    const [currentPage,setCurrentPage]=useState(1)
    const [pages,setPages]=useState(null)
    function getAllItems(){
        axiosConfig.get(`/user/all-users-without-pagintaion-full-data?page=${currentPage}&month=${month}&year=${year}&name=${searchTerm}`,{
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(res=>{
            setItems(res.data?.data)
            // setPages(res.data?.data?.pages)
        }).catch(err=>{
            console.log(err)
        })
    }
    useEffect(()=>{
        getAllItems()
    },[month,year,searchTerm])
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Client Name')}</th>
                    <th>{t('Phone Number')}</th>
                    <th>{t('Whatsapp Number')}</th>
                    <th>{t('Email')}</th>
                    <th>{t('State')}</th>
                    <th>{t('District')}</th>
                    <th>{t('Joining Date')}</th>
                    {/* <th>{t('Subscription Price')}</th> */}
                    <th></th>
                </thead>
                <tbody>
                    {
                        items && items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.name}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.phone_number}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.whatsapp_number}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.email}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.state}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.district}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.joining_date}</p>
                                </td>
                                {/* <td>
                                    <p className={styles['appointments__table-name']}>{item?.subscription_period}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.subscription_price}</p>
                                </td> */}
                                <td>
                                    <Link to={`/our-clients/client-details/${item?.id}`} className={styles['appointments__table-link']}>
                                        {t('More')}
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default ClientsReportsTable
import styles from './AllClientsInstallmentsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import './AllClientsInstallmentsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
function AllClientsInstallmentsTable(props:any) {
    const {t}= useTranslation()
    const { items ,getUserInstallments ,deleteItem,isDeleting,getAllUsers } = props
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const [payingAmount ,setPayingAmount] = useState<any>([])
    const params = useParams()
    const [isLoading ,setIsLoading] = useState(false)
    
    function payInstallment(installmentId:String,index:any){
        if(!payingAmount?.[index]){
            toast.error(t('Paying Amount Is Required'))
            return
        }
        setIsLoading(true)
        
        let modifiedPayingAmount = [...payingAmount]
        modifiedPayingAmount[index]=0
        setPayingAmount(modifiedPayingAmount)
        const formData  = new FormData()
        formData.append('paying_amount',payingAmount?.[index])
        formData.append('installment_id',String(installmentId))
        axiosConfig.post(`/user/pay-installment-for-user/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
        }).then(res=>{
            setIsLoading(false)
            toast.success(t(`User Installment Paid Successfully Successfully`))
            getUserInstallments()
            setPayingAmount([])
            let payingAmountItems=items?.length ||0
            setPayingAmount([...Array(payingAmountItems)])
        }).catch(err=>{
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        })
    }
    function handleChangePayingAmount(value:any,index:any,paidAmount:any,price:any){
        if(Number(value)+Number(paidAmount)>Number(price)){ 
            value=price-paidAmount
        }
        let modifiedPayingAmount = [...payingAmount]
        modifiedPayingAmount[index]=value
        setPayingAmount(modifiedPayingAmount)
    }
    useEffect(()=>{
        setPayingAmount([])
        let payingAmountItems=items?.length ||0
        setPayingAmount([...Array(payingAmountItems)])
    },[items])
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Product Name')}</th>
                    <th>{t('Price')}</th>
                    <th>{t("Paid Amount")}</th>
                    <th>{t("Remain Amount")}</th>
                    {/* <th>{t("Transaction Date")}</th>
                    <th>{t("Paying")}</th> */}
                    <th></th>
                </thead>
                <tbody>
                    {
                        items &&items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.product_name} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.price} {t('AED')}</p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.paid_price} {t('AED')} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.remain_price} {t('AED')} </p>
                                </td>
                                <td>
                                    <Link to={`/our-clients/month-installments/${params?.id}?month_installment=${item?.id}`} className={styles['appointments__table-link']}>
                                        {t('More')}
                                    </Link>
                                    {
                                        user?.permission_type=='admin' &&
                                        <>
                                        
                                            <button onClick={()=>{deleteItem(params?.id,index,item?.id)}} disabled={isDeleting[index]}
                                                className={styles['appointments__table-delete']}>
                                                {
                                                    !isDeleting[index]?t('Delete')
                                                    :<ReactLoading type={"spin"} color={'red'} height={20} width={20} />
                                                }
                                            </button>
                                            
                                            {/* {
                                            item?.received_status=='received'?
                                            <button onClick={()=>{receiveTestment(order?.id,index,item?.id,'not_received')}}
                                                className={styles['appointments__table-received']}>
                                                {t('Received')}
                                            </button>
                                            :
                                            <button onClick={()=>{receiveTestment(order?.id,index,item?.id,'received')}}
                                                className={styles['appointments__table-not-received']}>
                                                {t('Not Received')}
                                            </button>
                                            } */}
                                        </>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default AllClientsInstallmentsTable
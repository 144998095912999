import { useTranslation } from 'react-i18next'
import styles from './ClientInstallments.module.css'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import { Container } from 'react-bootstrap'
import AllOrderTestamentsTable from 'components/Tables/AlOrderTestamentsTable/AllOrderTestamentsTable'
import { useSelector } from 'react-redux'
import AllClientsInstallmentsTable from 'components/Tables/AllClientsInstallmentsTable/AllClientsInstallmentsTable'
function ClientInstallments() {
  const {t} =useTranslation()
  const params = useParams()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)

  const [item,setItem]=useState({} as any)
  const [installments,setInstallments]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  function getUserInstallments(){
    setIsLoading(true)
    axiosConfig.get(`/user/single-user-by-id/${params?.id}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setIsLoading(false)
      setItem(res.data?.data)
      setInstallments(res.data?.data?.installments)
      // let deletedNumber:any = [...Array(res.data.data.length)]
      // setIsDeleting(deletedNumber)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }
  function deleteItem(userId:String ,index:any,testmentId:String){
    let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
      axiosConfig.delete(`/user/delete-installment-from-user/${userId}?installment_id=${testmentId}`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res=>{
        let modifiedDelete:[Boolean]=[...isDeleting]
        modifiedDelete[index]=false
        setIsDeleting(modifiedDelete)
        toast.success(t('Item Deleted Successfully'))
        getUserInstallments()
      }).catch(err=>{
        let modifiedDelete:[Boolean]=[...isDeleting]
        modifiedDelete[index]=false
        setIsDeleting(modifiedDelete)
        toast.error(err?.response?.data?.message)
        console.log(err)
      })
  }
  function receiveTestment(orderId:String ,index:any,testmentId:String,receivedStatus:String){
    let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
      axiosConfig.post(`/orders/received-testment-from-order/${orderId}`,{testment_id:testmentId,received_status:receivedStatus},{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res=>{
        let modifiedDelete:[Boolean]=[...isDeleting]
        modifiedDelete[index]=false
        setIsDeleting(modifiedDelete)
        toast.success(t('Item Changed Successfully'))
        getUserInstallments()
      }).catch(err=>{
        let modifiedDelete:[Boolean]=[...isDeleting]
        modifiedDelete[index]=false
        setIsDeleting(modifiedDelete)
        toast.error(err?.response?.data?.message)
        console.log(err)
      })
  }
  useEffect(()=>{
    getUserInstallments()
  },[])
  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          <div className={styles['page-header']}>
            <h1 className={styles['page-header__title']}>
              {t('Client Installments')} ({item?.name})
            </h1>
            {/* <Link to='/dashboard/add-technician' className={styles['page-header__link']}>
              {t('Export PDF')}
            </Link> */}
            {
              user?.permission_type=='admin' &&
              <Link to={`/our-clients/add-client-installments/${params?.id}`} className={styles['page-header__link']}>
                {t('Add Installments')}
              </Link>
            }
          </div>
        </Container>
        <AllClientsInstallmentsTable 
          getUserInstallments={getUserInstallments}
          items={installments} 
          deleteItem={deleteItem} 
          isDeleting={isDeleting} 
        />
      </section>
    </>
  )
}

export default ClientInstallments
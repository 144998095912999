import { useCallback, useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader ,Marker,Autocomplete} from '@react-google-maps/api';
import './MapModel.css'
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
export default function MapModel(props) {
  const {setLatitude,setLongitude,latitude,longitude,show,setShow} = props
  
  const [isLoading, setLoading]=useState(false)
  // const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(prevState=>!prevState);

  const [searchResult,setSearchResult]=useState('')
  const {t} = useTranslation()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: String('AIzaSyBzVRlLD50Rd4rPuA20lm2RH6SAzPzac_s'),
    libraries:['places']
  })
  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  
  const [location ,setLocation] = useState({lat:Number(latitude)||23.4241,lng:Number(longitude)||53.8478})
  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    map.setZoom(10)
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])
  let onMapClicked =(e)=>{
    setLocation({lat:e.latLng.lat(),lng:e.latLng.lng()})
    setLatitude(e.latLng.lat())
    setLongitude(e.latLng.lng())
  }
  function onLoadd(autocomplete) {
    setSearchResult(autocomplete);
  }
  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      setLocation({lat:place?.geometry?.location?.lat(),lng:place?.geometry?.location?.lng()})
      setLatitude(place?.geometry?.location?.lat())
      setLongitude(place?.geometry?.location?.lng())
    } else {
      alert("Please enter text");
    }
  }
  useEffect(()=>{
    if(latitude&&longitude){
      setLocation({lat:Number(latitude),lng:Number(longitude)})
    }
},[latitude,longitude])
  return (
    <>
    {/* <button type='button' className={`btn btn-primary ms-auto `} onClick={handleShow}>
      Add User
    </button> */}
    {
      (show&&isLoaded)&&
        <div className='position-relative'>
          <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoadd}>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className={'map-modal__search-input'}
              // placeholder={`${t('Square Urdu Name')}`}
              id='itemUrduName'
              // value={nameUr}
              onChange={(e) => console.log(e.target.value)}
            />
          </Autocomplete>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={location}
            // zoom={5}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={e =>onMapClicked(e) }
          >
            <Marker position={location}/>
          </GoogleMap>
        </div>

    }
    {/* <Modal show={show} onHide={handleClose} size='xl' >
        
        <Modal.Body className='p-0'>
          {
            isLoaded &&
            <>
              <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoadd}>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className={'map-modal__search-input'}
                  id='itemUrduName'
                  onChange={(e) => console.log(e.target.value)}
                />
              </Autocomplete>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={location}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={e =>onMapClicked(e) }
              >
                <Marker position={location}/>
              </GoogleMap>
            </>

          }
        </Modal.Body>
      </Modal> */}

    </>
)
}

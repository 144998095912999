import { useTranslation } from 'react-i18next'
import styles from './ImportClients.module.css'
import {ReactComponent as ClockIcon} from 'assets/icons/clockIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import MapModel from 'components/Global/Elements/MapModel/MapModel'
import { useNavigate, useParams } from 'react-router-dom'
import { emailPattern } from 'utils/features'
import Resizer from "react-image-file-resizer";
import {ReactComponent as UploadedIcon} from 'assets/icons/uploadedIcon.svg'
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx'

interface UserType {
  name:string;
  phone:string;
  email:string;
  password:string;
}
function ImportClients() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()

  
  const [isLoading , setIsLoading] = useState(false)
  const [excelData , setExcelData] = useState([])
  const [items , setItems] = useState<any>([])

  function addUpdateItem(items:any){
    // if(!validateInputs()){
    //   return
    // }
    setIsLoading(true)
    const formData  = new FormData()
    excelData?.forEach((item:any,index:any)=>{

      formData.append(`users[${index}][name]`,item?.name)
      formData.append(`users[${index}][phone_number]`,item?.phone_number)
      formData.append(`users[${index}][whatsapp_number]`,item?.whatsapp_number)
      formData.append(`users[${index}][state_id]`,item?.state_id)
      formData.append(`users[${index}][district]`,item?.district)
      formData.append(`users[${index}][email]`,item?.email)
      formData.append(`users[${index}][status]`,'1')
      formData.append(`users[${index}][password]`,'123456')
      item?.products?.split('&&')?.forEach((product:any,productIndex:any)=>{
        product = product?.split('/')
        formData.append(`users[${index}][products][${productIndex}][product_id]`,product?.[0]?.trim())
        formData.append(`users[${index}][products][${productIndex}][warranty_start_date]`,product?.[1]?.trim())
        formData.append(`users[${index}][products][${productIndex}][price]`,product?.[2]?.trim())
      })
      item?.installments?.split('&&')?.forEach((installment:any,productIndex:any)=>{
        installment = installment?.split('/')
        formData.append(`users[${index}][installments][${productIndex}][product_id]`,installment?.[0].trim())
        formData.append(`users[${index}][installments][${productIndex}][warranty_date]`,installment?.[1])
        formData.append(`users[${index}][installments][${productIndex}][price]`,installment?.[2])
        formData.append(`users[${index}][installments][${productIndex}][count]`,installment?.[3])
      })
    })

    axiosConfig.post(`/user/create-multi-users`,formData,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setIsLoading(false)
      toast.success(t(`users Added Successfully`))
      navigate('/our-clients')
    }).catch(err=>{
      toast.error(err?.response?.data?.message)
      setIsLoading(false)
      // toast.error(t('Something went wrong'))
    })
  }
  let addQuestionByExcelFile =async (e:any)=>{
      const file = e.target.files[0];
      if(!file) return;
      // loadDivRef.current.classList.remove('d-none')
      let extension = file.name.split('.').pop().toLowerCase();
      let allowed = [ 'xls', 'xlsx'];

      if(file){
          if(allowed.indexOf(extension) === -1) {
              toast.error('File type is not supported. file type must be .xlsx or .xls')
              // loadDivRef.current.classList.add('d-none')
              return ;
          }else{

          const data = await file.arrayBuffer();
          const workbook = XLSX.read(data);
          const workSheet = workbook.Sheets[workbook.SheetNames[0]]
          let jsonData = XLSX.utils.sheet_to_json(workSheet)
          if(jsonData.length>600){
              jsonData = jsonData.slice(0,600)
          }
          jsonData=jsonData.filter(question=>question)
          console.log('jsonData',jsonData)
          setExcelData((jsonData as any))
          // addUpdateItem(jsonData)
          // methods.setValue('examConfiguration.no_of_question',jsonData.length)
          }
      }
  }
  // useEffect(()=>{
  //     if(excelData?.length>0){
  //         let itemsData=excelData?.map((product:any)=>{
  //             let sizes = product?.sizes?.split(',')?.map((size:any)=>{
  //                 return{
  //                     id:uuidv4(),
  //                     name:size?.split('-')?.[0]||'',
  //                     priceBeforeDiscount:size?.split('-')?.[2]||'',
  //                     priceAfterDiscount:size?.split('-')?.[1]||'',
  //                 }
  //             })
  //             let types = product?.types?.split(',')?.map((type:any)=>{
  //                 return{
  //                     id:uuidv4(),
  //                     name:type?.split('-')?.[0]||'',
  //                     price:type?.split('-')?.[1]||'',
  //                 }
  //             })
  //             let extras = product?.extras?.split(',')?.map((extra:any)=>{
  //                 return{
  //                     id:uuidv4(),
  //                     name:extra?.split('-')?.[0]||'',
  //                     price:extra?.split('-')?.[1]||'',
  //                 }
  //             })
              
                  
  //             return{
  //                 id:uuidv4(),
  //                 title_en:product?.title_english,
  //                 title_ar:product?.title_arabic,
  //                 description_en:product?.description_english,
  //                 description_ar:product?.description_arabic,
  //                 category:product?.category,
  //                 sizes,
  //                 types,
  //                 extras,
  //                 image:[],
  //                 images:[],
  //             }
  //         })
  //         setItems(itemsData as any)
  //     }
  // },[excelData])
  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{params?.id?t('Update Client'):t('Add New Client')}</h1>
            <form className={styles['form__content-form']}>
              <div>
                <label className='fs-5 text-muted mb-0' htmlFor={`test__configuration`}>{t('Upload File')}</label>
                <a download href={'/users-modal.xlsx'} className='btn btn-primary ms-auto d-flex align-items-center justify-content-center w-100'>
                    {/* <DownladExcelIcon className={styles['add-test__config-download-icon']}/> */}
                    {t('Download File')}
                </a>
              </div>
              <div>
                <label className='fs-5 text-muted mb-0' htmlFor={`test__configuration`}>{t('Export File')}</label>
                  <input 
                      type="file" 
                      className='form-control form-control- mb-5 '
                      accept='.xlsx,.xls'
                      id='add-test__configuration-input'
                      onChange={(e)=>{addQuestionByExcelFile(e)}}
                  />
              </div>
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?
                  <>{t('Add Users')} </>
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default ImportClients
import { useTranslation } from 'react-i18next'
import styles from './AddUpdateProvidersTestment.module.css'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
function AddUpdateProvidersTestment() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [name,setName] =useState('')
  const [receivedCount,setReceivedCount] =useState('')
  const [receivedDate,setReceivedDate] =useState('')
  const [isLoading,setIsLoading] =useState(false)
  
  
  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('Testment Name Is Required'))
      status=false
    }
    if(!receivedCount){
      toast.error(t('Testment Received Count Is Required'))
      status=false
    }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('testment_name',name)
    formData.append('testment_received_count',receivedCount)
    
    axiosConfig.post(`/provider/add-testment-to-provider/${params?.id}`,formData,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setIsLoading(false)
      toast.success(t(`Technician Testment Added Successfully`))
      navigate(`/technicians/testaments/${params?.id}`)
    }).catch(err=>{
      setIsLoading(false)
      toast.error(t('Something went wrong'))
    })
  }

  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{t('Add Testaments')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Name')}`}
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                />
                  
                  <input 
                    type='number'
                    className={styles['form__content-form-input']}
                    placeholder={`${t('Received Count')}`}
                    value={receivedCount}
                    onChange={(e)=>setReceivedCount(e.target.value)}
                  />
              </div>
              
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?t('Add Testaments')
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default AddUpdateProvidersTestment
import { useTranslation } from 'react-i18next'
import styles from './AddUpdateProvidersTestments.module.css'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';

const TESTMENT_MODEL = (testment:any)=>{
  return{
      id:uuidv4(),
      name:testment?.name||'',
      receivedCount:testment?.receivedCount||'',
      receivedDate:testment?.receivedDate||'',
  }
}
function AddUpdateProvidersTestments() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [name,setName] =useState('')
  const [receivedCount,setReceivedCount] =useState('')
  const [isLoading,setIsLoading] =useState(false)
  const [testmentsData,setTestmentsData] =useState<any>([])
  const [testments,setTestments] =useState<any>([TESTMENT_MODEL({})])

  function addTestment(){
      let testmentData = [...testments]
      testmentData.push(TESTMENT_MODEL({}))
      setTestments(testmentData)
  }
  function removeTestment(id:any){
      let testmentData = [...testments]
      testmentData =testmentData.filter(testment=>testment?.id !=id)
      setTestments(testmentData)
  }
  function handleChangeTestmentData(key:any,value:any,index:any){
      let testmentData = [...testments]
      testmentData[index][key] = value
      setTestments(testmentData)
  }

  
  function validateInputs(){
    let status =true
    // if(!name){
    //   toast.error(t('Testment Name Is Required'))
    //   status=false
    // }
    // if(!receivedCount){
    //   toast.error(t('Testment Received Count Is Required'))
    //   status=false
    // }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    testments?.forEach((testment:any,index:number)=>{
      formData.append(`testments[${index}][testment_id]`,testment?.name)
      formData.append(`testments[${index}][received_count]`,testment?.receivedCount)
      formData.append(`testments[${index}][received_date]`,testment?.receivedDate)
    })
    
    axiosConfig.post(`/provider/add-testments-to-provider/${params?.id}`,formData,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setIsLoading(false)
      toast.success(t(`Technician Testment Added Successfully`))
      navigate(`/technicians/testaments/${params?.id}`)
    }).catch(err=>{
      setIsLoading(false)
      toast.error(t('Something went wrong'))
    })
  } 
  function getAllTestments(){
    axiosConfig.get(`/testment/all-testments-without-pagination`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setTestmentsData(res.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    getAllTestments()
  },[])

  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{t('Add Testaments')}</h1>
            <form className={styles['form__content-form']}>
              
            <div className='w-100 mb-4 d-flex'>
                {/* <label className='fs-5 text-muted mb-0' htmlFor='status'>{t('Testaments')}</label> */}
                <button type='button' onClick={addTestment} className='btn btn-success ms-auto'>{t('Add Testment')}</button>
            </div>
            {
                testments&&testments?.map((testment:any,index:any)=>(
                  <div className={styles['form__content-form-inputs-wrapper']} key={testment?.id}>
                    <select 
                      className={styles['form__content-form-input']}
                      name={'name'}
                      value={testment?.name}
                      onChange={(e)=>handleChangeTestmentData(e.target.name,e.target.value,index)}
                    >
                      <option value=''>{t('Please Select Testment')}</option>
                      {
                        testmentsData?.map((testment:any)=>(
                          <option value={testment?.id} key={testment?.id}>{testment?.title}</option>
                        ))
                      }
                    </select>
                    {/* <input 
                      type='text'
                      className={styles['form__content-form-input']}
                      placeholder={`${t('Name')}`}
                      name={'name'}
                      value={testment?.name}
                      onChange={(e)=>handleChangeTestmentData(e.target.name,e.target.value,index)}
                    /> */}
                      
                    <input 
                      type='number'
                      className={styles['form__content-form-input']}
                      placeholder={`${t('Received Count')}`}
                      name={'receivedCount'}
                      value={testment?.receivedCount}
                      onChange={(e)=>handleChangeTestmentData(e.target.name,e.target.value,index)}
                    />
                    <input 
                      type='datetime-local'
                      className={styles['form__content-form-input']}
                      placeholder={`${t('Received Date')}`}
                      name={'receivedDate'}
                      value={testment?.receivedDate}
                      onChange={(e)=>handleChangeTestmentData(e.target.name,e.target.value,index)}
                    />
                      <button type='button' onClick={()=>{removeTestment(testment?.id)}}
                      className='btn btn-danger ms-auto my-2 mb-5'>{t('Remove Testment')}</button>
                  </div>
                ))
            }
              
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?t('Add Testaments')
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default AddUpdateProvidersTestments
import { useTranslation } from 'react-i18next'
import styles from './Messages.module.css'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
function Messages() {
  const {t} =useTranslation()
  const [isLoading,setIsLoading]=useState(false)
  const [users,setUsers]=useState([])
  const [user,setUser]=useState('')
  const [phone,setPhone]=useState('')
  const [text,setText]=useState('')

  function validateInputs(){
    let status =true
    if(!phone){
      toast.error(t('Phone Is Required'))
      status=false
    }
    if(!text){
      toast.error(t('Text Is Required'))
      status=false
    }
    return status
  }

  function getAllUsers(){
    axiosConfig.get(`/user/all-users-without-pagintaion`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setUsers(res.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getAllUsers()
  },[])

  useEffect(()=>{
    let userItemData:any = users?.filter((userItem:any)=>userItem?.id == user)?.[0]
    setPhone(userItemData?.phone_number as any)
  },[user])

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('phone',phone)
    formData.append('text',text)

    axiosConfig.post(`/settings/send-message-to-number`,formData,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setIsLoading(false)
      toast.success(t(`Message Sent Successfully`))
    }).catch(err=>{
      console.log('err?.response', err?.response)
      toast.error(err?.response?.data?.message)
      setIsLoading(false)
    })
  }

  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{t('Messages')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                <select 
                  className={styles['form__content-form-input']}
                  value={user}
                  onChange={(e)=>setUser(e.target.value)}
                >
                  <option value=''>{t('Please Select Client')}</option>
                  {
                    users?.map((user:any)=>(
                      <option value={user?.id} key={user?.id}>{user?.name}</option>
                    ))
                  }
                </select>
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Text')}`}
                  value={text}
                  onChange={(e)=>setText(e.target.value)}
                />
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Phone')}`}
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                />
              </div>
              
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?
                  <>{t('Update')} </>
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default Messages
import { useTranslation } from 'react-i18next'
import styles from './Admins.module.css'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import AllAdminsTable from 'components/Tables/AllAdminsTable/AllAdminsTable'
function Admins() {
  const {t} =useTranslation()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)

  const [searchTerm,setSearchTerm]=useState('')
  const [items,setItems]=useState([])
  const [currentPage,setCurrentPage]=useState(1)
  const [itemsCount,setItemsCount]=useState(0)
  const [pages,setPages]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  const [isDeletingLoading,setIsDeletingLoading] =useState<Boolean>(false)
  function getAllItems(){
      setIsLoading(true)
      axiosConfig.get(`/admin/all-users?page=${currentPage}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            
        }
    }).then(res=>{
      console.log(res.data?.data?.data)
          setIsLoading(false)
          setItems(res.data?.data?.data)
          setPages(res.data?.data?.pages)
          setItemsCount(res.data.data.count)
          let deletedNumber:any = [...Array(res.data.data.count)]
          setIsDeleting(deletedNumber)
      }).catch(err=>{
          setIsLoading(false)
          console.log(err)
      })
  }
  function deleteItem(deltedId:String ,index:any){
    let modifiedDelete:[Boolean]=[...isDeleting]
    modifiedDelete[index]=true
    setIsDeletingLoading(true)
    setIsDeleting(modifiedDelete)
      axiosConfig.delete(`/admin/delete-user/${deltedId}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
            let modifiedDelete:[Boolean]=[...isDeleting]
            modifiedDelete[index]=false
            setIsDeleting(modifiedDelete)
            toast.success(t('Item Deleted Successfully'))
            getAllItems()
            setIsDeletingLoading(false)
      }).catch(err=>{
          let modifiedDelete:[Boolean]=[...isDeleting]
          modifiedDelete[index]=false
          setIsDeleting(modifiedDelete)
          toast.error(err?.response?.data?.message)
          console.log(err)
          setIsDeletingLoading(false)
      })
  }
  useEffect(()=>{
    getAllItems()
  },[currentPage,searchTerm])
  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          <div className={styles['page-header']}>
            <h1 className={styles['page-header__title']}>
            {t('Admins')}
            <span className={styles['page-header__title-count']}>{itemsCount}</span>
            </h1>
            {
              user?.permission_type=='admin' &&
              <Link to='add-admin' className={styles['page-header__link']}>
                {t('Add New Admin')}
                <PlusIcon/>
              </Link>
            }
          </div>
        </Container>
        <AllAdminsTable items={items} deleteItem={deleteItem} isDeletingLoading={isDeletingLoading}/>
        
      </section>
    </>
  )
}

export default Admins
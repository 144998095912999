export const emailPattern =/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
export const linkPattern =/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
export const passwordPattern =/^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$/
export const whtsAppConnect=(whatsAppNumber:any)=>`https://api.whatsapp.com/send?phone=${whatsAppNumber}`
export const emailConnect =(email:any)=>`mailto:${email}?subject=SendMail&body=Description`

// export const gtag_report_conversion =(url)=> {
//     var callback = function () {
//         if (typeof(url) != 'undefined') {
//             window.location = url;
//         }
//     };
//     gtag('event', 'conversion', {
//         'send_to': 'AW-10968148875/_yA8CIHr_IYYEIvXgu4o',
//         'event_callback': callback
//     });
//     return false;
// }
// <!-- Event snippet for النقر على زر conversion page
// In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->
// <script>
// function gtag_report_conversion(url) {
//   var callback = function () {
//     if (typeof(url) != 'undefined') {
//       window.location = url;
//     }
//   };
//   gtag('event', 'conversion', {
//       'send_to': 'AW-10968148875/_yA8CIHr_IYYEIvXgu4o',
//       'event_callback': callback
//   });
//   return false;
// }
// </script>
import { useTranslation } from 'react-i18next'
import styles from './Dashboard.module.css'
import {ReactComponent as PrinterIcon} from 'assets/icons/printerIcon.svg'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import ImmediateCommingsTable from 'components/Tables/PaymentsTables/ImmediateCommingsTable/ImmediateCommingsTable'
import InstallmentsTable from 'components/Tables/PaymentsTables/InstallmentsTable/InstallmentsTable'
import ChartJs from 'components/Global/Elements/ChartJs/ChartJs'
import OurClientsTable from 'components/Tables/DashboardTables/OurClientsTable/OurClientsTable'
import TechniciansTable from 'components/Tables/DashboardTables/TechniciansTable/TechniciansTable'
import NewOrdersTable from 'components/Tables/DashboardTables/NewOrdersTable/NewOrdersTable'
function Dashboard() {
  const {t} =useTranslation()
  const [users,setUsers]=useState([])
  const [providers,setProviders]=useState([])
  const [orders,setOrders]=useState([])
  const [payments,setPayments]=useState([])
  function getAllUsers(){
    axiosConfig.get(`/user/all-users?limit=6`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setUsers(res.data?.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }
  function getAllProviders(){
    axiosConfig.get(`/provider/all-providers?limit=6`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setProviders(res.data?.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }
  function getAllNewOrder(){
    axiosConfig.get(`/orders/all-users-orders?limit=6&order_type=new`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setOrders(res.data?.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }
  
  function getAlPayments(){
    let month = (new Date()).getMonth()
    let year = (new Date()).getFullYear()
    axiosConfig.get(`/orders/month-payments?month=${month}&year=${year}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setPayments(res.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    getAllUsers()
    getAllProviders()
    getAllNewOrder()
    getAlPayments()
  },[])
  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          {/* <div className={styles['page-header']}>
            <div className={styles['page-header__top-wrapper']}>
              <div className='d-flex align-items-center gap-2'>
              <h1 className={styles['page-header__title']}>
                {t('Payments')}
              </h1>
                <div className='d-flex align-items-center gap-2'>
                  <select className={styles['form__content-form-input']}>
                      <option value='jan'>{t('Jan')}</option>
                      <option value='feb'>{t('Feb')}</option>
                      <option value='mar'>{t('Mar')}</option>
                      <option value='apr'>{t('Apr')}</option>
                      <option value='may'>{t('May')}</option>
                      <option value='jun'>{t('Jun')}</option>
                      <option value='jul'>{t('Jul')}</option>
                      <option value='aug'>{t('Aug')}</option>
                      <option value='sep'>{t('Sep')}</option>
                      <option value='oct'>{t('Oct')}</option>
                      <option value='nov'>{t('Nov')}</option>
                      <option value='dec'>{t('Dec')}</option>
                  </select>
                  <select className={styles['form__content-form-input']}>
                    {
                      [...Array(20)]?.map((year,index)=>(
                        <option value={new Date().getFullYear() -index} key={new Date().getFullYear() -index}>{new Date().getFullYear() -index}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
                <Link to='add-order' className={styles['page-header__link']}>
                  {t('Print')}
                  <PrinterIcon/>
                </Link>
            </div>
          </div> */}

          <Row>
            <Col lg='3' className='order-2 order-lg-1'>
              {/* <div className='d-flex flex-lg-column justify-content-center'> */}
                <div>
                  <h2 className={styles['page-header__sub-title']}>{t('Our Clients')}</h2>
                  <OurClientsTable items={users}/>
                </div>
                <div className='mt-lg-4'>
                  <h2 className={styles['page-header__sub-title']}>{t('Technicians')}</h2>
                  <TechniciansTable items={providers}/>
                </div>
              {/* </div> */}
            </Col>
            <Col lg='9' className='order-1 order-lg-2'>
              <ChartJs items={payments}/>
              <div>
                <h2 className={styles['page-header__sub-title']}>{t('New Orders')}</h2>
                <NewOrdersTable items={orders}/>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  )
}

export default Dashboard
import { useTranslation } from 'react-i18next'
import styles from './AllTecnicianReciepts.module.css'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import AllClientsTable from 'components/Tables/AllClientsTable/AllClientsTable'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import {ReactComponent as WhatsappIcon} from 'assets/icons/whatsappIcon.svg'
import AllOrdersTable from 'components/Tables/AllOrdersTable/AllOrdersTable'
import AllInstallmentsTable from 'components/Tables/AllInstallmentsTable/AllInstallmentsTable'
import AllClientOrdersTable from 'components/Tables/AllClientOrdersTable/AllClientOrdersTable'
import receiptsImg from 'assets/imgs/receiptsImg.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import './AllTecnicianReciepts.css';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import DownloadLink from 'react-download-link';

function AllTecnicianReciepts() {
  const {t} =useTranslation()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const [formattedImages,setFormattedImages]=useState<any>([])
  const [activeIndex, setActiveIndex] = useState(0);

  const params = useParams()
  const [item,setItem]=useState({} as any)
  const [isLoading,setIsLoading]=useState(false)
  function getUserDetails(){
    setIsLoading(true)
    axiosConfig.get(`/provider/single-provider/${params?.id}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setItem(res.data?.data)
      const formatedImage = res.data?.data?.additional_documents&&res.data?.data?.additional_documents?.map((image:any)=>{
        return {
          original: image,
          thumbnail: image, 
          thumbnailClass:'thumbnailClass'
        }
      })
      setFormattedImages(formatedImage)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }
  useEffect(()=>{
    getUserDetails()
  },[])
  return (
    <>
      <section className={`${styles['client-details-receipts']} client-details-receipts`}>
        <Container>
          <ImageGallery 
            items={formattedImages} 
            onSlide={(index:any) => setActiveIndex(index)} 
          />

          <DownloadLink
            label="Download"
            style={{
              margin: '10px auto',
              display: 'flex',
              background: '#5885c3',
              width: 'fit-content',
              padding: '10px',
              color: 'white',
              height: 'fit-content',
              fontFamily: 'InterBold',
              textDecoration: 'none',
            }}
            filename={formattedImages?.[activeIndex]?.original || "image.jpg"}
            exportFile={() => fetch(formattedImages?.[activeIndex]?.original).then(res => res.blob())}
          />
        </Container>
      </section>
    </>
  )
}

export default AllTecnicianReciepts
import styles from './AllAdminsTable.module.css'
import { Container, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './AllAdminsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import adminImg from 'assets/imgs/adminImg.png'
import { useEffect } from 'react'
import ReactLoading from "react-loading";

function AllAdminsTable(props:any) {
    const {items,deleteItem,isDeletingLoading} = props
    
    const {t} = useTranslation()
    return (
        <>
            <div className={styles['all-admins-wrapper']}>
                {
                    isDeletingLoading &&
                    <div className={styles['all-admins-wrapper__loading-icon-wrapper']}>
                        <ReactLoading type={"spin"} color={'#5885C3'} height={30} width={30} />
                    </div>
                }
                {
                    items && items?.map((item:any,index:number)=>(
                        <div className={styles['all-admins__item-wrapper']} key={item?.id}>
                            <Container>
                                <div className={styles['all-admins__item']}>
                                    {/* <img src={adminImg} className={styles['all-admins__item-img']}/> */}
                                    <div className={styles['all-admins__item-img-div']}>
                                        {item?.name?.[0]}
                                    </div>
                                    <div className={styles['all-admins__item-info-wrapper']}>
                                        <p className={styles['all-admins__item-info-name']}> {item?.name} (
                                            {
                                                item?.permission_type=='admin'?t('Admin'):
                                                item?.permission_type=='editor'?t('Editor'):
                                                item?.permission_type=='viewer'?t('Viewer'):
                                                item?.permission_type=='technical'?t('Technical Support'):
                                                item?.permission_type=='Accounts'?t('Accounts'):''
                                            }
                                        )</p>
                                        {/* <p className={styles['all-admins__item-info-role']}> مدير </p> */}
                                        <p className={styles['all-admins__item-info-role']}> {item?.email} </p>
                                    </div>
                                    <div className={styles['all-admins__item-link-wrapper']}>
                                        <Link to={`update-admin/${item?.id}`} className={styles['all-admins__item-link']}>
                                            <PencilIcon className={styles['all-admins__item-link-icon']}/>
                                            {t('Update Admin')}
                                        </Link>
                                        <button type='button' onClick={()=>{deleteItem(item?.id,index)}} className={styles['all-admins__item-delete-link']}>
                                            <DeleteIcon className={styles['all-admins__item-link-icon']}/>
                                            {t('Delete Admin')}
                                        </button>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    ))
                }
                {/* <div className={styles['all-admins__item-wrapper']}>
                    <Container>
                        <div className={styles['all-admins__item']}>
                            <img src={adminImg} className={styles['all-admins__item-img']}/>
                            <div className={styles['all-admins__item-info-wrapper']}>
                                <p className={styles['all-admins__item-info-name']}> أحمد سالم الراوي </p>
                                <p className={styles['all-admins__item-info-role']}> مدير </p>
                            </div>
                            <Link to='update-admin/dasdasas' className={styles['all-admins__item-link']}>
                                <PencilIcon className={styles['all-admins__item-link-icon']}/>
                                {t('Update Admin')}
                            </Link>
                        </div>
                    </Container>
                </div>
                <div className={styles['all-admins__item-wrapper']}>
                    <Container>
                        <div className={styles['all-admins__item']}>
                            <img src={adminImg} className={styles['all-admins__item-img']}/>
                            <div className={styles['all-admins__item-info-wrapper']}>
                                <p className={styles['all-admins__item-info-name']}> أحمد سالم الراوي </p>
                                <p className={styles['all-admins__item-info-role']}> مدير </p>
                            </div>
                            <Link to='update-admin/dasdasas' className={styles['all-admins__item-link']}>
                                <PencilIcon className={styles['all-admins__item-link-icon']}/>
                                {t('Update Admin')}
                            </Link>
                        </div>
                    </Container>
                </div>
                <div className={styles['all-admins__item-wrapper']}>
                    <Container>
                        <div className={styles['all-admins__item']}>
                            <img src={adminImg} className={styles['all-admins__item-img']}/>
                            <div className={styles['all-admins__item-info-wrapper']}>
                                <p className={styles['all-admins__item-info-name']}> أحمد سالم الراوي </p>
                                <p className={styles['all-admins__item-info-role']}> مدير </p>
                            </div>
                            <Link to='update-admin/dasdasas' className={styles['all-admins__item-link']}>
                                <PencilIcon className={styles['all-admins__item-link-icon']}/>
                                {t('Update Admin')}
                            </Link>
                        </div>
                    </Container>
                </div>
                <div className={styles['all-admins__item-wrapper']}>
                    <Container>
                        <div className={styles['all-admins__item']}>
                            <img src={adminImg} className={styles['all-admins__item-img']}/>
                            <div className={styles['all-admins__item-info-wrapper']}>
                                <p className={styles['all-admins__item-info-name']}> أحمد سالم الراوي </p>
                                <p className={styles['all-admins__item-info-role']}> مدير </p>
                            </div>
                            <Link to='update-admin/dasdasas' className={styles['all-admins__item-link']}>
                                <PencilIcon className={styles['all-admins__item-link-icon']}/>
                                {t('Update Admin')}
                            </Link>
                        </div>
                    </Container>
                </div>
                <div className={styles['all-admins__item-wrapper']}>
                    <Container>
                        <div className={styles['all-admins__item']}>
                            <img src={adminImg} className={styles['all-admins__item-img']}/>
                            <div className={styles['all-admins__item-info-wrapper']}>
                                <p className={styles['all-admins__item-info-name']}> أحمد سالم الراوي </p>
                                <p className={styles['all-admins__item-info-role']}> مدير </p>
                            </div>
                            <Link to='update-admin/dasdasas' className={styles['all-admins__item-link']}>
                                <PencilIcon className={styles['all-admins__item-link-icon']}/>
                                {t('Update Admin')}
                            </Link>
                        </div>
                    </Container>
                </div> */}
            </div>
        </>
    )
}

export default AllAdminsTable
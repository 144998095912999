import styles from './ImmediateCommingsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './ImmediateCommingsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
function ImmediateCommingsTable(props:any) {
    const {items} = props
    const {t} = useTranslation()
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Client Name')}</th>
                    <th>{t('Price')}</th>
                </thead>
                <tbody>
                    {
                        items?.orders&& items?.orders?.map((item:any)=>(
                            <tr key={item?.id}>
                                <td>
                                    <Link to={`/our-clients/client-details/${item?.user_id}`} className={styles['appointments__table-link']}>
                                        {item?.user_name}
                                    </Link>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.paid_amount} {t('AED')}</p>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default ImmediateCommingsTable
import styles from './AllOrderTestamentsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './AllOrderTestamentsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
function AllOrderTestamentsTable(props:any) {
    const {t}= useTranslation()
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const { items ,order ,deleteItem,isDeleting,receiveTestment } = props
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Piece Name')}</th>
                    <th>{t('Pieces Count')}</th>
                    <th>{t("Return Number")}</th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        items &&items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.name} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.received_count} {t('Piece')} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.return_count} {t('Piece')} </p>
                                </td>
                                <td>
                                    {
                                        user?.permission_type=='admin' &&
                                        <>
                                        
                                        <button onClick={()=>{deleteItem(order?.id,index,item?.id)}} disabled={isDeleting[index]}
                                            className={styles['appointments__table-delete']}>
                                            {
                                                !isDeleting[index]?t('Delete')
                                                :<ReactLoading type={"spin"} color={'red'} height={20} width={20} />
                                            }
                                        </button>
                                        {
                                            item?.received_status=='received'?
                                            <button onClick={()=>{receiveTestment(order?.id,index,item?.id,'not_received')}}
                                                className={styles['appointments__table-received']}>
                                                {t('Received')}
                                            </button>
                                            :
                                            <button onClick={()=>{receiveTestment(order?.id,index,item?.id,'received')}}
                                                className={styles['appointments__table-not-received']}>
                                                {t('Not Received')}
                                            </button>
                                            }
                                        </>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default AllOrderTestamentsTable
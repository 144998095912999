import { useTranslation } from 'react-i18next'
import styles from './AddUpdateAdmins.module.css'
import {ReactComponent as ClockIcon} from 'assets/icons/clockIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import MapModel from 'components/Global/Elements/MapModel/MapModel'
import { useNavigate, useParams } from 'react-router-dom'
import { emailPattern } from 'utils/features'
interface AdminType {
  name:string;
  phone:string;
  email:string;
  password:string;
  permission_type:string;
}
function AddUpdateAdmins() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()

  const [name,setName]=useState('')
  const [phone,setPhone]=useState('')
  const [email,setEmail]=useState('')
  const [permissionType,setPermissionType]=useState('viewer')
  const [password,setPassword]=useState('')
  const [item,setItem]=useState<AdminType>()
  const [isLoading,setIsLoading]=useState(false)
  
  function getItem(){
    setIsLoading(true)
    axiosConfig.get(`/admin/single-user/${params?.id}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setIsLoading(false)
      setItem(res.data?.data)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }
  
  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('Admin Name Is Required'))
      status=false
    }
    if(!phone){
      toast.error(t('Admin Phone Is Required'))
      status=false
    }
    if(!permissionType){
      toast.error(t('Admin Permission Type Is Required'))
      status=false
    }
    if(!emailPattern.test(email)){
      toast.error(t('Admin Email Is Required'))
      status=false
    }
    // if(!password){
    //   toast.error(t('Admin Password Is Required'))
    //   status=false
    // }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('name',name)
    formData.append('phone',phone)
    formData.append('email',email)
    formData.append('permission_type',permissionType)
    password&&formData.append('password',password)

    if(item){
      axiosConfig.put(`/admin/update-user/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Admin Updated Successfully`))
        navigate('/admins')
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/admin/create-user`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Admin Added Successfully`))
        navigate('/admins')
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }
  useEffect(()=>{
    if(params?.id){
      getItem()
    }
  },[params])
  useEffect(()=>{
    if(item){
      setName(item?.name)
      setPhone(item?.phone)
      setEmail(item?.email)
      setPermissionType(item?.permission_type)
      setPassword(item?.password)
    }
  },[item])
  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{params?.id?t('Update Admin'):t('Add New Admin')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Admin Name')}`}
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                />
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Phone Number')}`}
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                />
                <input 
                  type='email'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Email')}`}
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
                <input 
                  type='password'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Password')}`}
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                />
                <select
                  className={styles['form__content-form-input']}
                  value={permissionType}
                  onChange={(e)=>setPermissionType(e.target.value)}
                >
                  <option value=''>{t('Please Select Permission Type')}</option>
                  <option value='admin'>{t('Admin')}</option>
                  <option value='editor'>{t('Editor')}</option>
                  <option value='viewer'>{t('Viewer')}</option>
                  <option value='technical'>{t('Technical Support')}</option>
                  <option value='accounts'>{t('Accounts')}</option>
                </select>

              </div>
              {/* <button 
              type='button' 
              className={styles['form__content-form-submit']}
              onClick={addItem}
              >{t('Save')}</button> */}
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?
                  <>{params?.id?t('Update'):t('Add Admin')} </>
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default AddUpdateAdmins
import { useTranslation } from 'react-i18next'
import styles from './AddUpdateTestments.module.css'
import {ReactComponent as UploadedIcon} from 'assets/icons/uploadedIcon.svg'
import {ReactComponent as PatientsMenuIcon} from 'assets/icons/patientsMenuIcon.svg'
import userImg from 'assets/imgs/userImg.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { ItemsListPagination } from 'components/Global/Elements/ItemsListPagination/ItemsListPagination'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import TableElement from 'components/Global/Elements/TableElement/TableElement'
import Resizer from "react-image-file-resizer";
import { emailPattern } from 'utils/features'
function AddUpdateTestments() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [titleEn,setTitleEn]=useState('')
  const [titleAr,setTitleAr]=useState('')
  const [descriptionEn,setDescriptionEn]=useState('')
  const [descriptionAr,setDescriptionAr]=useState('')

  const [show,setShow]=useState(false)
  const [item,setItem]=useState<any>()
  const [isLoading,setIsLoading]=useState(false)
  
  function getItem(){
    setIsLoading(true)
    axiosConfig.get(`/testment/single-testment/${params?.id}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setIsLoading(false)
      setItem(res.data?.data)
      setShow(true)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }
  function validateInputs(){
    let status =true
    if(!titleEn){
      toast.error(t('Testment Title English Is Required'))
      status=false
    }
    if(!titleAr){
      toast.error(t('Testment Title Arabic Is Required'))
      status=false
    }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('title_en',titleEn)
    formData.append('title_ar',titleAr)
    formData.append('description_en',descriptionEn)
    formData.append('description_ar',descriptionAr)
    if(item){
      axiosConfig.put(`/testment/update-testment/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Testment Updated Successfully`))
        navigate('/testments')
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/testment/create-testment`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Testment Added Successfully`))
        navigate('/testments')
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }
  useEffect(()=>{
    if(params?.id){
      getItem()
    }
  },[params])
  
  useEffect(()=>{
    if(item){
      setTitleEn(item?.title_en)
      setTitleAr(item?.title_ar)
      setDescriptionEn(item?.description_en)
      setDescriptionAr(item?.description_ar)
    }
  },[item])
  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{params?.id?t('Update Testment'):t('Add New Testment')} </h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Title English')}`}
                  value={titleEn}
                  onChange={(e)=>setTitleEn(e.target.value)}
                />
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Title Arabic')}`}
                  value={titleAr}
                  onChange={(e)=>setTitleAr(e.target.value)}
                />
                <textarea 
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Description English')}`}
                  value={descriptionEn}
                  onChange={(e)=>setDescriptionEn(e.target.value)}
                ></textarea>
                <textarea 
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Description Arabic')}`}
                  value={descriptionAr}
                  onChange={(e)=>setDescriptionAr(e.target.value)}
                ></textarea>
              </div>
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?
                  <>{params?.id?t('Update'):t('Add Testment')} </>
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
              
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default AddUpdateTestments
import React, { useEffect, useState } from 'react'
import styles from './SideBar.module.css'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import blankImage from 'assets/imgs/blank.png'

import modernWaterLogo from 'assets/imgs/modernWaterLogo.png'
import './SideBar.css'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'
import { useSelector } from 'react-redux'
import { HashLink } from 'react-router-hash-link'
import { useDispatch } from 'react-redux'
import { changeTokenAction, changeUserDetails } from 'reduxStore/Global/GlobalActions'
function SideBar({isSideBarVisible,toggleSideNavBar}) {
    const {t} =useTranslation()
    const navigate =useNavigate()
    const dispatch = useDispatch()
    const user =useSelector((state)=>state?.GlobalReducer?.user)
    function logout(){
        navigate('/')
        localStorage.removeItem('token')        
        dispatch(changeTokenAction(''))
        dispatch(changeUserDetails({}))
    }
    useEffect(()=>{
        toggleSideNavBar()
    },[navigate])
    return (
    <nav className={`${styles["sidebar"]} ${isSideBarVisible && styles['sidebar--open']}`}>
        <div className={styles["sidebar__content"]}>
            <div className='d-flex justify-content-between mb-3'>
                <div className={styles["sidebar__content-language"]}>
                    <ChangeLangueList type='light'/>
                </div>
                <button role='button' onClick={()=>{toggleSideNavBar()}} className={styles['sidebar__close-button']}>X</button>
            </div>
            <ul className={styles["sidebar__nav-list"]}>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <NavLink to='/' className={styles["sidebar__nav-link"]}>                    
                        <img src={modernWaterLogo} alt='logo' className={`${styles['sidebar__logo']}`}/>
                    </NavLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <div className={styles['sidebar__client-info-wrapper']}>
                        <div className={styles['sidebar__client-info-content-wrapper']}>
                            <p className={styles['sidebar__client-info-name']}>{user?.name}</p>
                            <p className={styles['sidebar__client-info-text']}>
                            {
                                user?.permission_type=='admin'?t('Admin'):
                                user?.permission_type=='editor'?t('Editor'):
                                user?.permission_type=='viewer'?t('Viewer'):
                                user?.permission_type=='technical'?t('Technical Support'):
                                user?.permission_type=='Accounts'?t('Accounts'):''
                            }
                            </p>
                        </div>
                        <img src={blankImage} className={styles['sidebar__client-info-img']}/>
                    </div>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/' className={styles["sidebar__nav-link"]}>{t('Dashboard')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/our-clients' className={styles["sidebar__nav-link"]}>{t('Customers')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/technicians' className={styles["sidebar__nav-link"]}>{t('Technicians')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/orders' className={styles["sidebar__nav-link"]}>{t('Orders')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/payments' className={styles["sidebar__nav-link"]}>{t('Payments')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/products' className={styles["sidebar__nav-link"]}>{t('Products')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/testments' className={styles["sidebar__nav-link"]}>{t('Testments')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/states' className={styles["sidebar__nav-link"]}>{t('States')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/reports' className={styles["sidebar__nav-link"]}>{t('Reports')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/messages' className={styles["sidebar__nav-link"]}>{t('Messages')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/notifications' className={styles["sidebar__nav-link"]}>{t('Notifications')}</HashLink>
                </li>
                {
                    user?.permission_type=='admin' &&
                    <>
                        <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                            <HashLink to='/admins' className={styles["sidebar__nav-link"]}>{t('Admins')}</HashLink>
                        </li>
                        <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                            <HashLink to='/settings' className={styles["sidebar__nav-link"]}>{t('Settings')}</HashLink>
                        </li>
                    </>
                }
                
                <li>
                    <button type='button' onClick={logout} className={styles['navbar__nav-link-logout']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Logout')}</span>
                    </button>
                </li>
                {/* <li className={styles['navbar__nav-item--margin']}>
                    <ChangeLangueList/>
                </li> */}
            </ul>
        </div>
        <div className={styles["sidebar__overlayer"]} onClick={()=>{toggleSideNavBar('close')}}></div>
    </nav>
  )
}

export default SideBar
import styles from './NewOrdersTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './NewOrdersTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useState } from 'react'
function NewOrdersTable(props:any) {
    const {month,year} = props
    const {t} = useTranslation()
    const [items,setItems]=useState([])
    const [currentPage,setCurrentPage]=useState(1)
    const [pages,setPages]=useState(null)
    
    function getAllItems(){
        axiosConfig.get(`/orders/all-users-orders?page=${currentPage}&order_type=new`,{
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((res:any)=>{
            setItems(res.data?.data?.data)
            setPages(res.data?.data?.pages)
        }).catch((err:any)=>{
            console.log(err)
        })
    }
    useEffect(()=>{
        getAllItems()
    },[])
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Order No')}</th>
                    <th>{t('Client Name')}</th>
                    {/* <th>{t("Phone Number")}</th> */}
                    {/* <th>{t("Receipt Number")}</th> */}
                    <th>{t('State')}</th>
                    {/* <th>{t('Order Type')}</th> */}
                    <th>{t('Technician')}</th>
                    <th>{t('Order Status')}</th>
                </thead>
                <tbody>
                    {
                        items && items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <Link to={`/orders/order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                        {item?.item_number}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/our-clients/client-details/${item?.user?.id}`} className={styles['appointments__table-link']}>
                                        {item?.user?.name}
                                    </Link>
                                </td>
                                {/* <td>
                                    <p className={styles['appointments__table-name']}>{item?.receipt_number}</p>
                                </td> */}
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.user?.state}</p>
                                </td>
                                {/* <td>
                                    <p className={styles['appointments__table-name']}>{item?.order_type}</p>
                                </td> */}
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.provider?.name||'---'}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>
                                        {
                                            item?.status ==1 ?t('New'):
                                            item?.status ==2 ?t('Completed'):
                                            item?.status ==3 ?t('Cancelled'):
                                            item?.status ==4 ?t('Reasigned'):
                                            ''
                                        }
                                    </p>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default NewOrdersTable
import styles from './AllProductsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './AllProductsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
function AllProductsTable(props:any) {
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)

    const { items ,deleteItem,isDeleting } = props
    const {t} = useTranslation()
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Title English')}</th>
                    <th>{t('Title Arabic')}</th>
                    <th>{t('Warranty Period')}</th>
                    <th>{t('Description English')}</th>
                    <th>{t("Description Arabic")}</th>
                    <th></th>
                </thead>
                <tbody>
                {
                    items && items?.map((item:any,index:number)=>(
                        <tr key={item?.id}>
                            <td>
                                {/* <Link to='/' className={styles['appointments__table-link']}>سالم محمد عبدالله</Link> */}
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.title_en} </p>
                            </td>
                            <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.title_ar} </p>
                            </td>
                            <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.warranty_period} </p>
                            </td>
                            <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.description_en} </p>
                            </td>
                            <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.description_ar} </p>
                            </td>
                            <td>
                                {
                                    user?.permission_type!='viewer' &&
                                        <Link to={`update-product/${item?.id}`} className={styles['appointments__table-link']}>
                                            <PencilIcon className={styles['appointments__table-link-icon']}/>
                                            {t('Update Product')}
                                        </Link>
                                }
                                {
                                    user?.permission_type=='admin' &&
                                    <button onClick={()=>{deleteItem(item?.id,index)}} disabled={isDeleting[index]}
                                        className={styles['appointments__table-delete']}>
                                        {
                                            !isDeleting[index]?t('Delete')
                                            :<ReactLoading type={"spin"} color={'red'} height={20} width={20} />
                                        }
                                    </button>
                                }
                            </td>
                        </tr>
                ))}
                </tbody>
            </Table>
        </>
    )
}

export default AllProductsTable
import { useTranslation } from 'react-i18next'
import styles from './Orders.module.css'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import { Link, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import AllClientsTable from 'components/Tables/AllClientsTable/AllClientsTable'
import AllOrdersTable from 'components/Tables/AllOrdersTable/AllOrdersTable'
import ReactLoading from "react-loading";
import PaginationElement from 'components/Global/Elements/PaginationElement/PaginationElement'
function Orders() {
  const {t} =useTranslation()
  const [searchParams ,setSearchParams] = useSearchParams()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const [itemsCount,setItemsCount]=useState('0')
  const [orderType,setorderType]=useState('new')
  const [searchTerm,setSearchTerm]=useState('')
  const [technician,setTechnician]=useState('')
  const [status,setStatus]=useState('')
  const [items,setItems]=useState([])
  const [providers,setProviders]=useState([])
  const [currentPage,setCurrentPage]=useState(1)
  const [pages,setPages]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])

  function getAllProviders(){
    setIsLoading(true)
    axiosConfig.get(`/provider/all-providers-without-pagination`,{
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
          
      }
    }).then(res=>{
      setProviders(res.data?.data)
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    })
  }

  function getAllItems(){
    setItems([])
    setIsLoading(true)
    axiosConfig.get(`/orders/all-users-orders?page=${currentPage}&order_type=${orderType}&item_number=${searchTerm}&technician_id=${technician}&status=${status}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setIsLoading(false)
      setItems(res.data?.data?.data)
      setPages(res.data?.data?.pages)
      let deletedNumber:any = [...Array(res.data.data.count)]
      setItemsCount(res.data.data.count)
      setIsDeleting(deletedNumber)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }

  function deleteItem(deltedId:String ,index:any){
    let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
      axiosConfig.delete(`/orders/delete-order/${deltedId}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
            let modifiedDelete:[Boolean]=[...isDeleting]
            modifiedDelete[index]=false
            setIsDeleting(modifiedDelete)
            toast.success(t('Item Deleted Successfully'))
            getAllItems()
      }).catch(err=>{
          let modifiedDelete:[Boolean]=[...isDeleting]
          modifiedDelete[index]=false
          setIsDeleting(modifiedDelete)
          toast.error(err?.response?.data?.message)
          console.log(err)
      })
  }

  function assignOrderToProvider(orderId:String ,providerId:String,index:number){
    let modifiedDelete:[Boolean]=[...isDeleting]
    modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
    axiosConfig.post(`/orders/assign-order-to-provider/`,{order_id:orderId,provider_id:providerId},{
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=false
      setIsDeleting(modifiedDelete)
      toast.success(t('Order Assigned Successfully'))
      getAllItems()
    }).catch(err=>{
      let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=false
      setIsDeleting(modifiedDelete)
      toast.error(err?.response?.data?.message)
      console.log(err)
    })
  }

  const handlePageChange = (page:any) => {
    setItems([])
    setCurrentPage(page?.selected +1);
  };

  useEffect(()=>{
    getAllItems()
  },[currentPage, orderType, technician, status])

  useEffect(()=>{
    getAllProviders()
  },[])
  
  useEffect(()=>{
    if(searchParams.get('technician_id')){
      setTechnician(searchParams.get('technician_id') || '')
    }
  },[searchParams.get('technician_id')])

  useEffect(()=>{
    if(searchParams.get('order_type')=='new' || searchParams.get('order_type')=='maintenance'){
      setorderType(searchParams.get('order_type') || 'new')
    }
  },[searchParams.get('order_type')])
  

  useEffect(()=>{
    if(searchParams.get('order_status')){
      setStatus(searchParams.get('order_status') || '')
    }
  },[searchParams.get('order_status')||''])
  
  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          <div className={styles['page-header']}>
            <div className={styles['page-header__top-wrapper']}>
              <h1 className={styles['page-header__title']}>
                {t('Orders')}
                <span className={styles['page-header__title-count']}>{itemsCount}</span>
              </h1>
              <select 
              className={styles['form__content-form-input']}
              value={orderType}
              onChange={(e)=>setorderType(e.target.value)}
              >
                <option value='new'>{t('New Orders')}</option>
                <option value='maintenance'>{t('Maintenance Orders')}</option>
              </select>
              
              {
                user?.permission_type=='admin' &&
                  <Link to='add-order' className={styles['page-header__link']}>
                    {t('Add Order')}
                    <PlusIcon/>
                  </Link>
              }
            </div>
            
            {/* <div className={styles['page-header__search-inputs-wrapper']}>
              <input 
                type='text'
                className={styles['page-header__search-input']}
                placeholder={`${t("Enter the customer's name, phone number, and invoice number")}`}
              />
              <input 
                type='date'
                className={styles['page-header__search-input']}
                // placeholder={`${t("Enter the customer's name, phone number, and invoice number")}`}
              />
              <button className={styles['page-header__search-submit']}>{t('Search')}</button>
            </div> */}
            <div className={styles['page-header__search-inputs-wrapper']}>
              <div className={styles['page-header__search-input-wrapper']}>
                <select 
                className={styles['form__content-form-input']}
                value={technician}
                onChange={(e)=>setTechnician(e.target.value)}
                >
                  <option value=''>{t('Please Select Technician')}</option>
                    {
                        providers?.map((provider:any)=>(
                            <option value={provider?.id}>{provider?.name}</option>
                        ))
                    }
                </select>
              </div>
              <div className={styles['page-header__search-input-wrapper']}>
                <select 
                className={styles['form__content-form-input']}
                value={status}
                onChange={(e)=>setStatus(e.target.value)}
                >
                  <option value=''>{t('Please Select Order Status')}</option>
                  <option value={1}>{t('New')}</option>
                  <option value={2}>{t('Completed')}</option>
                  <option value={3}>{t('Cancelled')}</option>
                  <option value={4}>{t('Reasigned')}</option>
                </select>
              </div>
              <div className={styles['page-header__search-input-wrapper']}>
                <input 
                  type='text'
                  className={styles['page-header__search-input']}
                  placeholder={`${t("Enter the Order number")}`}
                  value={searchTerm}
                  onChange={(e)=>setSearchTerm(e.target.value)}
                />
                <button 
                  type='button' 
                  className={styles['page-header__search-submit']}
                  onClick={getAllItems} 
                  disabled={isLoading}>
                  {
                    !isLoading?
                    <>{t('Search')} </>
                    :<ReactLoading type={"spin"} color={'#5885C3'} height={30} width={30} />
                  }
                </button> 
              </div>
            </div>
          </div>
        </Container>
        
        <AllOrdersTable 
          items={items} 
          deleteItem={deleteItem} 
          isDeleting={isDeleting} 
          providers={providers}
          assignOrderToProvider={assignOrderToProvider}
        />
        <PaginationElement
          pageCount={Math.ceil(Number(itemsCount) / 10)}
          onPageChange={handlePageChange}
        />
      </section>
    </>
  )
}

export default Orders
import { useTranslation } from 'react-i18next'
import styles from './AddUpdateClients.module.css'
import {ReactComponent as ClockIcon} from 'assets/icons/clockIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import MapModel from 'components/Global/Elements/MapModel/MapModel'
import { useNavigate, useParams } from 'react-router-dom'
import { emailPattern } from 'utils/features'
import Resizer from "react-image-file-resizer";
import {ReactComponent as UploadedIcon} from 'assets/icons/uploadedIcon.svg'
interface UserType {
  name:string;
  phone:string;
  email:string;
  password:string;
}
function AddUpdateClients() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()

  const [name,setName]=useState('')
  const [phone,setPhone]=useState('')
  const [whatsPhone,setWhatsPhone]=useState('')
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const [subscriptionPrice,setSubscriptionPrice]=useState('')
  const [subscriptionPeriod,setSubscriptionPeriod]=useState('')
  const [joiningDate,setJoiningDate]=useState('')
  const [joiningEndDate,setJoiningEndDate]=useState('')
  const [latitude,setLatitude]=useState('')
  const [longitude,setLongitude]=useState('')
  const [status,setStatus]=useState('')
  const [state,setState]=useState('')
  const [district,setDistrict]=useState('')
  const [clientLocation,setClientLocation]=useState('')
  const [states,setStates]=useState([])
  const [show,setShow]=useState(false)
  const [item,setItem]=useState<any>()
  const [isLoading,setIsLoading]=useState(false)
  const [images,setImages]=useState<any>([])
  
  function getItem(){
    setIsLoading(true)
    axiosConfig.get(`/user/single-user-by-id/${params?.id}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setIsLoading(false)
      setItem(res.data?.data)
      setShow(true)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }
  
  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('User Name Is Required'))
      status=false
    }
    if(!phone){
      toast.error(t('User Phone Is Required'))
      status=false
    }
    if(!latitude ||!longitude){
      toast.error(t('User Location Is Required'))
      status=false
    }
    if(!emailPattern.test(email)){
      toast.error(t('User Email Is Required'))
      status=false
    }
    if(!params?.id){
      if(!password ){
        toast.error(t('User Password Is Required'))
        status=false
      }
    }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('name',name)
    formData.append('phone_number',phone)
    formData.append('whatsapp_number',whatsPhone)
    // formData.append('subscription_price',subscriptionPrice)
    // formData.append('subscription_period',subscriptionPeriod)
    formData.append('current_latitude',latitude)
    formData.append('current_longitude',longitude)
    formData.append('location_link',clientLocation)
    formData.append('joining_date',joiningDate)
    // formData.append('joining_End_date',joiningEndDate)
    formData.append('email',email)
    formData.append('status',status)
    formData.append('district',district)
    formData.append('state_id',state)
    password&&formData.append('password',password)

    images?.map((image:any,index:number)=>{
      formData.append(`additional_documents`,image)
    })
    if(item){
      axiosConfig.put(`/user/update-user/${params?.id}`,formData,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res=>{
        setIsLoading(false)
        toast.success(t(`user Updated Successfully`))
        navigate('/our-clients')
      }).catch(err=>{
        toast.error(err?.response?.data?.message)
        setIsLoading(false)
        // toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/user/create-user`,formData,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res=>{
        setIsLoading(false)
        toast.success(t(`user Added Successfully`))
        navigate('/our-clients')
      }).catch(err=>{
        toast.error(err?.response?.data?.message)
        setIsLoading(false)
        // toast.error(t('Something went wrong'))
      })
    }
  }
  
  async function handleUploadedImages(e:any){
    let files =e.target.files
    let uploadedFile:any = [...images]
    if((files?.length+images?.length)>8){
        toast.error(t("You Can't upload more than 8 images"))
        return
    }
    files = await Promise.all([...files ].map(async(file) =>{
        let image:any = await new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                500,
                "PNG",
                100,
                0,
                (uri) => {
                    console.log(uri)
                    resolve(uri);
                },
                "file"
            );
        });
        Object.assign(image, {
            preview: URL.createObjectURL(image),
        })
        uploadedFile.push(image)
    }))
    setImages(uploadedFile)
  }

  function getAllStates(){
    axiosConfig.get(`/state/all-states-without-pagination`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setStates(res.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }

  function deleteImg(index:any){
    let modifiedImages=[...images]
    modifiedImages.splice(index,1)
    setImages(modifiedImages)
  }

  useEffect(()=>{
    if(params?.id){
      getItem()
    }
  },[params])

  useEffect(()=>{
    getAllStates()
  },[])
  
  useEffect(()=>{
    if(item){
      setName(item?.name)
      setPhone(item?.phone_number)
      setEmail(item?.email)
      setWhatsPhone(item?.whatsapp_number)
      setSubscriptionPrice(item?.subscription_price)
      setSubscriptionPeriod(item?.subscription_period)
      setJoiningDate(item?.joining_date)
      setJoiningEndDate(item?.joining_End_date)
      setLatitude(item?.current_latitude)
      setLongitude(item?.current_longitude)
      setClientLocation(item?.location_link)
      setStatus(item?.status)
      setState(item?.state_id)
      setDistrict(item?.district)
      setImages(item?.additional_documents)
      // setPassword(item?.password)
    }
  },[item])
  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{params?.id?t('Update Client'):t('Add New Client')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Client Name')}`}
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                />
                <input 
                  type='password'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Client Password')}`}
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                />
                <input 
                  type='email'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Client Email')}`}
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Phone Number')}`}
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                />
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Whatsapp Number')}`}
                  value={whatsPhone}
                  onChange={(e)=>setWhatsPhone(e.target.value)}
                />
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('District')}`}
                  value={district}
                  onChange={(e)=>setDistrict(e.target.value)}
                />
                <select 
                  className={styles['form__content-form-input']}
                  value={state}
                  onChange={(e)=>setState(e.target.value)}
                >
                  <option value=''>{t('Please Select State')}</option>
                  {
                    states?.map((state:any)=>(
                      <option value={state?.id} key={state?.id}>{state?.title}</option>
                    ))
                  }
                </select>
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Client Location')}`}
                  value={clientLocation}
                  onChange={(e)=>setClientLocation(e.target.value)}
                />
                <input 
                  type='datetime-local'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Joining date')}`}
                  value={joiningDate}
                  onChange={(e)=>setJoiningDate(e.target.value)}
                />
                {/* <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Address')}`}
                /> */}
                {/* 
                <input 
                  type='datetime-local'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Joining End date')}`}
                  value={joiningEndDate}
                  onChange={(e)=>setJoiningEndDate(e.target.value)}
                />
                <input 
                  type='number'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Subscription Period')}`}
                  value={subscriptionPeriod}
                  onChange={(e)=>setSubscriptionPeriod(e.target.value)}
                /> */}
                {/* <select
                  className={styles['form__content-form-input']}
                >
                  <option value=''>{t('Product Name')}</option>
                  <option value=''>{t('Product Name')}</option>
                  <option value=''>{t('Product Name')}</option>
                </select>
                <select
                  className={styles['form__content-form-input']}
                >
                  <option value=''>{t('Warranty Status')}</option>
                  <option value=''>{t('Product Name')}</option>
                  <option value=''>{t('Product Name')}</option>
                </select> */}
                {/* <input 
                  type='datetime-local'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Ending date')}`}
                /> */}
                {/* <input 
                  type='number'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Subscription Price')}`}
                  value={subscriptionPrice}
                  onChange={(e)=>setSubscriptionPrice(e.target.value)}
                /> */}
                <select 
                  className={styles['form__content-form-input']}
                  value={status}
                  onChange={(e)=>setStatus(e.target.value)}
                >
                  <option value=''>{t('Please Select Status')}</option>
                  <option value='1'>{t('Active')}</option>
                  <option value='2'>{t('Block')}</option>
                </select>
                <button type='button' className={styles['form__content-form-input']} onClick={()=>setShow(prevState=>!prevState)}>
                  {t('Client Location')}
                  <GoogleIcon />
                </button>
                <div className='d-flex p-3'>
                  <label className={styles['form__content-form-input-text']} htmlFor='uploadImages'>
                    <UploadedIcon/>
                    {t('Upload Images')}
                  </label>
                  <input
                      type='file'
                      className='d-none'
                      id='uploadImages'
                      onChange={(e:any) => handleUploadedImages(e)}
                      multiple
                  />
                </div>
                {/* <input 
                  type='number'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Contract Period')}`}
                /> */}
                  
              </div>
              
              <div className={styles['form__content-uploaded-images-wrapper']}>
                {
                  images&&images.map((img:any,index:any)=>(
                      <div key={index} className={styles['form__content-uploaded-image-wrapper']}>
                          <img src={img?.preview ?img?.preview:img} alt='img' className={styles['form__content-uploaded-image']}/>
                          <button type='button' onClick={()=>{deleteImg(index)}} className={styles['form__content-uploaded-image-delete-icon']}>X</button>
                      </div>
                  ))
                }
              </div>
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?
                  <>{params?.id?t('Update'):t('Add User')} </>
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
            <MapModel 
              setLatitude={setLatitude} 
              setLongitude={setLongitude} 
              // isItemLoading={isItemLoading} 
              longitude={longitude} 
              latitude={latitude}
              show={show}
              setShow={setShow}
            />
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default AddUpdateClients
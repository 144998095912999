import { useTranslation } from 'react-i18next'
import styles from './AddUpdateTechnicians.module.css'
import {ReactComponent as UploadedIcon} from 'assets/icons/uploadedIcon.svg'
import {ReactComponent as PatientsMenuIcon} from 'assets/icons/patientsMenuIcon.svg'
import userImg from 'assets/imgs/userImg.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { ItemsListPagination } from 'components/Global/Elements/ItemsListPagination/ItemsListPagination'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import TableElement from 'components/Global/Elements/TableElement/TableElement'
import Resizer from "react-image-file-resizer";
import { emailPattern } from 'utils/features'
function AddUpdateTechnicians() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [name,setName]=useState('')
  const [phone,setPhone]=useState('')
  const [address,setAddress]=useState('')
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const [subscriptionPrice,setSubscriptionPrice]=useState('')
  const [workingStartDate,setWorkingStartDate]=useState('')
  const [contractPeriod,setContractPeriod]=useState('')
  const [latitude,setLatitude]=useState('')
  const [longitude,setLongitude]=useState('')
  const [status,setStatus]=useState('1')
  const [show,setShow]=useState(false)
  const [item,setItem]=useState<any>()
  const [isLoading,setIsLoading]=useState(false)
  const [images,setImages]=useState<any>([])
  
  function getItem(){
    setIsLoading(true)
    axiosConfig.get(`/provider/single-provider/${params?.id}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setIsLoading(false)
      setItem(res.data?.data)
      setShow(true)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }

  async function handleUploadedImages(e:any){
    let files =e.target.files
    let uploadedFile:any = [...images]
    if((files?.length+images?.length)>8){
        toast.error(t("You Can't upload more than 8 images"))
        return
    }
    files = await Promise.all([...files ].map(async(file) =>{
        let image:any = await new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                500,
                "PNG",
                100,
                0,
                (uri) => {
                    console.log(uri)
                    resolve(uri);
                },
                "file"
            );
        });
        Object.assign(image, {
            preview: URL.createObjectURL(image),
        })
        uploadedFile.push(image)
    }))
    setImages(uploadedFile)
  }

  function deleteImg(index:any){
    let modifiedImages=[...images]
    modifiedImages.splice(index,1)
    setImages(modifiedImages)
  }
  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('User Name Is Required'))
      status=false
    }
    if(!phone){
      toast.error(t('User Phone Is Required'))
      status=false
    }
    // if(!emailPattern.test(email)){
    //   toast.error(t('User Email Is Required'))
    //   status=false
    // }
    // if(!password){
    //   toast.error(t('User Password Is Required'))
    //   status=false
    // }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('name',name)
    formData.append('phone_number',phone)
    formData.append('contract_period',contractPeriod)
    formData.append('address',address)
    formData.append('working_start_date',workingStartDate)
    formData.append('email',email)
    formData.append('status',status)
    password&&formData.append('password',password)
    images?.map((image:any,index:number)=>{
      formData.append(`additional_documents`,image)
    })
    if(item){
      axiosConfig.put(`/provider/update-provider/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Technician Updated Successfully`))
        navigate('/technicians')
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/provider/create-provider`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Technician Added Successfully`))
        navigate('/technicians')
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }
  useEffect(()=>{
    if(params?.id){
      getItem()
    }
  },[params])
  
  useEffect(()=>{
    if(item){
      setName(item?.name)
      setPhone(item?.phone_number)
      setAddress(item?.address)
      setWorkingStartDate(item?.working_start_date)
      setContractPeriod(item?.contract_period)
      setImages(item?.additional_documents)
    }
  },[item])
  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{params?.id?t('Update Technician'):t('Add New Technician')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Technician Name')}`}
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                />
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Phone Number')}`}
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                />
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Address')}`}
                  value={address}
                  onChange={(e)=>setAddress(e.target.value)}
                />
                <input 
                  type='number'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Contract Period')}`}
                  value={contractPeriod}
                  onChange={(e)=>setContractPeriod(e.target.value)}
                />
                <input 
                  type='password'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Password')}`}
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                />
                  <input 
                    type='datetime-local'
                    className={styles['form__content-form-input']}
                    placeholder={`${t('Service start date')}`}
                    value={workingStartDate}
                    onChange={(e)=>setWorkingStartDate(e.target.value)}
                  />
                  {/* <select 
                    className={styles['form__content-form-input']}
                    value={status}
                    onChange={(e)=>setStatus(e.target.value)}
                  >
                    <option value=''>{t('Please Select Status')}</option>
                    <option value='1'>{t('Active')}</option>
                    <option value='2'>{t('Block')}</option>
                  </select> */}
                  <div className='d-flex p-3'>
                    <label className={styles['form__content-form-input-text']} htmlFor='uploadImages'>
                      <UploadedIcon/>
                      {t('Upload Images')}
                    </label>
                    <input
                        type='file'
                        className='d-none'
                        id='uploadImages'
                        onChange={(e:any) => handleUploadedImages(e)}
                        multiple
                    />
                  </div>
              </div>
              <div className={styles['form__content-uploaded-images-wrapper']}>
                {
                  images&&images.map((img:any,index:any)=>(
                      <div key={index} className={styles['form__content-uploaded-image-wrapper']}>
                          <img src={img?.preview ?img?.preview:img} alt='img' className={styles['form__content-uploaded-image']}/>
                          <button type='button' onClick={()=>{deleteImg(index)}} className={styles['form__content-uploaded-image-delete-icon']}>X</button>
                      </div>
                  ))
                }
              </div>
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?
                  <>{params?.id?t('Update'):t('Add Technician')} </>
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
              
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default AddUpdateTechnicians
import styles from './AllTechnicinasTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './AllTechnicinasTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
function AllTechnicinasTable(props:any) {
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)

    const { items ,deleteItem,isDeleting } = props
    const {t} = useTranslation()
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('#')}</th>
                    <th>{t('Technician Name')}</th>
                    <th>{t('Finished Requests')}</th>
                    <th>{t('Requests In Progress')}</th>
                    <th>{t("Technician Place")}</th>
                    <th>{t('Testaments Count')}</th>
                    <th>{t('Testament Received')}</th>
                    {/* <th>{t('Status')}</th> */}
                    {/* <th>{t('Referring Requests To The Technician')}</th> */}
                    {/* <th></th> */}
                    <th></th>
                </thead>
                <tbody>
                {
                    items && items?.map((item:any,index:number)=>(
                        <tr key={item?.id}>
                            <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.item_number} </p>
                            </td>
                            <td>
                                <Link to={`technician-details/${item?.id}`} className={styles['appointments__table-link']}>{item?.name}</Link>
                            </td>
                            <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.finished_orders_count} {t('Order')} </p>
                            </td>
                            <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.processing_orders_count} {t('Orders')} </p>
                            </td>
                            <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.address} </p>
                            </td>
                            <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.testments_count} </p>
                            </td>
                            <td>
                                <Link to={`testaments/${item?.id}`} className={styles['appointments__table-link']}>{t('Details Of The testaments')}</Link>
                            </td>
                            {/* <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> 
                                    {
                                        item?.status==1?t('Active'):
                                        item?.status==2?t('Block'):''
                                    }
                                </p>
                            </td> */}
                            {/* <td>
                                <select className={styles['form__content-form-input']}>
                                    <option value=''>{t('Technician Name')}</option>
                                    <option value=''>{t('Technician Name')}</option>
                                    <option value=''>{t('Technician Name')}</option>
                                </select>
                            </td> */}
                            {/* <td>
                                <Link to='/' className={styles['appointments__table-link']}>{t('Technical Details')}</Link>
                            </td> */}
                            {/* <td>
                                <Link to={`update-technician/${item?.id}`} className={styles['appointments__table-link']}>
                                    <PencilIcon className={styles['appointments__table-link-icon']}/>
                                    {t('Update Technical')}
                                </Link>
                            </td> */}
                            <td>
                                {/* <Link to={`client-details/${item?.id}`} className={styles['appointments__table-link']}>
                                    {t('More')}
                                </Link> */}
                                {
                                    user?.permission_type!='viewer' &&
                                        <Link to={`update-technician/${item?.id}`} className={styles['appointments__table-link']}>
                                            <PencilIcon className={styles['appointments__table-link-icon']}/>
                                            {t('Update Technical')}
                                        </Link>
                                }
                                {
                                    user?.permission_type=='admin' &&
                                    <button onClick={()=>{deleteItem(item?.id,index)}} disabled={isDeleting[index]}
                                        className={styles['appointments__table-delete']}>
                                        {
                                            !isDeleting[index]?t('Delete')
                                            :<ReactLoading type={"spin"} color={'red'} height={20} width={20} />
                                        }
                                    </button>
                                }
                            </td>
                        </tr>
                ))}
                </tbody>
            </Table>
        </>
    )
}

export default AllTechnicinasTable
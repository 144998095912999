import { useTranslation } from 'react-i18next'
import styles from './OrderDetails.module.css'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BASE_URL, axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import AllClientsTable from 'components/Tables/AllClientsTable/AllClientsTable'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import {ReactComponent as WhatsappIcon} from 'assets/icons/whatsappIcon.svg'
import AllOrdersTable from 'components/Tables/AllOrdersTable/AllOrdersTable'
import AllInstallmentsTable from 'components/Tables/AllInstallmentsTable/AllInstallmentsTable'
import AllClientOrdersTable from 'components/Tables/AllClientOrdersTable/AllClientOrdersTable'
import receiptsImg from 'assets/imgs/receiptsImg.png'
import { whtsAppConnect } from 'utils/features'
import OrderUsedTestamentsTable from 'components/Tables/OrderUsedTestamentsTable/OrderUsedTestamentsTable'
import {ReactComponent as PrinterIcon} from 'assets/icons/printerIcon.svg'
import { useCookies } from 'react-cookie' 
function OrderDetails() {
  const {t} =useTranslation()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const params = useParams()
  const [item,setItem]=useState({} as any)
  const [providers,setProviders]=useState([])
  const [provider,setProvider]=useState('')
  const [status,setStatus]=useState('')
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  function getAllItems(){
      setIsLoading(true)
      axiosConfig.get(`/orders/single-order/${params?.id}`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
          
        }
      }).then(res=>{
        setItem(res.data?.data)
        setProvider(res.data?.data?.provider?.id)
        setStatus(res.data?.data?.status)
      }).catch(err=>{
        setIsLoading(false)
        console.log(err)
      })
  }
  function getAllProviders(){
    setIsLoading(true)
    axiosConfig.get(`/provider/all-providers-without-pagination`,{
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
          
      }
    }).then(res=>{
      setProviders(res.data?.data)
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    })
  }

  function assignOrderToProvider(orderId:String ,providerId:String){
    setIsLoading(true)
    setProvider(providerId as string)
    axiosConfig.post(`/orders/assign-order-to-provider/`,{order_id:orderId,provider_id:providerId},{
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setIsLoading(false)
      toast.success(t('Order Assigned Successfully'))
      getAllItems()
    }).catch(err=>{
      setIsLoading(false)
      toast.error(err?.response?.data?.message)
      console.log(err)
    })
  }
  function changeOrderStatus(orderId:String ,orderStatus:String){
    setIsLoading(true)
    setStatus(orderStatus as string)
    axiosConfig.post(`/orders/change-order-status`,{order_id:orderId,order_status:orderStatus},{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setIsLoading(false)
      toast.success(t('Order Status Changed Successfully'))
      getAllItems()
    }).catch(err=>{
      setIsLoading(false)
      toast.error(err?.response?.data?.message)
      console.log(err)
    })
  }
  useEffect(()=>{
    getAllProviders()
    getAllItems()
  },[])

  function secondsToHMS(seconds:any) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  
  const handleShare = () => {
    const pdfGenerationUrl = `${BASE_URL}/user/export-user-pdf/${item?.user?.id}?lang=${cookies?.i18next=='en'?'en':'ar'}`;
    const message = `Check out this PDF`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${item?.user?.whatsapp_number}?text=${encodedMessage}`;
    window.open(pdfGenerationUrl);
    window.open(whatsappLink);
  };
  return (
    <>
      <section className={styles['client-details']}>
        <Container>
          <div className={styles['client-details__header-wrapper']}>
            <h1 className={styles['client-details__header-title']}>{t('Order Details')}</h1>
            <a href={`${BASE_URL}/orders/export-order-details-pdf/${params?.id}?lang=${cookies?.i18next=='en'?'en':'ar'}`} target="_blank" className={styles['page-header__link']}>
              {t('Print')}
              <PrinterIcon/>
            </a>
            {
              user?.user_type=='admin' &&
              <button onClick={handleShare} className={styles['client-details__header-link']}>
                {t('Download the details or request data')}
                <WhatsappIcon className={styles['client-details__header-link-icon']}/>
              </button>
            }
          </div>
          <Row>
            <Col lg='7' className='mb-5'>
              <div className={styles['client-details__info-wrapper']}>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Order No')}</p>
                  <p className={styles['client-details__info-value']}>#{item?.item_number}</p>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Created At')}</p>
                  <p className={styles['client-details__info-value']}>{item?.created_at}</p>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Order Price')}</p>
                  <p className={styles['client-details__info-value']}>{item?.order_required_price} {t('AED')}</p>
                </div>

                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Order Type')}</p>
                  <p className={styles['client-details__info-value']}>
                  {
                    item?.order_type =='new' ?t('New'):
                    item?.order_type =='maintenance' ?t('Maintenance'):
                    ''
                  }
                  </p>
                </div>
                {/* <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Testaments')}</p>
                  <Link to={`/orders/order-testments/${item?.id}`} className={styles['client-details__info-link']}>
                    {t('Testaments')}
                  </Link>
                </div> */}
                  <div className={styles['client-details__info-item-wrapper']}>
                    <p className={styles['client-details__info-title']}>{t('Product Name')}</p>
                    <p className={styles['client-details__info-value']}>{item?.product?.title}</p>
                  </div>
                {/* <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Phone Number')}</p>
                  <p className={styles['client-details__info-value']}>{item?.user?.phone_number}</p>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Whatsapp Number')}</p>
                  <p className={styles['client-details__info-value']}>{item?.user?.whatsapp_number}</p>
                </div> */}
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('User Name')}</p>
                  {/* <p className={styles['client-details__info-value']}>{item?.user?.name}</p> */}
                  <Link to={`/our-clients/client-details/${item?.user?.id}`} className={styles['client-details__info-link']}>
                      {item?.user?.name}
                  </Link>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Phone Number')}</p>
                  <button role='button' onClick={()=>{window.open(`tel:${item?.user?.phone_number}`, '_self')}} className={styles['client-details__info-link']}>
                    {item?.user?.phone_number}
                  </button>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Whatsapp Number')}</p>
                  <a href={whtsAppConnect(item?.user?.whatsapp_number)} target='_blank' className={styles['client-details__info-link']} >
                    {item?.user?.whatsapp_number}
                  </a>
                </div>
                {/* <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Address')}</p>
                  <button className={styles['client-details__info-button']}>
                    <GoogleIcon />
                  </button>
                </div> */}
                {/* <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Subscribe Date')}</p>
                  <p className={styles['client-details__info-value']}>22/8/2023</p>
                </div> */}
                {/* <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Subscribe Period')}</p>
                  <p className={styles['client-details__info-value']}>{item?.user?.subscription_period}</p>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Subscription Amount')}</p>
                  <p className={styles['client-details__info-value']}>{item?.user?.subscription_price} {t('AED')}</p>
                </div> */}
                {/* <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Device Type')}</p>
                  <p className={styles['client-details__info-value']}>نوع الجهاز 2</p>
                </div> */}
                {
                  (item?.order_type =='maintenance')&&
                  <>
                    <div className={styles['client-details__info-item-wrapper']}>
                      <p className={styles['client-details__info-title']}>{t('Warranty Status')}</p>
                      <p className={styles['client-details__info-value']}>
                        {
                          item?.product?.warranty_status == 'in_warranty'? t('In Warranty'):
                          item?.product?.warranty_status == 'out_warranty'? t('Out Warranty'):'---'
                        }
                      </p>
                    </div>
                  </>
                }
                {
                  (item?.order_type =='maintenance' &&item?.status =='2')&&
                  <>
                    {
                      user?.user_type=='admin' &&
                        <div className={styles['client-details__info-item-wrapper']}>
                          <p className={styles['client-details__info-title']}>{t('Product Status')}</p>
                          <p className={styles['client-details__info-value']}>
                            {
                              item?.product_status == 'fixed'? t('Fixed'):
                              item?.product_status == 'send_to_fix'? t('Send To Fix'):'---'
                            }
                          </p>
                        </div>
                    }
                  </>
                }
                {
                  // (item?.order_type =='maintenance' &&item?.status =='2')&&
                  (item?.status =='2')&&
                  <>
                    {
                      user?.user_type=='admin' &&
                      <div className={styles['client-details__info-item-wrapper']}>
                        <p className={styles['client-details__info-title']}>{t('Working Time')}</p>
                        <p className={styles['client-details__info-value']}>{secondsToHMS(item?.order_working_time||0)||'---'}</p>
                      </div>
                    }
                    <div className={styles['client-details__info-item-wrapper']}>
                      <p className={styles['client-details__info-title']}>{t('Payment Method')}</p>
                      <p className={styles['client-details__info-value']}>
                        {
                          item?.payment_method == 'cash'? t('Cash'):
                          item?.payment_method == 'machine'? t('Machine'):
                          item?.payment_method == 'bank_deposite'? t('Bank Deposite'):
                          item?.payment_method == 'bank_deposit'? t('Bank Deposite'):
                          item?.payment_method == 'cheque'? t('Cheque'):
                          item?.payment_method == 'taby'? t('Taby'):
                          item?.payment_method == 'tab'? t('Tab'):
                          item?.payment_method == 'not_paid'? t('Not Paid'):
                          item?.payment_method == 'free'? t('Free'):
                          '---'
                        }
                      </p>
                    </div>
                    <div className={styles['client-details__info-item-wrapper']}>
                      <p className={styles['client-details__info-title']}>{t('Paid Amount')}</p>
                      <p className={styles['client-details__info-value']}>{item?.paid_amount||'---'}</p>
                    </div>
                    {
                      item?.payment_method == 'not_paid' && 
                        <div className={styles['client-details__info-item-wrapper']}>
                          <p className={styles['client-details__info-title']}>{t('Update Payment')}</p>
                          <Link to={`/orders/update-payment/${params?.id}`} className={styles['form__content-form-input-google']}>
                            {t('Update Payment')}
                          </Link>
                        </div>
                    }
                    {
                      user?.user_type=='admin' &&
                      <div className={styles['client-details__info-item-wrapper']}>
                        <p className={styles['client-details__info-title']}>{t('Payment Transaction Number')}</p>
                        <p className={styles['client-details__info-value']}>{item?.payment_transaction_number||'---'}</p>
                      </div>
                    }
                  </>
                }
                
                {
                  user?.user_type=='admin' &&
                    <div className={styles['client-details__info-item-wrapper']}>
                      <p className={styles['client-details__info-title']}>{t('Client Location')}</p>
                      <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${item?.user_latitude},${item?.user_longitude}`} className={styles['form__content-form-input-google']}>
                          <GoogleIcon className={styles['form__content-form-input-icon']}/>
                      </a>
                    </div>
                }

                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Client Location')}</p>
                  <a target='_blank' href={item?.user_order_address_link} className={styles['form__content-form-input-google']}>
                    {t('Address Link')}
                  </a>
                </div>
                {
                  (item?.order_type =='maintenance' )&&
                  <div className={styles['client-details__info-item-wrapper']}>
                    <p className={styles['client-details__info-title']}>{t('Receipt Number')}</p>
                    <p className={styles['client-details__info-value']}>{item?.receipt_number}</p>
                  </div>
                }
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('State')}</p>
                  <p className={styles['client-details__info-value']}>{item?.user?.state}</p>
                </div>
                {
                  user?.user_type=='admin' &&
                  <div className={styles['client-details__info-item-wrapper']}>
                    <p className={styles['client-details__info-title']}>{t('Technician Location')}</p>
                    <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${item?.provider?.current_latitude},${item?.provider?.current_longitude}`} className={styles['form__content-form-input-google']}>
                        <GoogleIcon className={styles['form__content-form-input-icon']}/>
                    </a>
                  </div>
                }
                {/* <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Warranty End Date')}</p>
                  <p className={styles['client-details__info-value']}>منتهي</p>
                </div> */}
              </div>
            </Col>

            <Col lg='5' className='mb-5'>
              {
                (user?.user_type=='admin' && user?.user_permission!='viewer' && item?.status!='2')?
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Technician')}</p>
                  <select className={styles['client-details__info-value--input']}
                    onChange={(e)=>{assignOrderToProvider(item?.id,e.target.value)}}
                    value={provider}
                  >
                      <option value=''>{t('Please Select Technician')}</option>
                      {
                          providers?.map((provider:any)=>(
                              <option value={provider?.id}>{provider?.name}</option>
                          ))
                      }
                  </select>
                </div>
                :
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Technician')}</p>
                  <p className={styles['client-details__info-value--input']}>
                    {item?.provider?.name}
                  </p>
                </div>
              }

              {
                (user?.user_type=='admin' && user?.user_permission!='viewer')?
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Order Status')}</p>
                  <select className={styles['client-details__info-value--input']}
                    onChange={(e)=>{changeOrderStatus(item?.id,e.target.value)}}
                    value={status}
                  >
                      <option value=''>{t('Please Select Order Status')}</option>
                      <option value={1}>{t('New')}</option>
                      <option value={2}>{t('Completed')}</option>
                      <option value={3}>{t('Cancelled')}</option>
                      <option value={4}>{t('Reasigned')}</option>
                  </select>
                </div>
                :
                <></>
                // <div className={styles['client-details__info-item-wrapper']}>
                //   <p className={styles['client-details__info-title']}>{t('Order Status')}</p>
                //   <p className={styles['client-details__info-value--input']}>
                //     {
                //       item?.status ==1 ?t('New'):
                //       item?.status ==2 ?t('Completed'):
                //       item?.status ==3 ?t('Cancelled'):
                //       item?.status ==4 ?t('Reasigned'):
                //       ''
                //     }
                //   </p>
                // </div>
              }
              {
                // (item?.order_type =='maintenance' && item?.status=='2')&&
                (item?.status=='2' && user?.user_type=='admin')&&
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Fixing Report')}</p>
                  <p className={styles['client-details__info-value--input']}>{item?.fixing_report}</p>
                </div>
              }
              {
                // (item?.issue_details && item?.order_type =='maintenance' && item?.status=='2')&&
                (item?.additional_notes && user?.user_type=='admin')&&
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{item?.order_type =='maintenance'?t('Issue Details'):t('Notes')}</p>
                  <p className={styles['client-details__info-value--input']}>{item?.additional_notes}</p>
                </div>
              }
              {
                (item?.cancel_order_reason && item?.status=='3')&&
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Cancel Order Reason')}</p>
                  <p className={styles['client-details__info-value--input']}>{item?.cancel_order_reason}</p>
                </div>
              }
              {/* {
                (item?.used_testments && item?.used_testments?.length>0)&& */}
                {
                  user?.user_type=='admin' &&
                  <div className={styles['client-details__info-item-wrapper']}>
                    <p className={styles['client-details__info-title']}>{t('Used Testments')}</p>
                    <OrderUsedTestamentsTable items={item?.used_testments}/>
                  </div>
                }
              {/* } */}
              {/* <div className={styles['client-details__info-item-wrapper']}>
                <p className={styles['client-details__info-title']}>{t('Subscription Amount')}</p>
                <p className={styles['client-details__info-value--input']}>
                لوريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما
                </p>
              </div>
              <div className={styles['client-details__info-item-wrapper']}>
                <p className={styles['client-details__info-title']}>{t('Subscription Amount')}</p>
                <p className={styles['client-details__info-value--input']}>
                لوريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما
                </p>
              </div> */}
            </Col>
            {
              user?.user_type=='admin' &&
                <Col lg='12' className='mb-5'>
                  <div className={styles['client-details__info-receipts-wrapper']}>
                    {/* <h2 className={styles['client-details__info-receipts-title']}>{t('Receipts')}</h2> */}
                    <div className={styles['client-details__info-receipts-imgs-wrapper']}>
                      {
                        item?.additional_images?.map((img:any,index:number)=>(
                          <div className={styles['client-details__info-receipts-img-wrapper']} key={index}>
                            <img src={img} alt='Receipts' className={styles['client-details__info-receipts-img']}/>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </Col>
            }
          </Row>
        </Container>
      </section>
    </>
  )
}

export default OrderDetails
import styles from './IncomesReportsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './IncomesReportsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useState } from 'react'
import ReactLoading from "react-loading";
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
function IncomesReportsTable(props:any) {
    const {month,year,searchTerm}= props
    const {t} =useTranslation()
    const [items,setItems]=useState([])
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const [currentPage,setCurrentPage]=useState(1)
    const [pages,setPages]=useState(null)
    const [isDeleting,setIsDeleting] =useState<any>([false])
    function getAllItems(){
        axiosConfig.get(`/orders/month-payments?month=${month}&year=${year}&searchTerm=${searchTerm}`,{
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(res=>{
            setItems(res.data?.data?.orders)
            let deletedNumber:any = [...Array(res.data?.data?.orders.length)]
            setIsDeleting(deletedNumber)
        }).catch(err=>{
            console.log(err)
        })
    }
    
    function deleteItem(orderId:String ,index:any){
        let modifiedDelete:any=[...isDeleting]
        modifiedDelete[index]=true
        setIsDeleting(modifiedDelete)
        axiosConfig.delete(`/orders/delete-month-payments/${orderId}`,{
            headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res=>{
            let modifiedDelete:any=[...isDeleting]
            modifiedDelete[index]=false
            setIsDeleting(modifiedDelete)
            toast.success(t('Item Deleted Successfully'))
            getAllItems()
        }).catch(err=>{
            let modifiedDelete:any=[...isDeleting]
            modifiedDelete[index]=false
            setIsDeleting(modifiedDelete)
            toast.error(err?.response?.data?.message)
            console.log(err)
        })
    }
    useEffect(()=>{
        getAllItems()
    },[month,year,searchTerm])
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Client Name')}</th>
                    <th>{t('Client Phone')}</th>
                    <th>{t('Paid')}</th>
                    <th>{t('Date')}</th>
                    <th>{t('Payment Method')}</th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        items && items?.map((item:any, index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <Link to={`/our-clients/client-details/${item?.user_id}`} className={styles['appointments__table-link']}>
                                        {item?.user_name}
                                    </Link>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.user_phone}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.paid_amount} {t('AED')}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.created_at}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>
                                        {
                                            item?.payment_method == 'cash'? t('Cash'):
                                            item?.payment_method == 'machine'? t('Machine'):
                                            item?.payment_method == 'bank_deposit'? t('Bank Deposite'):
                                            item?.payment_method == 'cheque'? t('Cheque'):
                                            item?.payment_method == 'taby'? t('Taby'):
                                            item?.payment_method == 'tab'? t('Tab'):
                                            item?.payment_method == 'not_paid'? t('Not Paid'):
                                            item?.payment_method == 'free'? t('Free'):
                                            '---'
                                        }
                                    </p>
                                </td>
                                <td>
                                    {
                                        (user?.permission_type=='admin')&&
                                        <>
                                            <button onClick={()=>{deleteItem(item?.id,index)}} disabled={isDeleting[index] || false}
                                                className={styles['appointments__table-delete']}>
                                                {
                                                    !isDeleting[index]?t('Delete')
                                                    :<ReactLoading type={"spin"} color={'red'} height={20} width={20} />
                                                }
                                            </button>
                                        </>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default IncomesReportsTable
import styles from './AllClientsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './AllClientsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
function AllClientsTable(props:any) {
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const { items ,deleteItem,isDeleting } = props
    const {t} = useTranslation()
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('#')}</th>
                    <th>{t('Client Name')}</th>
                    <th>{t('Phone Number')}</th>
                    <th>{t('Whatsapp Number')}</th>
                    <th>{t('Email')}</th>
                    {/* <th>{t('Subscription Period')}</th>
                    <th>{t('Subscription Price')}</th> */}
                    <th>{t('State')}</th>
                    <th>{t('Status')}</th>
                    <th>{t('Installments')}</th>
                    <th>{t('Products')}</th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        items && items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.item_number}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.name}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.phone_number}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.whatsapp_number}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.email}</p>
                                </td>
                                {/* <td>
                                    <p className={styles['appointments__table-name']}>{item?.subscription_period}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.subscription_price}</p>
                                </td> */}
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.state}</p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> 
                                        {
                                            item?.status==1?t('Active'):
                                            item?.status==2?t('Block'):''
                                        }
                                    </p>
                                </td>
                                <td>
                                    <Link to={`client-installments/${item?.id}`} className={styles['appointments__table-link']}>
                                        {t('Installments')}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`client-products/${item?.id}`} className={styles['appointments__table-link']}>
                                        {t('Products')}
                                    </Link>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center gap-3'>

                                        <Link to={`client-details/${item?.id}`} className={styles['appointments__table-link']}>
                                            {t('More')}
                                        </Link>
                                        {
                                            user?.permission_type!='viewer' &&
                                            <Link to={`update-client/${item?.id}`} className={styles['appointments__table-link']}>
                                                {t('Update')}
                                            </Link>
                                        }
                                        {
                                            user?.permission_type=='admin' &&
                                            <button onClick={()=>{deleteItem(item?.id,index)}} disabled={isDeleting[index]}
                                                className={styles['appointments__table-delete']}>
                                                {
                                                    !isDeleting[index]?t('Delete')
                                                    :<ReactLoading type={"spin"} color={'red'} height={20} width={20} />
                                                }
                                            </button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default AllClientsTable
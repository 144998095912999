import styles from './PaymentMethodsReportsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './PaymentMethodsReportsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useState } from 'react'
function PaymentMethodsReportsTable(props:any) {
    const {month,year,searchTerm}= props
    const {t} =useTranslation()
    const [items,setItems]=useState([])
    const [currentPage,setCurrentPage]=useState(1)
    const [pages,setPages]=useState(null)
    function getAllItems(){
        axiosConfig.get(`/provider/all-providers?page=${currentPage}&name=${searchTerm}`,{
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                
            }
        }).then(res=>{
            setItems(res.data?.data?.data)
            setPages(res.data?.data?.pages)
        }).catch(err=>{
            console.log(err)
        })
    }
    useEffect(()=>{
        getAllItems()
    },[month,year,searchTerm])
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Cash Payment')}</th>
                    <th>{t('Apple Pay')}</th>
                    <th>{t('Taby')}</th>
                    <th>{t('Bank Transfer')}</th>
                    <th>{t('Tab')}</th>
                    <th>{t('Check')}</th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>200 {t('AED')}</p>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export default PaymentMethodsReportsTable
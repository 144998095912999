import styles from './AllClientOrdersTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './AllClientOrdersTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
function AllClientOrdersTable(props:any) {
    const {items} = props
    const {t} = useTranslation()
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Order No')}</th>
                    <th>{t('Order Date')}</th>
                    <th>{t('Product Name')}</th>
                    <th>{t("Technician")}</th>
                    <th>{t('Amount')}</th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        items && items?.map((item:any)=>(
                            <tr key={item?.id}>
                                <td>
                                    <Link to={`/orders/order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                        {item?.item_number}
                                    </Link>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.created_at}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.product_name}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.provider_name}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.paid_amount} {t('AED')}</p>
                                </td>
                                <td>
                                    <Link to={`/orders/order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                        {t('More')}
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default AllClientOrdersTable
import styles from './InstallmentsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './InstallmentsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
function InstallmentsTable(props:any) {
    const {items} = props
    const {t} = useTranslation()
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Client Name')}</th>
                    <th>{t('Device Name')}</th>
                    <th>{t('Price')}</th>
                    <th>{t('Paid Status')}</th>
                    {/* <th>{t("Paid Amount")}</th>
                    <th>{t("Remain Amount")}</th> */}
                </thead>
                <tbody>
                    {
                        items && items?.map((item:any,index:number)=>(
                            <tr key={index}>
                                <td>
                                    <Link to={`/our-clients/client-details/${item?.user_id}`} className={styles['appointments__table-link']}>
                                        {item?.user_name}
                                    </Link>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}>{item?.product_name}</p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.price} {t('AED')}</p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}>
                                        {item?.paid_status=='not_paid'?t('Not Paid'):t('Paid')}
                                    </p>
                                </td>

                                {/* <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.paid_price} {t('AED')} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.remain_price} {t('AED')} </p>
                                </td> */}
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default InstallmentsTable
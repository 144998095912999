import styles from './AllNotificationsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './AllNotificationsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
function AllNotificationsTable(props:any) {
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const { items ,deleteItem,isDeleting } = props
    const {t} = useTranslation()
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('#')}</th>
                    <th>{t('Title')}</th>
                    <th>{t('Body')}</th>
                    <th>{t('Created At')}</th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        items && items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <p className={styles['appointments__table-name']}>{index+1}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.title}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.body}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.created_at}</p>
                                </td>
                                <td>
                                    {
                                        user?.permission_type=='admin' &&
                                        <button onClick={()=>{deleteItem(item?.id,index)}} disabled={isDeleting[index]}
                                            className={styles['appointments__table-delete']}>
                                            {
                                                !isDeleting[index]?t('Delete')
                                                :<ReactLoading type={"spin"} color={'red'} height={20} width={20} />
                                            }
                                        </button>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default AllNotificationsTable
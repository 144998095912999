import styles from './ChartJs.module.css'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import Chart from 'chart.js/auto'
import { useRef , useEffect, useState} from 'react';
import * as Utils from 'utils/Utils'
function ChartJs(props:any) {
  const {items} = props
  const canvaRef = useRef<HTMLCanvasElement>(null);
  const DATA_COUNT = 7;
  const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

  // const labels = Utils.months({count: 7});
  // const labels = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
  const [labels,setLabels] = useState([])
  const [values,setValues] = useState([])
  const [rangeMaximun,setRangeMaximun] = useState(2000)
  const [chartItem,setChartItem] = useState()
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Payments',
        // data: [10, 30, 50, 20, 25, 44, 10,30,50,100,20,25,30,54,88,99,12,23,54,86,200,88,94,23,25,68,95,45,25,45],
        data: values,
        borderColor: Utils.CHART_COLORS.blue,
        backgroundColor: Utils.CHART_COLORS.blue,
        borderWidth: 1
      },
      // {
      //   label: 'Female',
      //   data: [100, 33, 22, 19, 11, 49, 30],
      //   borderColor: Utils.CHART_COLORS.blue,
      //   backgroundColor: Utils.CHART_COLORS.blue,
      // }
    ]
  };
  function setup() {
    
    destroyChart(chartItem)
    let chartValue = new Chart(
      (canvaRef.current as HTMLCanvasElement),
      {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: false,
              text: 'Payments'
            }
          },
          scales: {
            y: {
              min: 50,
              max: rangeMaximun,
            }
          }
        },
      }
    );
    setChartItem(chartValue as any)
  }
  function destroyChart(chart:any) {
    if (chart) {
      chart.destroy();
      console.log('Chart destroyed.');
    } else {
      console.log('Chart instance not found.');
    }
  }
  useEffect(()=>{
    if(items?.month_orders){
      let labels =Object.keys(items?.month_orders)?.map(key=>Number(key))
      setLabels(labels as any)
      setValues(Object.values(items?.month_orders))
      let maximunRangeValue = Object.values(items?.month_orders)?.sort((a:any,b:any)=>Number(b)-Number(a))?.[0]
      setRangeMaximun(Number(maximunRangeValue)+500)
    }
  },[items])
  useEffect(()=>{
    if(labels?.length>0){
      setup()
    }
  },[labels,values])
  return (
    // <div className={styles['chartjs__wrapper']}>
      <canvas id="acquisitions" ref={canvaRef} className={styles['chartjs__wrapper']}></canvas>
    // </div>
  )
}

export default ChartJs
import { useTranslation } from 'react-i18next'
import styles from './PersonalInformation.module.css'
import {ReactComponent as ClockIcon} from 'assets/icons/clockIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import MapModel from 'components/Global/Elements/MapModel/MapModel'
import { emailPattern } from 'utils/features'
import { useNavigate, useParams } from 'react-router-dom'
function PersonalInformation() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()

  const [name,setName]=useState('')
  const [phone,setPhone]=useState('')
  const [whatsPhone,setWhatsPhone]=useState('')
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const [subscriptionPrice,setSubscriptionPrice]=useState('')
  const [subscriptionPeriod,setSubscriptionPeriod]=useState('')
  const [latitude,setLatitude]=useState('')
  const [longitude,setLongitude]=useState('')
  const [show,setShow]=useState(false)
  const [item,setItem]=useState<any>()
  const [isLoading,setIsLoading]=useState(false)
  
  function getItem(){
    setIsLoading(true)
    axiosConfig.get(`/user/single-user`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setIsLoading(false)
      setItem(res.data?.data)
      setShow(true)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }
  
  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('User Name Is Required'))
      status=false
    }
    if(!phone){
      toast.error(t('User Phone Is Required'))
      status=false
    }
    if(!emailPattern.test(email)){
      toast.error(t('User Email Is Required'))
      status=false
    }
    if(!params?.id){
      if(!password ){
        toast.error(t('User Password Is Required'))
        status=false
      }
    }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('name',name)
    formData.append('email',email)
    formData.append('phone_number',phone)
    password&&formData.append('password',password)
    // formData.append('whatsapp_number',whatsPhone)
    // formData.append('subscription_price',subscriptionPrice)
    // formData.append('subscription_period',subscriptionPeriod)
    // formData.append('current_latitude',latitude)
    // formData.append('current_longitude',longitude)

    axiosConfig.put(`/user/update-user/${item?.id}`,formData,{
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
  }).then(res=>{
      setIsLoading(false)
      toast.success(t(`user Updated Successfully`))
      navigate('/our-clients')
    }).catch(err=>{
      setIsLoading(false)
      toast.error(t('Something went wrong'))
    })
  }
  useEffect(()=>{
    getItem()
  },[])
  useEffect(()=>{
    if(item){
      setName(item?.name)
      setPhone(item?.phone_number)
      setEmail(item?.email)
      setWhatsPhone(item?.whatsapp_number)
      setSubscriptionPrice(item?.subscription_price)
      setSubscriptionPeriod(item?.subscription_period)
      setLatitude(item?.current_latitude)
      setLongitude(item?.current_longitude)
      // setPassword(item?.password)
    }
  },[item])
  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{t('Personal Information')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Client Name')}`}
                  value={name}
                  onChange={(e:any)=>setName(e.target.value)}
                />
                <input 
                  type='password'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Password')}`}
                  value={password}
                  onChange={(e:any)=>setPassword(e.target.value)}
                />
                <input 
                  type='email'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Email')}`}
                  value={email}
                  onChange={(e:any)=>setEmail(e.target.value)}
                />
                <input 
                  type='text'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Phone Number')}`}
                  value={phone}
                  onChange={(e:any)=>setPhone(e.target.value)}
                />
                  <button type='button' className={styles['form__content-form-input']} onClick={()=>setShow(prevState=>!prevState)}>
                    {t('Client Location')}
                    <GoogleIcon />
                  </button>
                  
              </div>
              
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?
                  <>{t('Save')} </>
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
            <MapModel 
              setLatitude={setLatitude} 
              setLongitude={setLongitude} 
              // isItemLoading={isItemLoading} 
              longitude={longitude} 
              latitude={latitude}
              show={show}
              setShow={setShow}
            />
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default PersonalInformation
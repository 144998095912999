import { useTranslation } from 'react-i18next'
import styles from './PaymentsAndInstallments.module.css'
import {ReactComponent as ClockIcon} from 'assets/icons/clockIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import MapModel from 'components/Global/Elements/MapModel/MapModel'
import GuestInstallmentsTable from 'components/Tables/GuestTables/GuestInstallmentsTable/GuestInstallmentsTable'
import GuetsPaymentMethodsReportsTable from 'components/Tables/GuestTables/GuetsPaymentMethodsReportsTable/GuetsPaymentMethodsReportsTable'
import GuestIncomesReportsTable from 'components/Tables/GuestTables/GuestIncomesReportsTable/GuestIncomesReportsTable'
function PaymentsAndInstallments() {
  const [month,setMonth]=useState(String((new Date()).getMonth()))
  const [year,setYear]=useState(String((new Date()).getFullYear()))
  const {t} =useTranslation()
  const [items,setItems]=useState([])
  const [currentPage,setCurrentPage]=useState(1)
  const [pages,setPages]=useState(null)
  const [installments,setInstallments]=useState([])
  
  function getAllInstallments(){
      axiosConfig.get(`/user/user-installments?month=${month}&year=${year}`,{
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
      }).then(res=>{
        setInstallments(res.data?.data)
          console.log(res.data.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  function getAllItems(){
      axiosConfig.get(`/orders/month-payments?month=${month}&year=${year}`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
      }).then(res=>{
        setItems(res.data?.data)
        // setPages(res.data?.data?.pages)
      }).catch(err=>{
        console.log(err)
      })
  }
  useEffect(()=>{
      getAllItems()
      getAllInstallments()
  },[month,year])
  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{t('Installments')}</h1>
            <div className='d-flex align-items-center gap-2 mb-2'>
                <select className={styles['form__content-form-input']} value={month} onChange={(e)=>setMonth(e.target.value)}>
                    <option value='0'>{t('Jan')}</option>
                    <option value='1'>{t('Feb')}</option>
                    <option value='2'>{t('Mar')}</option>
                    <option value='3'>{t('Apr')}</option>
                    <option value='4'>{t('May')}</option>
                    <option value='5'>{t('Jun')}</option>
                    <option value='6'>{t('Jul')}</option>
                    <option value='7'>{t('Aug')}</option>
                    <option value='8'>{t('Sep')}</option>
                    <option value='9'>{t('Oct')}</option>
                    <option value='10'>{t('Nov')}</option>
                    <option value='11'>{t('Dec')}</option>
                </select>
                <select className={styles['form__content-form-input']} value={year} onChange={(e)=>setYear(e.target.value)}>
                  {
                    [...Array(20)]?.map((year,index)=>(
                      <option value={new Date().getFullYear() -index} key={new Date().getFullYear() -index}>{new Date().getFullYear() -index}</option>
                    ))
                  }
                </select>
              </div>
            <GuestInstallmentsTable items={installments}/>
          </div>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{t('Payments')}</h1>
            
            <GuestIncomesReportsTable items={items}/>
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default PaymentsAndInstallments
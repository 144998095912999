import { useTranslation } from 'react-i18next'
import styles from './Payments.module.css'
import {ReactComponent as PrinterIcon} from 'assets/icons/printerIcon.svg'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import ImmediateCommingsTable from 'components/Tables/PaymentsTables/ImmediateCommingsTable/ImmediateCommingsTable'
import InstallmentsTable from 'components/Tables/PaymentsTables/InstallmentsTable/InstallmentsTable'
import ChartJs from 'components/Global/Elements/ChartJs/ChartJs'
function Payments() {
  const {t} =useTranslation()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const [month,setMonth]=useState(String((new Date()).getMonth()))
  const [year,setYear]=useState(String((new Date()).getFullYear()))

  const [activePage,setActivePage]=useState('warranty')
  const [searchTerm,setSearchTerm]=useState('')
  const [items,setItems]=useState([])
  const [installments,setInstallments]=useState([])
  const [currentPage,setCurrentPage]=useState(1)
  const [pages,setPages]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  function getAllItems(){
      setIsLoading(true)
      axiosConfig.get(`/orders/month-payments?month=${month}&year=${year}`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
          
        }
      }).then(res=>{
        setItems(res.data?.data)
        console.log(res.data.data)
      }).catch(err=>{
        console.log(err)
      })
  }
  function getAllInstallments(){
      setIsLoading(true)
      axiosConfig.get(`/user/user-installments?month=${month}&year=${year}`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
          
        }
      }).then(res=>{
        setInstallments(res.data?.data)
        console.log(res.data.data)
      }).catch(err=>{
        console.log(err)
      })
  }
  useEffect(()=>{
    getAllItems()
    getAllInstallments()
  },[month,year])
  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          <div className={styles['page-header']}>
            <div className={styles['page-header__top-wrapper']}>
              <div className={styles['page-header__top-title-wrapper']}>
                <h1 className={styles['page-header__title']}>
                  {t('Payments')}
                </h1>
                <div className='d-flex align-items-center gap-2'>
                <select className={styles['form__content-form-input']} value={month} onChange={(e)=>setMonth(e.target.value)}>
                    <option value='0'>{t('Jan')}</option>
                    <option value='1'>{t('Feb')}</option>
                    <option value='2'>{t('Mar')}</option>
                    <option value='3'>{t('Apr')}</option>
                    <option value='4'>{t('May')}</option>
                    <option value='5'>{t('Jun')}</option>
                    <option value='6'>{t('Jul')}</option>
                    <option value='7'>{t('Aug')}</option>
                    <option value='8'>{t('Sep')}</option>
                    <option value='9'>{t('Oct')}</option>
                    <option value='10'>{t('Nov')}</option>
                    <option value='11'>{t('Dec')}</option>
                </select>
                <select className={styles['form__content-form-input']} value={year} onChange={(e)=>setYear(e.target.value)}>
                  {
                    [...Array(20)]?.map((year,index)=>(
                      <option value={new Date().getFullYear() -index} key={new Date().getFullYear() -index}>{new Date().getFullYear() -index}</option>
                    ))
                  }
                </select>
                </div>
              </div>
                {/* <Link to='add-order' className={styles['page-header__link']}>
                  {t('Print')}
                  <PrinterIcon/>
                </Link> */}
            </div>
          </div>

          <Row>
            <Col lg='3' className='order-2 order-lg-1'>
              <div>
                <h2 className={styles['page-header__sub-title']}>{t('Immediate Comings')}</h2>
                <ImmediateCommingsTable items={items}/>
              </div>
            </Col>
            <Col lg='9' className='order-1 order-lg-2'>
              <ChartJs items={items}/>
              <div>
                <h2 className={styles['page-header__sub-title']}>{t('Installments')}</h2>
                <InstallmentsTable items={installments}/>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  )
}

export default Payments
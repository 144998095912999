import styles from './OrderUsedTestamentsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import './OrderUsedTestamentsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
function OrderUsedTestamentsTable(props:any) {
    const { items } = props
    const {t}= useTranslation()
    
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('#')}</th>
                    <th>{t('Piece Name')}</th>
                    <th>{t('Used Count')}</th>
                </thead>
                <tbody>
                    {
                        items &&items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {index + 1} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.title} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.used_count} {t('Piece')} </p>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default OrderUsedTestamentsTable
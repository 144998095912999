import { useTranslation } from 'react-i18next'
import styles from './Settings.module.css'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
function Settings() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [item,setItem]=useState({} as any)
  const [status,setStatus]=useState('')
  const [isLoading,setIsLoading]=useState(false)

  function getItem(){
    axiosConfig.get(`/settings`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setItem(res.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }
  
  function validateInputs(){
    let status =true
    if(!status){
      toast.error(t('Project Working Status Is Required'))
      status=false
    }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('is_project_In_factory_mode',status)

    axiosConfig.put(`/settings/update-settings`,formData,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setIsLoading(false)
      toast.success(t(`Settings Updated Successfully`))
    }).catch(err=>{
      toast.error(err?.response?.data?.message)
      setIsLoading(false)
    })
  }
  
  useEffect(()=>{
    getItem()
  },[])
  useEffect(()=>{
    if(item){
      setStatus(item?.is_project_In_factory_mode)
    }
  },[item])
  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{t('Settings')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                <select 
                  className={styles['form__content-form-input']}
                  value={status}
                  onChange={(e)=>setStatus(e.target.value)}
                >
                  <option value=''>{t('Please Select Project App Status')}</option>
                  <option value='1'>{t('Working')}</option>
                  <option value='2'>{t('Not Working')}</option>
                </select>
              </div>
              
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?
                  <>{t('Update')} </>
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default Settings
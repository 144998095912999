import React,{ useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from 'react-toastify';
import ErrorPage from './views/ErrorPage/ErrorPage';
import { useDispatch } from 'react-redux';
import Login from 'views/Login/Login';
import AOS from "aos";
import "aos/dist/aos.css";
import DashboardLayout from 'views/DashboardLayout/DashboardLayout';
import NotAuthed from 'utils/NotAuthed';
import AddUpdateTechnicians from 'views/Technicians/AddUpdateTechnicians/AddUpdateTechnicians';
import Technicians from 'views/Technicians/Technicians/Technicians';
import OurClients from 'views/Clients/OurClients/OurClients';
import Admins from 'views/Admins/Admins/Admins/Admins';
import AddUpdateClients from 'views/Clients/AddUpdateClients/AddUpdateClients';
import Orders from 'views/Orders/Orders/Orders';
import AddUpdateOrders from 'views/Orders/AddUpdateOrders/AddUpdateOrders';
import ClientDetails from 'views/Clients/ClientDetails/ClientDetails';
import AllReciepts from 'views/Clients/AllReciepts/AllReciepts';
import OrderDetails from 'views/Orders/OrderDetails/OrderDetails';
import AddUpdateAdmins from 'views/Admins/Admins/AddUpdateAdmins/AddUpdateAdmins';
import Reports from 'views/Reports/Reports/Reports';
import Payments from 'views/Payments/Payments/Payments';
import Dashboard from 'views/Dashboard/Dashboard/Dashboard';
import GuestLayout from 'views/GuestLayout/GuestLayout';
import GuestOrders from 'views/Guest/Orders/GuestOrders/GuestOrders';
import PersonalInformation from 'views/Guest/PersonalInformation/PersonalInformation';
import PaymentsAndInstallments from 'views/Guest/PaymentsAndInstallments/PaymentsAndInstallments';
import OrderTestaments from 'views/Orders/OrderTestaments/OrderTestaments';
import AddUpdateOrdersTestment from 'views/Orders/AddUpdateOrdersTestment/AddUpdateOrdersTestment';
import TecnicnianTestaments from 'views/Technicians/TecnicnianTestaments/TecnicnianTestaments';
import { useSelector } from 'react-redux';
import AuthedUser from 'utils/AuthedUser';
import AuthedAdmin from 'utils/AuthedAdmin';
import AddUpdateProducts from 'views/Products/AddUpdateProducts/AddUpdateProducts';
import Products from 'views/Products/Products/Products';
import AddUpdateOrdersTestments from 'views/Orders/AddUpdateOrdersTestments/AddUpdateOrdersTestments';
import ClientInstallments from 'views/Clients/ClientInstallments/ClientInstallments/ClientInstallments';
import AddUpdateClientInstallments from 'views/Clients/ClientInstallments/AddUpdateClientInstallments/AddUpdateClientInstallments';
import Settings from 'views/Settings/Settings';
import Notifications from 'views/Notifications/Notifications/Notifications';
import { Notification } from 'Notification';
import AddUpdateTestments from 'views/Testments/AddUpdateTestments/AddUpdateTestments';
import Testments from 'views/Testments/Testments/Testments';
import AddUpdateProvidersTestments from 'views/Technicians/AddUpdateProvidersTestments/AddUpdateProvidersTestments';
import AddUpdateProvidersTestment from 'views/Technicians/AddUpdateProvidersTestment/AddUpdateProvidersTestment';
import TecnicianDetails from 'views/Technicians/TecnicianDetails/TecnicianDetails';
import AllTecnicianReciepts from 'views/Technicians/AllTecnicianReciepts/AllTecnicianReciepts';
import ClientProducts from 'views/Clients/ClientProducts/ClientProducts/ClientProducts';
import AddUpdateClientProducts from 'views/Clients/ClientProducts/AddUpdateClientProducts/AddUpdateClientProducts';
import ClientMonthInstallments from 'views/Clients/ClientInstallments/ClientMonthInstallments/ClientMonthInstallments';
import States from 'views/States/States/States';
import AddUpdateStates from 'views/States/AddUpdateStates/AddUpdateStates';
import ImportClients from 'views/Clients/ImportClients/ImportClients';
import Messages from 'views/Messages/Messages';
import UpdateOrderPayment from 'views/Orders/UpdateOrderPayment/UpdateOrderPayment';
function AnimatedRoutes() {
  const [isSideBarVisible , setIsSideBarVisible] =useState(false)
  const [isRouteExist,setIsRouteExist] =useState(false)
  const [isLoadingVisible,setIsLoadingVisible]=useState(false)
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)

  const dispatch= useDispatch()
  const location = useLocation()
  useEffect(()=>{
    window.scrollTo(0,0)
  },[location])
  useEffect(() => {
    AOS.init({ duration: 400,once: true ,offset: 20});
    AOS.refresh();
  }, []);
  return (
    <>
      {/* {isLoadingVisible&&<Loading/>} */}
      <div className="App">
        <ToastContainer />
        {/* <Notification/> */}
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>

            <Route path='/' element={<AuthedAdmin><DashboardLayout/></AuthedAdmin>}>
              <Route index element={<Dashboard/>}></Route>
              <Route path='/settings' element={<Settings/>}></Route>
              <Route path='/messages' element={<Messages/>}></Route>
              <Route path='/notifications' element={<Notifications/>}></Route>

              <Route path='/our-clients'>
                <Route index element={<OurClients/>}></Route>
                <Route path='client-details/:id' element={<ClientDetails/>}></Route>
                <Route path='client-installments/:id' element={<ClientInstallments/>}></Route>
                <Route path='month-installments/:id' element={<ClientMonthInstallments/>}></Route>
                <Route path='client-products/:id' element={<ClientProducts/>}></Route>
                <Route path='all-reciepts/:id' element={<AllReciepts/>}></Route>
                {
                  user?.permission_type=='admin' &&
                  <>
                    <Route path='add-client-products/:id' element={<AddUpdateClientProducts/>}></Route>
                    <Route path='update-client-products/:id' element={<AddUpdateClientProducts/>}></Route>
                    <Route path='add-client-installments/:id' element={<AddUpdateClientInstallments/>}></Route>
                    <Route path='add-client' element={<AddUpdateClients/>}></Route>
                    <Route path='update-client/:id' element={<AddUpdateClients/>}></Route>
                    <Route path='import-clients' element={<ImportClients/>}></Route>
                  </>
                }
              </Route>

              <Route path='/technicians'>
                <Route index element={<Technicians/>}></Route>
                <Route path='technician-details/:id' element={<TecnicianDetails/>}></Route>
                <Route path='all-documents/:id' element={<AllTecnicianReciepts/>}></Route>
                <Route path='testaments/:id' element={<TecnicnianTestaments/>}></Route>
                {
                  user?.permission_type=='admin' &&
                  <>
                    <Route path='add-technician' element={<AddUpdateTechnicians/>}></Route>
                    <Route path='update-technician/:id' element={<AddUpdateTechnicians/>}></Route>
                    <Route path='add-technician-testment/:id' element={<AddUpdateProvidersTestment/>}></Route>
                    <Route path='add-technician-testments/:id' element={<AddUpdateProvidersTestments/>}></Route>
                  </>
                }
              </Route>

              <Route path='/products'>
                <Route index element={<Products/>}></Route>
                {
                  user?.permission_type=='admin' &&
                  <>
                    <Route path='add-product' element={<AddUpdateProducts/>}></Route>
                    <Route path='update-product/:id' element={<AddUpdateProducts/>}></Route>
                  </>
                }
              </Route>

              <Route path='/testments'>
                <Route index element={<Testments/>}></Route>
                {
                  user?.permission_type=='admin' &&
                  <>
                    <Route path='add-testment' element={<AddUpdateTestments/>}></Route>
                    <Route path='update-testment/:id' element={<AddUpdateTestments/>}></Route>
                  </>
                }
              </Route>

              <Route path='/states'>
                <Route index element={<States/>}></Route>
                {
                  user?.permission_type=='admin' &&
                  <>
                    <Route path='add-state' element={<AddUpdateStates/>}></Route>
                    <Route path='update-state/:id' element={<AddUpdateStates/>}></Route>
                  </>
                }
              </Route>

              {
                user?.permission_type=='admin' &&
                <Route path='/admins'>
                  <Route index element={<Admins/>}></Route>
                  <Route path='add-admin' element={<AddUpdateAdmins/>}></Route>
                  <Route path='update-admin/:id' element={<AddUpdateAdmins/>}></Route>
                </Route>
              }

              <Route path='/orders'>
                <Route index element={<Orders/>}></Route>
                <Route path='order-details/:id' element={<OrderDetails/>}></Route>
                <Route path='order-testments/:id' element={<OrderTestaments/>}></Route>
                {
                  user?.permission_type=='admin' &&
                  <>
                    <Route path='add-order' element={<AddUpdateOrders/>}></Route>
                    <Route path='update-payment/:id' element={<UpdateOrderPayment/>}></Route>
                    <Route path='add-order-testment/:id' element={<AddUpdateOrdersTestment/>}></Route>
                    <Route path='add-order-testments/:id' element={<AddUpdateOrdersTestments/>}></Route>
                    <Route path='update-order/:id' element={<AddUpdateOrders/>}></Route>
                  </>
                }
              </Route>

              <Route path='/reports'>
                <Route index element={<Reports/>}></Route>
              </Route>

              <Route path='/payments'>
                <Route index element={<Payments/>}></Route>
              </Route>

            </Route>

            <Route path='/guest' element={<AuthedUser><GuestLayout/></AuthedUser>}>
              {/* <Route index element={<Dashboard/>}></Route> */}
              
              <Route path='orders'>
                <Route index element={<GuestOrders/>}></Route>
                <Route path='order-details/:id' element={<OrderDetails/>}></Route>
                <Route path='add-order' element={<AddUpdateOrders/>}></Route>
                <Route path='update-order/:id' element={<AddUpdateOrders/>}></Route>
              </Route>
              <Route path='personal-information'>
                <Route index element={<PersonalInformation/>}></Route>
              </Route>
              <Route path='payments-and-installments'>
                <Route index element={<PaymentsAndInstallments/>}></Route>
              </Route>

            </Route>
            <Route path='/login' element={<NotAuthed><Login/></NotAuthed>}></Route>
            <Route path='*' element={<ErrorPage/>}></Route>
          </Routes>
        </AnimatePresence>
      </div>
    </>
  )
}

export default AnimatedRoutes
import styles from './TechniciansTable.module.css'
import { Container, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './TechniciansTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import adminImg from 'assets/imgs/adminImg.png'
function TechniciansTable(props:any) {
    const {items} = props
    const {t} = useTranslation()
    return (
        <>
            <div className={styles['all-admins-wrapper']}>
                {
                    items && items?.map((item:any,index:number)=>(
                        <div className={styles['all-admins__item-wrapper']} key={item?.id}>
                            <Link to={`/technicians/update-technician/${item?.id}`} className={styles['all-admins__item']}>
                                {/* <img src={adminImg} className={styles['all-admins__item-img']}/> */}
                                
                                <div className={styles['all-admins__item-img-div']}>
                                    {item?.name?.[0]}
                                </div>
                                <div className={styles['all-admins__item-info-wrapper']}>
                                    <p className={styles['all-admins__item-info-name']}> {item?.name} </p>
                                    <p className={styles['all-admins__item-info-role']}> 
                                    <span className={styles['all-admins__item-info-role--bold']}>{item?.finished_orders_count}</span>
                                    {t('Finished Tasks')}
                                    </p>
                                </div>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default TechniciansTable
import styles from './NewOrdersReportsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './NewOrdersReportsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import { useEffect, useState } from 'react'
import { axiosConfig } from 'utils/axiosConfig'
function NewOrdersReportsTable(props:any) {
    const {month,year,searchTerm} = props
    const {t} = useTranslation()
    const [items,setItems]=useState([])
    const [currentPage,setCurrentPage]=useState(1)
    const [pages,setPages]=useState(null)
    
    function getAllItems(){
        axiosConfig.get(`/orders/all-users-orders-without-pagination?page=${currentPage}&order_type=new&month=${month}&year=${year}&name=${searchTerm}`,{
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((res:any)=>{
            setItems(res.data?.data)
            // setPages(res.data?.data?.pages)
        }).catch((err:any)=>{
            console.log(err)
        })
    }
    useEffect(()=>{
        getAllItems()
    },[month,year,searchTerm])
    return (
        <>
        <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
            <thead>
                {/* <th>{t('Order No')}</th> */}
                <th>{t('Client Name')}</th>
                <th>{t('Technician Name')}</th>
                <th>{t('Order Price')}</th>
                <th>{t('Order Status')}</th>
                <th>{t("Phone Number")}</th>
                {/* <th>{t('Whatsapp Number')}</th> */}
                {/* <th>{t('Address')}</th> */}
                {/* <th>{t('Testaments')}</th> */}
                {/* <th></th> */}
            </thead>
            <tbody>
                {
                    items?.map((item:any,index:number)=>(
                        <tr key={item?.id}>
                            {/* <td>
                                <Link to={`/orders/order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                    {item?.item_number}
                                </Link>
                            </td> */}
                            <td>
                                <Link to={`/our-clients/client-details/${item?.user?.id}`} className={styles['appointments__table-link']}>
                                    {item?.user?.name}
                                </Link>
                            </td>
                            <td>
                                {item?.provider?.name}
                            </td>
                            <td>
                                <p className={styles['appointments__table-name']}>{item?.order_required_price} {t('AED')}</p>
                            </td>
                            <td>
                                <p className={styles['appointments__table-name']}>
                                    {
                                        item?.status ==1 ?t('New'):
                                        item?.status ==2 ?t('Completed'):
                                        item?.status ==3 ?t('Cancelled'):
                                        item?.status ==4 ?t('Reasigned'):
                                        ''
                                    }
                                </p>
                            </td>
                            <td>
                                <p className={styles['appointments__table-name']}>{item?.user?.phone_number}</p>
                            </td>
                            {/* <td>
                                <p className={styles['appointments__table-name']}>{item?.user?.whatsapp_number}</p>
                            </td> */}
                            {/* <td>
                                <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${item?.user_latitude},${item?.user_longitude}`} className={styles['form__content-form-input']}>
                                    <GoogleIcon className={styles['form__content-form-input-icon']}/>
                                </a>
                            </td> */}
                            {/* <td>
                                <Link to={`/orders/order-testments/${item?.id}`} className={styles['appointments__table-link']}>
                                    {t('Testaments')}
                                </Link>
                            </td> */}
                            {/* <td>
                                <Link to={`/orders/order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                    {t('Order Details')}
                                </Link>
                            </td> */}
                        </tr>
                    ))
                }
            </tbody>
        </Table>
        </>
    )
}

export default NewOrdersReportsTable
import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Outlet, useLocation } from 'react-router-dom'
import styles from './DashboardLayout.module.css'
import {ReactComponent as MenuIcon} from 'assets/icons/menuIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { Container } from 'react-bootstrap'
// import { useOutletContext } from 'react-router-dom'
import modernWaterLogo from 'assets/imgs/modernWaterLogo.png'
import SideBar from 'components/Global/Layout/SideBar/SideBar'
import { changeNotficationCountAction } from 'reduxStore/Global/GlobalActions'
import { useDispatch } from 'react-redux'
function DashboardLayout() {
  const {t} =useTranslation()
  // const [patient,getPatient]:any = useOutletContext();
  const dispatch = useDispatch()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [admin,setAdmin]=useState<any>([])
  const [statistics,setStatistics]=useState<any>([])
  const [isNavbarOpen ,setIsNavbarOpen] =useState(false)
  const [isSideBarVisible , setIsSideBarVisible] =useState(false)

  // const params = useParams()
  let toggleSideNavBar =(type:any)=>{
    setIsSideBarVisible(type==='open')
  }
  const location = useLocation()
  useEffect(()=>{
    setIsNavbarOpen(false)
  },[location])

  // function getStatictics(){
  //   axiosConfig.get(`/admin/statistics`,{
  //     headers: {
  //       "Authorization": `Bearer ${localStorage.getItem('token')}`,
  //       
  //     }
  //   }).then(res=>{
  //     setStatistics(res.data?.data)
  //   }).catch(err=>{
  //       console.log(err)
  //   })
  // }
  // useEffect(()=>{
  //   getStatictics()
  // },[])
  useEffect(()=>{
    toggleSideNavBar('close')
  },[location])

  function getAllNotifications(){
    axiosConfig.get(`/admin-notifications/all-notifications-without-pagination`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      dispatch(changeNotficationCountAction(res.data.data?.new_number))

    }).catch(err=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    getAllNotifications()
  },[])
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      >
        <Container>
          <NavBar isNavbarOpen={isNavbarOpen} />
          <SideBar 
            isSideBarVisible={isSideBarVisible} 
            toggleSideNavBar={()=>{toggleSideNavBar('close')}}
          />
          <div className={styles['navbar__wrapper--responsive']}>
            <button className={styles['navbar__responsive-button']} onClick={()=>toggleSideNavBar('open')}>
              <MenuIcon className={styles['navbar__responsive-button']}/>
            </button>
            <img src={modernWaterLogo} className={styles['navbar__responsive-logo']} alt='login logo'/>
          </div>
        </Container>

        <div className={styles['sections__wrapper']}>
          <div className={styles['content__wrapper']}>
            <Outlet context={statistics}/>
          </div>
        </div>
    </motion.div>
    </>
  )
}

export default DashboardLayout
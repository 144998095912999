import styles from './OurClientsTable.module.css'
import { Container, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './OurClientsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import adminImg from 'assets/imgs/adminImg.png'
function OurClientsTable(props:any) {
    const {items} = props
    const {t} = useTranslation()
    return (
        <>
            <div className={styles['all-admins-wrapper']}>
                {
                    items&&items?.map((item:any,index:number)=>(
                        <div className={styles['all-admins__item-wrapper']} key={item?.id}>
                            <Link to={`/our-clients/client-details/${item?.id}`} className={styles['all-admins__item']}>
                                {/* <img src={adminImg} className={styles['all-admins__item-img']}/> */}
                                <div className={styles['all-admins__item-img-div']}>
                                    {item?.name?.[0]}
                                </div>
                                <div className={styles['all-admins__item-info-wrapper']}>
                                    <p className={styles['all-admins__item-info-name']}> {item?.name} </p>
                                    <p className={styles['all-admins__item-info-role']}> {item?.phone_number} </p>
                                </div>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default OurClientsTable
import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './PaginationElement.module.css'
import { useTranslation } from 'react-i18next';

const PaginationElement = (props:any) => {
  const { pageCount, onPageChange } = props
  const {t} = useTranslation()
  return (
    <ReactPaginate
      previousLabel={t('Previous')}
      nextLabel={t('Next')}
      breakLabel={'...'}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName={styles['pagination__wrapper']}
      pageClassName={styles['pagination__element']}
      activeClassName={styles['pagination__element--active']}
    />
  );
};

export default PaginationElement;

import { useTranslation } from 'react-i18next'
import styles from './Reports.module.css'
import {ReactComponent as PrinterIcon} from 'assets/icons/printerIcon.svg'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BASE_URL, axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import WarrantyReportsTable from 'components/Tables/ReportsTables/WarrantyReportsTable/WarrantyReportsTable'
import PaymentMethodsReportsTable from 'components/Tables/ReportsTables/PaymentMethodsReportsTable/PaymentMethodsReportsTable'
import ClientsReportsTable from 'components/Tables/ReportsTables/ClientsReportsTable/ClientsReportsTable'
import LattencyReportsTable from 'components/Tables/ReportsTables/LattencyReportsTable/LattencyReportsTable'
import IncomesReportsTable from 'components/Tables/ReportsTables/IncomesReportsTable/IncomesReportsTable'
import TechnicinasReportsTable from 'components/Tables/ReportsTables/TechnicinasReportsTable/TechnicinasReportsTable'
import MaintenanceOrdersReportsTable from 'components/Tables/ReportsTables/MaintenanceOrdersReportsTable/MaintenanceOrdersReportsTable'
import NewOrdersReportsTable from 'components/Tables/ReportsTables/NewOrdersReportsTable/NewOrdersReportsTable'
import ReactLoading from "react-loading";
import { useCookies } from 'react-cookie'
function Reports() {
  const {t} =useTranslation()
  const [searchTerm,setSearchTerm]=useState('')
  const [activePage,setActivePage]=useState('warranty')
  const [month,setMonth]=useState(String((new Date()).getMonth()))
  const [year,setYear]=useState(String((new Date()).getFullYear()))
  
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  function printData(){
    if(activePage=='warranty'){
      // window.location.replace((`${BASE_URL}/orders/export-order-excel?month=${month}&year=${year}`))
      window.location.replace((`${BASE_URL}/user/export-user-warranty-status-pdf?month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
    }
    if(activePage=='payment'){
      window.location.replace((`${BASE_URL}/orders/export-order-excel?month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
    }
    if(activePage=='technicians'){
      // window.location.replace((`${BASE_URL}/provider/export-provider-excel?month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
      window.location.replace((`${BASE_URL}/provider/export-provider-pdf?month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
    }
    if(activePage=='lattency'){
      // window.location.replace((`${BASE_URL}/user/export-user-installments-excel?month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
      window.location.replace((`${BASE_URL}/user/export-user-installments-pdf?month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
    }
    if(activePage=='incomes'){
      // window.location.replace((`${BASE_URL}/orders/export-order-incomes-excel?month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
      window.location.replace((`${BASE_URL}/orders/export-order-incomes-pdf?month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
    }
    if(activePage=='maintenance'){
      // window.location.replace((`${BASE_URL}/orders/export-order-excel?order_type=maintenance&month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
      window.location.replace((`${BASE_URL}/orders/export-order-pdf?order_type=maintenance&month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
    }
    if(activePage=='new_orders'){
      // window.location.replace((`${BASE_URL}/orders/export-order-excel?order_type=new&month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
      window.location.replace((`${BASE_URL}/orders/export-order-pdf?order_type=new&month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
    }
    if(activePage=='clients'){
      // window.location.replace((`${BASE_URL}/user/export-user-excel?month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
      window.location.replace((`${BASE_URL}/user/export-users-pdf?month=${month}&year=${year}&lang=${cookies?.i18next=='en'?'en':'ar'}`))
    }
  }
  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          <div className={styles['page-header']}>
            <div className={styles['page-header__top-wrapper']}>
              <h1 className={styles['page-header__title']}>
                {t('Reports')}
                {/* <span className={styles['page-header__title-count']}>100</span> */}
              </h1>
              <div className={styles['page-header__search-input-wrapper']}>
                <input 
                  type='text'
                  className={styles['page-header__search-input']}
                  placeholder={`${t("Enter the Name")}`}
                  value={searchTerm}
                  onChange={(e)=>setSearchTerm(e.target.value)}
                />
                {/* <button 
                  type='button' 
                  className={styles['page-header__search-submit']}
                  onClick={getAllItems} 
                  disabled={isLoading}>
                  {
                    !isLoading?
                    <>{t('Search')} </>
                    :<ReactLoading type={"spin"} color={'#5885C3'} height={30} width={30} />
                  }
                </button>  */}
              </div>
            </div>

            <div className={styles['page-header__pages-link-wrapper']}>
                <button type='button' onClick={()=>setActivePage('warranty')} 
                className={`${styles['page-header__pages-link']} ${activePage=='warranty'&&styles['page-header__pages-link--active']}`}>
                  {t('Warranty Reports')}
                </button>
                {/* <button type='button' onClick={()=>setActivePage('payment')}
                className={`${styles['page-header__pages-link']} ${activePage=='payment'&&styles['page-header__pages-link--active']}`}>
                  {t('Payment Methods Reports')}
                  </button> */}
                <button type='button' onClick={()=>setActivePage('technicians')}
                className={`${styles['page-header__pages-link']} ${activePage=='technicians'&&styles['page-header__pages-link--active']}`}>
                  {t('Technicians')}
                  </button>
                <button type='button' onClick={()=>setActivePage('lattency')}
                className={`${styles['page-header__pages-link']} ${activePage=='lattency'&&styles['page-header__pages-link--active']}`}>
                  {t('Installments')}
                  </button>
                <button type='button' onClick={()=>setActivePage('incomes')}
                className={`${styles['page-header__pages-link']} ${activePage=='incomes'&&styles['page-header__pages-link--active']}`}>
                  {t('Incomes')}
                  </button>
                <button type='button' onClick={()=>setActivePage('maintenance')}
                className={`${styles['page-header__pages-link']} ${activePage=='maintenance'&&styles['page-header__pages-link--active']}`}>
                  {t('Maintenance Orders')}
                  </button>
                <button type='button' onClick={()=>setActivePage('new_orders')}
                className={`${styles['page-header__pages-link']} ${activePage=='new_orders'&&styles['page-header__pages-link--active']}`}>
                  {t('New Orders')}
                  </button>
                <button type='button' onClick={()=>setActivePage('clients')}
                className={`${styles['page-header__pages-link']} ${activePage=='clients'&&styles['page-header__pages-link--active']}`}>
                  {t('Clients')}
                  </button>
            </div>
            <div className='d-flex justify-content-between'>
              <button onClick={printData} className={styles['page-header__link']}>
                {t('Print')}
                <PrinterIcon/>
              </button>
              <div className='d-flex align-items-center gap-2'>
                <select className={styles['form__content-form-input']} value={month} onChange={(e)=>setMonth(e.target.value)}>
                    <option value='0'>{t('Jan')}</option>
                    <option value='1'>{t('Feb')}</option>
                    <option value='2'>{t('Mar')}</option>
                    <option value='3'>{t('Apr')}</option>
                    <option value='4'>{t('May')}</option>
                    <option value='5'>{t('Jun')}</option>
                    <option value='6'>{t('Jul')}</option>
                    <option value='7'>{t('Aug')}</option>
                    <option value='8'>{t('Sep')}</option>
                    <option value='9'>{t('Oct')}</option>
                    <option value='10'>{t('Nov')}</option>
                    <option value='11'>{t('Dec')}</option>
                </select>
                <select className={styles['form__content-form-input']} value={year} onChange={(e)=>setYear(e.target.value)}>
                  {[...Array(41)].map((_, index) => {
                    const yearValue = new Date().getFullYear() - 20 + index;
                    return (
                      <option key={yearValue} value={yearValue}>
                        {yearValue}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </Container>
        {
          activePage=='warranty' ? <WarrantyReportsTable month={month} year={year} searchTerm={searchTerm}/>:
          activePage=='payment' ?<PaymentMethodsReportsTable month={month} year={year} searchTerm={searchTerm}/>:
          activePage=='technicians' ?<TechnicinasReportsTable month={month} year={year} searchTerm={searchTerm}/>:
          activePage=='lattency' ?<LattencyReportsTable month={month} year={year} searchTerm={searchTerm}/>:
          activePage=='incomes' ?<IncomesReportsTable month={month} year={year} searchTerm={searchTerm}/>:
          activePage=='maintenance' ?<MaintenanceOrdersReportsTable month={month} year={year} searchTerm={searchTerm}/>:
          activePage=='new_orders' ?<NewOrdersReportsTable month={month} year={year} searchTerm={searchTerm}/>:
          activePage=='clients' ?<ClientsReportsTable month={month} year={year} searchTerm={searchTerm}/>:''
        }
        
      </section>
    </>
  )
}

export default Reports
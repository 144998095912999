import { useTranslation } from 'react-i18next'
import styles from './Technicians.module.css'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as PatientsMenuIcon} from 'assets/icons/patientsMenuIcon.svg'
import userImg from 'assets/imgs/userImg.png'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { ItemsListPagination } from 'components/Global/Elements/ItemsListPagination/ItemsListPagination'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import TableElement from 'components/Global/Elements/TableElement/TableElement'
import AllTechnicinasTable from 'components/Tables/AllTechnicinasTable/AllTechnicinasTable'
import PaginationElement from 'components/Global/Elements/PaginationElement/PaginationElement'
function Technicians() {
  const {t} =useTranslation()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const [searchTerm,setSearchTerm]=useState('')
  const [itemsCount,setItemsCount]=useState('0')
  const [items,setItems]=useState([])
  const [currentPage,setCurrentPage]=useState(1)
  const [pages,setPages]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])

  function getAllItems(){
    setIsLoading(true)
    axiosConfig.get(`/provider/all-providers?page=${currentPage}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setIsLoading(false)
      setItems(res.data?.data?.data)
      setPages(res.data?.data?.pages)
      let deletedNumber:any = [...Array(res.data.data.count)]
      setItemsCount(res.data.data.count)
      setIsDeleting(deletedNumber)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }

  function deleteItem(deltedId:String ,index:any){
    let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
      axiosConfig.delete(`/provider/delete-provider/${deltedId}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
            let modifiedDelete:[Boolean]=[...isDeleting]
            modifiedDelete[index]=false
            setIsDeleting(modifiedDelete)
            toast.success(t('Item Deleted Successfully'))
            getAllItems()
      }).catch(err=>{
          let modifiedDelete:[Boolean]=[...isDeleting]
          modifiedDelete[index]=false
          setIsDeleting(modifiedDelete)
          toast.error(err?.response?.data?.message)
          console.log(err)
      })
  }

  useEffect(()=>{
    getAllItems()
  },[currentPage,searchTerm])

  const handlePageChange = (page:any) => {
    setItems([])
    setCurrentPage(page?.selected +1);
  };

  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          <div className={styles['page-header']}>
            <h1 className={styles['page-header__title']}>
              {t('Technicians')}
              <span className={styles['page-header__title-count']}>{itemsCount}</span>
            </h1>
            
            {
              user?.permission_type=='admin' &&
                <Link to='add-technician' className={styles['page-header__link']}>
                  {t('Add New Technician')}
                  <PlusIcon/>
                </Link>
            }
          </div>
        </Container>
        <AllTechnicinasTable items={items} deleteItem={deleteItem} isDeleting={isDeleting}/>

        <PaginationElement
          pageCount={Math.ceil(Number(itemsCount) / 10)}
          onPageChange={handlePageChange}
        />
      </section>
    </>
  )
}

export default Technicians
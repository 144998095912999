import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import SideBar from 'components/Global/Layout/SideBar/SideBar'
import styles from './GuestLayout.module.css'
import {ReactComponent as MenuIcon} from 'assets/icons/menuIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import GuestNavBar from 'components/Global/Layout/GuestNavBar/GuestNavBar'
function GuestLayout() {
  const {t} =useTranslation()
  const navigate = useNavigate()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isLoading,setIsLoading]=useState(true)
  const [patient,setPatient]=useState<any>([])
  const [isNavbarOpen ,setIsNavbarOpen] =useState(false)
  const location = useLocation()
  const params = useParams()
  useEffect(()=>{
    setIsNavbarOpen(false)
  },[location])
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      >
        <div className={styles['sections__wrapper']}>
          <div className={styles['navbar__wrapper']}>
            <GuestNavBar isNavbarOpen={isNavbarOpen} setIsNavbarOpen={setIsNavbarOpen} patient={patient}/>
          </div>
          <div className={styles['content__wrapper']}>
            <button className={styles['navbar__responsive-button']} onClick={()=>setIsNavbarOpen(true)}>
              <MenuIcon className={styles['navbar__responsive-button']}/>
            </button>
            <Outlet context={[patient]} />
          </div>
          {/* <div className={styles['sidebar__wrapper']}>
            <SideBar patient={patient}/>
          </div> */}
        </div>
    </motion.div>
    </>
  )
}

export default GuestLayout
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import imgSrc from 'assets/imgs/404.png'
import styles from './ErrorPage.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
function ErrorPage() {
  const {t} =useTranslation()
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
        initial={{width:0}}
        animate={{width:"100%"}}
        exit={{x:window.innerWidth ,transition:{duration:.3}}}
        > 
        {/* <MetaTags>
            <title>Pavilion</title>
        </MetaTags> */}
        <section className={styles['error']}  >
            <Container>
              <Row>
                <Col lg='6' className='order-2 order-lg-1'>
                  <div className={styles['error__content-wrapper']}>
                    <h1 className={styles['error__content-title']}>404</h1>
                    <h2 className={styles['error__content-second-title']}>{t('Oops')}</h2>
                    <p className={styles['error__content-description']}>{t("We Can't Find This Page You Are Looking For")}</p>
                    <Link to='/' className={styles['error__content-link']}>{t('Home Page')}</Link>
                  </div>
                </Col>
                <Col lg='6' className='order-1 order-lg-2'>
                  <img src={imgSrc} className={styles['error__img']}/>
                </Col>
              </Row>
            </Container>
        </section>
        <Footer/>
        
    </motion.div>
    </>
  )
}

export default ErrorPage
import { useTranslation } from 'react-i18next'
import styles from './TecnicnianTestaments.module.css'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BASE_URL, axiosConfig } from 'utils/axiosConfig'
import { Container } from 'react-bootstrap'
import AllOrderTestamentsTable from 'components/Tables/AlOrderTestamentsTable/AllOrderTestamentsTable'
import AlTestamentsTable from 'components/Tables/AlTestamentsTable/AlTestamentsTable'
import AllTecnicnianTestamentsTable from 'components/Tables/AllTecnicnianTestamentsTable/AllTecnicnianTestamentsTable'
import {ReactComponent as PrinterIcon} from 'assets/icons/printerIcon.svg'
import { useCookies } from 'react-cookie'
function TecnicnianTestaments() {
  const {t} =useTranslation()
  const params = useParams()
  const [item,setItem]=useState({} as any)
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  const [cookies, setCookie, removeCookie] = useCookies(['i18next']);
  function getProviderTestments(){
    setIsLoading(true)
    axiosConfig.get(`/provider/single-provider/${params?.id}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setIsLoading(false)
      setItem(res?.data?.data)
      let deletedNumber:any = [...Array(res?.data?.data?.testments?.length)]
      setIsDeleting(deletedNumber)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }

  function receiveTestment(orderId:String ,index:any,testmentId:String,receivedStatus:String,returnCount:any,item:any={}){
    let modifiedDelete:[Boolean]=[...isDeleting]
    modifiedDelete[index]=true
    if (item?.received_count) {
      if(Number(item?.used_count) + Number(item?.return_count) + Number(returnCount) >= Number(item?.received_count)){
        receivedStatus = 'received'
      } else{
        receivedStatus = 'not_received'
      }
    }

    setIsDeleting(modifiedDelete)
      axiosConfig.post(`/provider/received-testment-from-provider/${orderId}`,{testment_id:testmentId,received_status:receivedStatus,return_count:returnCount},{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res=>{
        let modifiedDelete:[Boolean]=[...isDeleting]
        modifiedDelete[index]=false
        setIsDeleting(modifiedDelete)
        toast.success(t('Item Changed Successfully'))
        getProviderTestments()
      }).catch(err=>{
        let modifiedDelete:[Boolean]=[...isDeleting]
        modifiedDelete[index]=false
        setIsDeleting(modifiedDelete)
        toast.error(err?.response?.data?.message)
        console.log(err)
      })
  }  
  
  function deleteItem(orderId:String ,index:any,testmentId:String){
    let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
      axiosConfig.delete(`/provider/delete-testment-from-provider/${orderId}?testment_id=${testmentId}`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res=>{
        let modifiedDelete:[Boolean]=[...isDeleting]
        modifiedDelete[index]=false
        setIsDeleting(modifiedDelete)
        toast.success(t('Item Deleted Successfully'))
        getProviderTestments()
      }).catch(err=>{
        let modifiedDelete:[Boolean]=[...isDeleting]
        modifiedDelete[index]=false
        setIsDeleting(modifiedDelete)
        toast.error(err?.response?.data?.message)
        console.log(err)
      })
  }
  useEffect(()=>{
    getProviderTestments()
  },[])
  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          <div className={styles['page-header']}>
            <h1 className={styles['page-header__title']}>
              {t('Tecnicnian Testaments')} ({item?.name})
            </h1>
            {/* <Link to='/dashboard/add-technician' className={styles['page-header__link']}>
              {t('Export PDF')}
            </Link> */}
            <div className={styles['page-header__wrapper']}>
              <Link to={`${BASE_URL}/provider/export-provider-testments-pdf/${params?.id}?lang=${cookies?.i18next=='en'?'en':'ar'}`} className={styles['page-header__link']}>
                {t('Print')}
                <PrinterIcon/>
              </Link>
              <Link to={`/technicians/add-technician-testments/${params?.id}`} className={styles['page-header__link']}>
                {t('Add Testment')}
              </Link>
            </div>
          </div>
        </Container>
        <AllTecnicnianTestamentsTable 
          provider={item}
          items={item?.testments} 
          deleteItem={deleteItem} 
          receiveTestment={receiveTestment} 
          isDeleting={isDeleting} 
        />
      </section>
    </>
  )
}

export default TecnicnianTestaments
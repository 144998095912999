import { useTranslation } from 'react-i18next'
import styles from './AddUpdateClientInstallments.module.css'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
function AddUpdateClientInstallments() {
  
  const TESTMENT_MODEL = (date:any)=>{
    return{
        id:uuidv4(),
        price:'',
        date:date||'',
    }
  }
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [user,setUser] =useState({} as any)
  const [price,setPrice] =useState('')
  const [InstallmentCount,setInstallmentCount] =useState('1')
  const [product,setProduct] =useState('')
  const [isLoading,setIsLoading] =useState(false)
  const [products,setProducts]=useState([])
  
  const [installments,setInstallments] =useState<any>([TESTMENT_MODEL(generateMonthDate(0))])
  function getUserDetails(){
    // setIsLoading(true)
    axiosConfig.get(`/user/single-user-by-id/${params?.id}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setUser(res.data?.data)
    }).catch(err=>{
      // setIsLoading(false)
      console.log(err)
    })
}
  function validateInputs(){
    let status =true
    if(!product){
      toast.error(t('Installment Product Is Required'))
      status=false
    }
    // if(!price){
    //   toast.error(t('Installment Price Is Required'))
    //   status=false
    // }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('product_id',product)
    installments?.forEach((installment:any,index:number)=>{
      formData.append(`installment_months[${index}][price]`,installment?.price)
      formData.append(`installment_months[${index}][date]`,installment?.date)
    })
    
    axiosConfig.post(`/user/add-installments-to-user/${params?.id}`,formData,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setIsLoading(false)
      toast.success(t(`User Installment Added Successfully`))
      navigate(`/our-clients/client-installments/${params?.id}`)
    }).catch(err=>{
      setIsLoading(false)
      toast.error(t('Something went wrong'))
    })
  }

  function getAllProducts(){
    axiosConfig.get(`/product/all-products-without-pagination`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setProducts(res.data?.data)
    }).catch(err=>{
      console.log(err)
    })
  }

  
  function addInstallment(){
    let installmentsData = [...installments]
    installmentsData.push(TESTMENT_MODEL(generateMonthDate(installments?.length)))
    setInstallments(installmentsData)
  }
  function removeInstallment(id:any){
      let installmentsData = [...installments]
      installmentsData =installmentsData.filter(installment=>installment?.id !=id)
      setInstallments(installmentsData)
  }
  function handleChangeInstallmentData(key:any,value:any,index:any){
      let installmentsData = [...installments]
      installmentsData[index][key] = value
      setInstallments(installmentsData)
  }
  function generateMonthDate(installmentsCount:any){
    let startDate = new Date();
    if (startDate) {
      let initialDate = new Date(startDate);
      let monthsToAdd = installmentsCount;
      let newDate = new Date(initialDate);
      newDate.setMonth(initialDate.getMonth() + monthsToAdd);
      if (initialDate.getDate() !== newDate.getDate()) {
        newDate.setDate(0); 
      }
      let timestamp = newDate.getTime();
      return moment(new Date(timestamp)).format('yyyy-MM-DD') 
    }
  }

  useEffect(()=>{
    // getAllProducts()
    getUserDetails()
  },[])
  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{t('Add Installments')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                <select 
                    className={styles['form__content-form-input']}
                    value={product}
                    onChange={(e)=>setProduct(e.target.value)}
                  >
                    <option value=''>{t('Please Select Product')}</option>
                    {
                      user?.products?.map((product:any)=>(
                        <option value={product?.product_id} key={product?.product_id}>{product?.product_name}</option>
                      ))
                    }
                  </select>
                {/* <input 
                  type='number'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Price')}`}
                  value={price}
                  onChange={(e)=>setPrice(e.target.value)}
                /> */}
                <div className='w-100 mb-4 d-flex'>
                    <button type='button' onClick={addInstallment} className='btn btn-success ms-auto'>{t('Add Installment')}</button>
                </div>
              </div>
              {
                installments&&installments?.map((testment:any,index:any)=>(
                  <>
                    <hr className='my-4'/>
                    <div className={styles['form__content-form-inputs-wrapper']} key={testment?.id}>
                      <input 
                        type='date'
                        className={styles['form__content-form-input']}
                        placeholder={`${t('Date')}`}
                        name={'date'}
                        value={testment?.date}
                        onChange={(e)=>handleChangeInstallmentData(e.target.name,e.target.value,index)}
                      />
                      <input 
                        type='text'
                        className={styles['form__content-form-input']}
                        placeholder={`${t('Price')}`}
                        name={'price'}
                        value={testment?.price}
                        onChange={(e)=>handleChangeInstallmentData(e.target.name,e.target.value,index)}
                      />
                        
                        <button type='button' onClick={()=>{removeInstallment(testment?.id)}}
                        className='btn btn-danger ms-auto '>{t('Remove Installment')}</button>
                    </div>
                  </>
                ))
            }
              
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?t('Add Installments')
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default AddUpdateClientInstallments
import { useTranslation } from 'react-i18next'
import styles from './Notifications.module.css'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import AllClientsTable from 'components/Tables/AllClientsTable/AllClientsTable'
import ReactLoading from "react-loading";
import AllNotificationsTable from 'components/Tables/AllNotificationsTable/AllNotificationsTable'

function Notifications() {
  const {t} =useTranslation()

  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const [itemsCount,setItemsCount]=useState('0')
  const [searchTerm,setSearchTerm]=useState('')
  const [items,setItems]=useState({} as any)
  const [currentPage,setCurrentPage]=useState(1)
  const [pages,setPages]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  function getAllItems(){
    setIsLoading(true)
    axiosConfig.get(`/admin-notifications/all-notifications-without-pagination?page=${currentPage}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        
      }
    }).then(res=>{
      setIsLoading(false)
      setItems(res.data?.data)
      // setPages(res.data?.data?.pages)
      let deletedNumber:any = [...Array(res?.data?.data?.notifications?.length)]
      setItemsCount(res?.data?.data?.notifications?.length)
      setIsDeleting(deletedNumber)
    }).catch(err=>{
      setIsLoading(false)
      console.log(err)
    })
  }
  function deleteItem(deltedId:String ,index:any){
    let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
    axiosConfig.delete(`/admin-notifications/delete-notification/${deltedId}`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=false
      setIsDeleting(modifiedDelete)
      toast.success(t('Item Deleted Successfully'))
      getAllItems()
    }).catch(err=>{
      let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=false
      setIsDeleting(modifiedDelete)
      toast.error(err?.response?.data?.message)
      console.log(err)
    })
  }
  function viewNotifications(){
    axiosConfig.post(`/admin-notifications/set-viewed`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
    }).catch(err=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    getAllItems()
  },[currentPage])
  useEffect(()=>{
    viewNotifications()
  },[])
  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          <div className={styles['page-header']}>
            <div className={styles['page-header__top-wrapper']}>
              <h1 className={styles['page-header__title']}>
              {t('Notifications')}
              <span className={styles['page-header__title-count']}>{itemsCount}</span>
              </h1>
              
            </div>

          </div>
        </Container>
        <AllNotificationsTable items={items?.notifications} deleteItem={deleteItem} isDeleting={isDeleting}/>
        
      </section>
    </>
  )
}

export default Notifications
import { useTranslation } from 'react-i18next'
import styles from './TecnicianDetails.module.css'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import AllClientsTable from 'components/Tables/AllClientsTable/AllClientsTable'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import {ReactComponent as WhatsappIcon} from 'assets/icons/whatsappIcon.svg'
import AllOrdersTable from 'components/Tables/AllOrdersTable/AllOrdersTable'
import AllInstallmentsTable from 'components/Tables/AllInstallmentsTable/AllInstallmentsTable'
import AllClientOrdersTable from 'components/Tables/AllClientOrdersTable/AllClientOrdersTable'
import receiptsImg from 'assets/imgs/receiptsImg.png'
import { whtsAppConnect } from 'utils/features'
import AllTecnicnianTestamentsTable from 'components/Tables/AllTecnicnianTestamentsTable/AllTecnicnianTestamentsTable'
function TecnicianDetails() {
  const {t} =useTranslation()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const params = useParams()
  const [item,setItem]=useState({} as any)
  const [orders,setOrders]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  function getUserDetails(){
      setIsLoading(true)
      axiosConfig.get(`/provider/single-provider/${params?.id}`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
      }).then(res=>{
        setItem(res.data?.data)
      }).catch(err=>{
        setIsLoading(false)
        console.log(err)
      })
  }
  function getUserOrders(){
      setIsLoading(true)
      axiosConfig.get(`/orders/provider-orders/${params?.id}`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
          
        }
      }).then(res=>{
        setOrders(res.data?.data)
      }).catch(err=>{
        setIsLoading(false)
        console.log(err)
      })
  }
  useEffect(()=>{
    getUserDetails()
    getUserOrders()
  },[])
  return (
    <>
      <section className={styles['client-details']}>
        <Container>
          <div className={styles['client-details__header-wrapper']}>
            {/* <Link to='/dashboard/add-order' className={styles['client-details__header-link']}>
            </Link> */}
            
            {/* <a href={whtsAppConnect(item?.whatsapp_number)} target='_blank' className={styles['client-details__header-link']}>
                {t('Share On Client Whatsapp')}
                <WhatsappIcon className={styles['client-details__header-link-icon']}/>
            </a> */}
            {/* <button className={styles['client-details__header-link']}>
              {t('Export PDF')}
            </button> */}
          </div>
          <Row>
            <Col lg='7' className='mb-5'>
              <div className={styles['client-details__info-wrapper']}>
                
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Name')}</p>
                  <p className={styles['client-details__info-value']}>{item?.name}</p>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Phone Number')}</p>
                  <button role='button' onClick={()=>{window.open(`tel:${item?.phone_number}`, '_self')}} className={styles['client-details__info-link']}>
                    {item?.phone_number}
                  </button>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Address')}</p>
                  <p className={styles['client-details__info-value']}>{item?.address}</p>
                </div>
                {/* <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Subscribe Date')}</p>
                  <p className={styles['client-details__info-value']}>22/8/2023</p>
                </div> */}
                {/* <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('joining Date')}</p>
                  <p className={styles['client-details__info-value']}>{item?.joining_date}</p>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('joining End Date')}</p>
                  <p className={styles['client-details__info-value']}>{item?.joining_end_date}</p>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Subscribe Period')}</p>
                  <p className={styles['client-details__info-value']}>{item?.subscription_period}</p>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Subscription Amount')}</p>
                  <p className={styles['client-details__info-value']}>{item?.subscription_price} {t('AED')}</p>
                </div> */}
                {/* <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Device Type')}</p>
                  <p className={styles['client-details__info-value']}>نوع الجهاز 2</p>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Warranty Status')}</p>
                  <p className={styles['client-details__info-value']}>خارج الضمان</p>
                </div>
                <div className={styles['client-details__info-item-wrapper']}>
                  <p className={styles['client-details__info-title']}>{t('Warranty End Date')}</p>
                  <p className={styles['client-details__info-value']}>منتهي</p>
                </div> */}
              </div>
            </Col>

            <Col lg='5' className='mb-5'>
              <div className={styles['client-details__info-receipts-wrapper']}>
                <h2 className={styles['client-details__info-receipts-title']}>{t('Documents')}</h2>
                <div className={styles['client-details__info-receipts-imgs-wrapper']}>
                    {
                      item?.additional_documents?.map((img:any,index:number)=>(
                        index<3&&
                        <div className={styles['client-details__info-receipts-img-wrapper']} key={index}>
                          <img src={img} alt='Receipts' className={styles['client-details__info-receipts-img']}/>
                        </div>
                      ))
                    }
                    {
                      item?.additional_documents?.length>3&&
                      <div className={styles['client-details__info-receipts-img-wrapper']}>
                        <img src={item?.additional_documents?.[3]} alt='Receipts' className={styles['client-details__info-receipts-img']}/>
                        <Link to={`/technicians/all-documents/${item?.id}`} className={styles['client-details__info-receipts-total-wrapper']}>+ {item?.additional_documents?.length}</Link>
                      </div>
                    }
                </div>
              </div>
            </Col>

            <Col lg='7' className='mb-5'>
              <div className={styles['client-details__table-wrapper']}>
                <div className={styles['client-details__table-header-wrapper']}>
                  <h2 className={styles['client-details__table-title']}>{t('Orders')}</h2>
                  {/* {
                    user?.permission_type=='admin' &&
                    <Link to='/orders/add-order' className={styles['client-details__table-link']}>
                      {t('Add Order')}
                      <PlusIcon className={styles['client-details__table-link-icon']}/>
                    </Link>
                  } */}
                </div>
                <AllClientOrdersTable items={orders}/>
              </div>
            </Col>

            <Col lg='5' className='mb-5'>
              <div className={styles['client-details__table-wrapper']}>
                <div className={styles['client-details__table-header-wrapper']}>
                  <h2 className={styles['client-details__table-title']}>{t('Testments')}</h2>
                </div>
                <AllTecnicnianTestamentsTable items={item?.testments}/>
              </div>
            </Col>

          </Row>
        </Container>
      </section>
    </>
  )
}

export default TecnicianDetails
import styles from './MaintenanceOrdersReportsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './MaintenanceOrdersReportsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import { useEffect, useState } from 'react'
import { axiosConfig } from 'utils/axiosConfig'
function MaintenanceOrdersReportsTable(props:any) {
    const {month,year,searchTerm} = props
    const {t} = useTranslation()
    const [items,setItems]=useState([])
    const [currentPage,setCurrentPage]=useState(1)
    const [pages,setPages]=useState(null)
    
    function getAllItems(){
        axiosConfig.get(`/orders/all-users-orders-without-pagination?page=${currentPage}&order_type=maintenance&month=${month}&year=${year}&name=${searchTerm}`,{
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        }).then((res:any)=>{
            setItems(res.data?.data)
            // setPages(res.data?.data)
        }).catch((err:any)=>{
            console.log(err)
        })
    }
    useEffect(()=>{
        getAllItems()
    },[month,year,searchTerm])
    return (
        <>
            
        <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
            <thead>
                {/* <th>{t('Order No')}</th> */}
                <th>{t('Client Name')}</th>
                <th>{t('Technician Name')}</th>
                <th>{t('Product Name')}</th>
                <th>{t('Order Price')}</th>
                <th>{t("Phone Number")}</th>
                <th>{t('Order Status')}</th>
                {/* <th>{t('Whatsapp Number')}</th> */}
                {/* <th>{t('Address')}</th> */}
                {/* <th>{t('Testaments')}</th> */}
                <th>{t('Issue Details')}</th>
            </thead>
            <tbody>
                {
                    items?.map((item:any,index:number)=>(
                        <tr key={item?.id}>
                            {/* <td>
                                <Link to={`/orders/order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                    {item?.item_number}
                                </Link>
                            </td> */}
                            <td>
                                <Link to={`/our-clients/client-details/${item?.user?.id}`} className={styles['appointments__table-link']}>
                                    {item?.user?.name}
                                </Link>
                            </td>
                            <td>
                                {item?.provider?.name}
                            </td>
                            <td>
                                <p className={styles['appointments__table-name']}>{item?.product?.title}</p>
                            </td>
                            <td>
                                <p className={styles['appointments__table-name']}>{item?.order_required_price} {t('AED')}</p>
                            </td>
                            <td>
                                <p className={styles['appointments__table-name']}>{item?.user?.phone_number}</p>
                            </td>
                            <td>
                                <p className={styles['appointments__table-name']}>
                                    {
                                        item?.status ==1 ?t('New'):
                                        item?.status ==2 ?t('Completed'):
                                        item?.status ==3 ?t('Cancelled'):
                                        item?.status ==4 ?t('Reasigned'):
                                        ''
                                    }
                                </p>
                            </td>
                            {/* <td>
                                <p className={styles['appointments__table-name']}>{item?.user?.whatsapp_number}</p>
                            </td>
                            <td>
                                <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${item?.user_latitude},${item?.user_longitude}`} className={styles['form__content-form-input']}>
                                    <GoogleIcon className={styles['form__content-form-input-icon']}/>
                                </a>
                            </td> */}
                            {/* <td>
                                <Link to={`/orders/order-testments/${item?.id}`} className={styles['appointments__table-link']}>
                                    {t('Testaments')}
                                </Link>
                            </td> */}
                            <td>
                                {item?.fixing_report}
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
            {/* <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Technician Name')}</th>
                    <th>{t('Client Name')}</th>
                    <th>{t('Order Status')}</th>
                    <th>{t("Phone Number")}</th>
                    <th>{t('Whatsapp Number')}</th>
                    <th>{t('Address')}</th>
                    <th>{t('Issue Details')}</th>
                    <th>{t('Used Parts')}</th>
                </thead>
                <tbody>
                    {
                        items && items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <Link to={`/orders/order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                        {item?.item_number}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/our-clients/client-details/${item?.user?.id}`} className={styles['appointments__table-link']}>
                                        {item?.user?.name}
                                    </Link>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>
                                        {
                                            item?.status ==1 ?'Pending':
                                            item?.status ==2 ?'Processing':
                                            item?.status ==3 ?'Finished':
                                            item?.status ==4 ?'Repairing':
                                            item?.status ==5 ?'Cancelled':
                                            ''
                                        }
                                    </p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.address}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.order_type}</p>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.provider?.name||'---'}</p>
                                </td>
                            </tr>
                        ))
                    }
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>سالم محمد عبدالله</p>
                        </td>
                        <td>
                            <Link to='/our-clients/client-details/dasdasas' className={styles['appointments__table-link']}>
                                سالم على
                            </Link>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>نوع الجهاز 1</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <a target='_blank' href='https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai' className={styles['form__content-form-input']}>
                                <GoogleIcon className={styles['form__content-form-input-icon']}/>
                            </a>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...</p>
                        </td> 
                        <td>
                            <p className={styles['appointments__table-name']}>5 قطع غيار 1</p>
                        </td> 
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>سالم محمد عبدالله</p>
                        </td>
                        <td>
                            <Link to='/our-clients/client-details/dasdasas' className={styles['appointments__table-link']}>
                                سالم على
                            </Link>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>نوع الجهاز 1</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <a target='_blank' href='https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai' className={styles['form__content-form-input']}>
                                <GoogleIcon className={styles['form__content-form-input-icon']}/>
                            </a>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...</p>
                        </td> 
                        <td>
                            <p className={styles['appointments__table-name']}>5 قطع غيار 1</p>
                        </td> 
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>سالم محمد عبدالله</p>
                        </td>
                        <td>
                            <Link to='/our-clients/client-details/dasdasas' className={styles['appointments__table-link']}>
                                سالم على
                            </Link>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>نوع الجهاز 1</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <a target='_blank' href='https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai' className={styles['form__content-form-input']}>
                                <GoogleIcon className={styles['form__content-form-input-icon']}/>
                            </a>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...</p>
                        </td> 
                        <td>
                            <p className={styles['appointments__table-name']}>5 قطع غيار 1</p>
                        </td> 
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>سالم محمد عبدالله</p>
                        </td>
                        <td>
                            <Link to='/our-clients/client-details/dasdasas' className={styles['appointments__table-link']}>
                                سالم على
                            </Link>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>نوع الجهاز 1</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <a target='_blank' href='https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai' className={styles['form__content-form-input']}>
                                <GoogleIcon className={styles['form__content-form-input-icon']}/>
                            </a>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...</p>
                        </td> 
                        <td>
                            <p className={styles['appointments__table-name']}>5 قطع غيار 1</p>
                        </td> 
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>سالم محمد عبدالله</p>
                        </td>
                        <td>
                            <Link to='/our-clients/client-details/dasdasas' className={styles['appointments__table-link']}>
                                سالم على
                            </Link>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>نوع الجهاز 1</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <a target='_blank' href='https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai' className={styles['form__content-form-input']}>
                                <GoogleIcon className={styles['form__content-form-input-icon']}/>
                            </a>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...</p>
                        </td> 
                        <td>
                            <p className={styles['appointments__table-name']}>5 قطع غيار 1</p>
                        </td> 
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>سالم محمد عبدالله</p>
                        </td>
                        <td>
                            <Link to='/our-clients/client-details/dasdasas' className={styles['appointments__table-link']}>
                                سالم على
                            </Link>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>نوع الجهاز 1</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <a target='_blank' href='https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai' className={styles['form__content-form-input']}>
                                <GoogleIcon className={styles['form__content-form-input-icon']}/>
                            </a>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...</p>
                        </td> 
                        <td>
                            <p className={styles['appointments__table-name']}>5 قطع غيار 1</p>
                        </td> 
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>سالم محمد عبدالله</p>
                        </td>
                        <td>
                            <Link to='/our-clients/client-details/dasdasas' className={styles['appointments__table-link']}>
                                سالم على
                            </Link>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>نوع الجهاز 1</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <a target='_blank' href='https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai' className={styles['form__content-form-input']}>
                                <GoogleIcon className={styles['form__content-form-input-icon']}/>
                            </a>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...</p>
                        </td> 
                        <td>
                            <p className={styles['appointments__table-name']}>5 قطع غيار 1</p>
                        </td> 
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>سالم محمد عبدالله</p>
                        </td>
                        <td>
                            <Link to='/our-clients/client-details/dasdasas' className={styles['appointments__table-link']}>
                                سالم على
                            </Link>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>نوع الجهاز 1</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <a target='_blank' href='https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai' className={styles['form__content-form-input']}>
                                <GoogleIcon className={styles['form__content-form-input-icon']}/>
                            </a>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...</p>
                        </td> 
                        <td>
                            <p className={styles['appointments__table-name']}>5 قطع غيار 1</p>
                        </td> 
                    </tr>
                    <tr>
                        <td>
                            <p className={styles['appointments__table-name']}>سالم محمد عبدالله</p>
                        </td>
                        <td>
                            <Link to='/our-clients/client-details/dasdasas' className={styles['appointments__table-link']}>
                                سالم على
                            </Link>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>نوع الجهاز 1</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>971 558858585</p>
                        </td>
                        <td>
                            <a target='_blank' href='https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai' className={styles['form__content-form-input']}>
                                <GoogleIcon className={styles['form__content-form-input-icon']}/>
                            </a>
                        </td>
                        <td>
                            <p className={styles['appointments__table-name']}>لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...</p>
                        </td> 
                        <td>
                            <p className={styles['appointments__table-name']}>5 قطع غيار 1</p>
                        </td> 
                    </tr>
                </tbody>
            </Table> */}
        </>
    )
}

export default MaintenanceOrdersReportsTable
import styles from './TechnicinasReportsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './TechnicinasReportsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useState } from 'react'
function TechnicinasReportsTable(props:any) {
    const {month,year,searchTerm}= props
    const {t} =useTranslation()
    const [items,setItems]=useState([])
    const [currentPage,setCurrentPage]=useState(1)
    const [pages,setPages]=useState(null)
    function getAllItems(){
        axiosConfig.get(`/provider/all-providers-without-pagination-full-data?month=${month}&year=${year}&name=${searchTerm}`,{
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(res=>{
            setItems(res.data?.data)
            // setPages(res.data?.data?.pages)
        }).catch(err=>{
            console.log(err)
        })
    }
    useEffect(()=>{
        getAllItems()
    },[month,year,searchTerm])
    return (
        <>
            
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Technician Name')}</th>
                    <th>{t('Finished Requests')}</th>
                    <th>{t('Requests In Progress')}</th>
                    <th>{t('New Orders')}</th>
                    <th>{t('Maintenance Orders')}</th>
                    {/* <th>{t("Technician Place")}</th> */}
                    {/* <th>{t('Testament Received')}</th> */}
                </thead>
                <tbody>
                {
                    items && items?.map((item:any,index:number)=>(
                        <tr key={item?.id}>
                            <td>
                                <Link to={`/technicians/technician-details/${item?.id}`} className={styles['appointments__table-link']}>{item?.name}</Link>
                                {/* <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.name} </p> */}
                            </td>
                            <td>
                                <Link to={`/orders?technician_id=${item?.id}&order_status=2`} className={styles['appointments__table-link']}> {item?.finished_orders_count} {t('Orders')} </Link>
                                {/* <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.finished_orders_count} {t('Order')} </p> */}
                            </td>
                            <td>
                                <Link to={`/orders?technician_id=${item?.id}&order_status=1`} className={styles['appointments__table-link']}> {item?.processing_orders_count} {t('Orders')} </Link>
                                {/* <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.processing_orders_count} {t('Orders')} </p> */}
                            </td>
                            <td>
                                <Link to={`/orders?technician_id=${item?.id}&order_type=new`} className={styles['appointments__table-link']}> {item?.new_orders_count} {t('Orders')} </Link>
                                {/* <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.new_orders_count} {t('Orders')} </p> */}
                            </td>
                            <td>
                                <Link to={`/orders?technician_id=${item?.id}&order_type=maintenance`} className={styles['appointments__table-link']}> {item?.maintenance_orders_count} {t('Orders')} </Link>
                                {/* <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.maintenance_orders_count} {t('Orders')} </p> */}
                            </td>
                            {/* <td>
                                <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.address} </p>
                            </td> */}
                            {/* <td>
                                <Link to={`/technicians/testaments/${item?.id}`} className={styles['appointments__table-link']}>{t('Details Of The testaments')}</Link>
                            </td> */}
                        </tr>
                ))}
                </tbody>
            </Table>
            {/* <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Technician Name')}</th>
                    <th>{t('Finished Requests')}</th>
                    <th>{t('Requests In Progress')}</th>
                    <th>{t("Technician Place")}</th>
                    <th>{t('Testament Received')}</th>
                    <th>{t('Referring Requests To The Technician')}</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Link to='/' className={styles['appointments__table-link']}>سالم محمد عبدالله</Link>
                        </td>
                        <td>
                            <p className={`${styles['appointments__table-name']} text-capitalize`}> 210 {t('Order')} </p>
                        </td>
                        <td>
                            <p className={`${styles['appointments__table-name']} text-capitalize`}> 210 {t('Orders')} </p>
                        </td>
                        <td>
                            <Link to='/' className={styles['appointments__table-link']}>18 ش/الصالح دبي- الأمارات</Link>
                        </td>
                        <td>
                            <Link to='/' className={styles['appointments__table-link']}>18 ش/الصالح دبي- الأمارات</Link>
                        </td>
                        <td>
                            <Link to='testaments/dasdas' className={styles['appointments__table-link']}>{t('Details Of The testaments')}</Link>
                        </td>
                        <td>
                            <Link to='/' className={styles['appointments__table-link']}>{t('Technical Details')}</Link>
                        </td>
                        
                    </tr>
                </tbody>
            </Table> */}
        </>
    )
}

export default TechnicinasReportsTable
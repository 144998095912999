import { useTranslation } from 'react-i18next'
import styles from './UpdateOrderPayment.module.css'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
function UpdateOrderPayment() {
  const {t} =useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [paymentMethod,setPaymentMethod] =useState('cash')
  const [paidAmount,setPaidAmount] =useState('')
  const [name,setName] =useState('')
  const [receivedCount,setReceivedCount] =useState('')
  const [isLoading,setIsLoading] =useState(false)
  
  function validateInputs(){
    let status =true
    if(!paymentMethod){
      toast.error(t('Payment Method Is Required'))
      status=false
    }
    if(!paidAmount){
      toast.error(t('Paid Amount Is Required'))
      status=false
    }
    return status
  }

  function addUpdateItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('payment_method',paymentMethod)
    formData.append('paid_amount',paidAmount)
    formData.append('order_id',(params?.id || ""))
    
    axiosConfig.post(`/orders/update-payment-for-order`,formData,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setIsLoading(false)
      toast.success(t(`Order Payment Updated Successfully`))
      navigate(`/orders/order-details/${params?.id}`)
    }).catch(err=>{
      setIsLoading(false)
      toast.error(t('Something went wrong'))
    })
  }

  return (
    <>
      <section className={styles['form-wrapper']}>
        <Container>
          <div className={styles['form__content-wrapper']}>
            <h1 className={styles['form__content-title']}>{t('Update Payment')}</h1>
            <form className={styles['form__content-form']}>
              <div className={styles['form__content-form-inputs-wrapper']}>
                
                <input 
                  type='number'
                  className={styles['form__content-form-input']}
                  placeholder={`${t('Paid Amount')}`}
                  value={paidAmount}
                  onChange={(e)=>setPaidAmount(e.target.value)}
                />
                <select className={styles['form__content-form-input']}
                  onChange={(e)=>{setPaymentMethod(e.target.value)}}
                  value={paymentMethod}
                >
                  <option value=''>{t('Choose Payment Method')}</option>
                  <option value='cash'>{t('Cash')}</option>
                  <option value='machine'>{t('Machine')}</option>
                  <option value='bank_deposite'>{t('Bank Deposite')}</option>
                  <option value='cheque'>{t('Cheque')}</option>
                  <option value='taby'>{t('Taby')}</option>
                  <option value='tab'>{t('Tab')}</option>
                  <option value='free'>{t('Free')}</option>
                </select>
              </div>
              
              <button 
                type='button' 
                className={styles['form__content-form-submit']}
                onClick={addUpdateItem} 
                disabled={isLoading}>
                {
                  !isLoading?t('Update Payment')
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            </form>
          </div>
        </Container>
        
      </section>
    </>
  )
}

export default UpdateOrderPayment
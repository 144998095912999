import { useTranslation } from 'react-i18next'
import styles from './GuestOrders.module.css'
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import AllClientsTable from 'components/Tables/AllClientsTable/AllClientsTable'
import AllOrdersTable from 'components/Tables/AllOrdersTable/AllOrdersTable'
import GuestOrdersTable from 'components/Tables/GuestTables/GuestOrdersTable/GuestOrdersTable'
function GuestOrders() {
  const {t} =useTranslation()
  
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const [itemsCount,setItemsCount]=useState('0')
  const [orderType,setorderType]=useState('new')
  const [searchTerm,setSearchTerm]=useState('')
  const [items,setItems]=useState([])
  const [providers,setProviders]=useState([])
  const [currentPage,setCurrentPage]=useState(1)
  const [pages,setPages]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  
  function getAllItems(){
      setIsLoading(true)
      axiosConfig.get(`/orders/all-users-orders?page=${currentPage}&order_type=${orderType}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            
        }
    }).then(res=>{
        setIsLoading(false)
        setItems(res.data?.data?.data)
        setPages(res.data?.data?.pages)
        let deletedNumber:any = [...Array(res.data.data.count)]
        setItemsCount(res.data.data.count)
        setIsDeleting(deletedNumber)
      }).catch(err=>{
        setIsLoading(false)
        console.log(err)
      })
  }
  useEffect(()=>{
    getAllItems()
  },[currentPage,searchTerm,orderType])
  return (
    <>
      <section className={styles['page-wrapper']}>
        <Container>
          <div className={styles['page-header']}>
            <div className={styles['page-header__top-wrapper']}>
              <h1 className={styles['page-header__title']}>
                {t('Orders')}
                <span className={styles['page-header__title-count']}>{itemsCount}</span>
              </h1>
              <select 
              className={styles['form__content-form-input']}
              value={orderType}
              onChange={(e)=>setorderType(e.target.value)}
              >
                <option value='new'>{t('New Orders')}</option>
                <option value='maintenance'>{t('Maintenance Orders')}</option>
              </select>
              {/* {
                user?.permission_type=='admin' && */}
                  <Link to='add-order' className={styles['page-header__link']}>
                    {t('Add Order')}
                    <PlusIcon/>
                  </Link>
              {/* } */}
            </div>
          </div>
        </Container>
        
        <GuestOrdersTable 
          items={items}
        />
      </section>
    </>
  )
}

export default GuestOrders
// import { useTranslation } from 'react-i18next'
// import styles from './GuestOrders.module.css'
// import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
// import { Link } from 'react-router-dom'
// import { useEffect, useState } from 'react'
// import { toast } from 'react-toastify'
// import { axiosConfig } from 'utils/axiosConfig'
// import { useSelector } from 'react-redux'
// import { Container } from 'react-bootstrap'
// import AllClientsTable from 'components/Tables/AllClientsTable/AllClientsTable'
// import AllOrdersTable from 'components/Tables/AllOrdersTable/AllOrdersTable'
// import GuestOrdersTable from 'components/Tables/GuestTables/GuestOrdersTable/GuestOrdersTable'
// function GuestOrders() {
//   const {t} =useTranslation()
//   const user =useSelector((state:any)=>state?.GlobalReducer?.user)

//   const [searchTerm,setSearchTerm]=useState('')
//   const [items,setItems]=useState([])
//   const [currentPage,setCurrentPage]=useState(1)
//   const [pages,setPages]=useState(null)
//   const [isLoading,setIsLoading]=useState(false)
//   const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
//   function getAllItems(){
//       setIsLoading(true)
//       axiosConfig.get(`/doctors/all-doctors-with-pagination?page=${currentPage}`,{
//         headers: {
//             "Authorization": `Bearer ${localStorage.getItem('token')}`,
//             
//         }
//     }).then(res=>{
//           setIsLoading(false)
//           setItems(res.data?.data?.data)
//           setPages(res.data?.data?.pages)
//           let deletedNumber:any = [...Array(res.data.data.count)]
//           setIsDeleting(deletedNumber)
//       }).catch(err=>{
//           setIsLoading(false)
//           console.log(err)
//       })
//   }
//   function deleteItem(deltedId:String ,index:any){
//     let modifiedDelete:[Boolean]=[...isDeleting]
//       modifiedDelete[index]=true
//     setIsDeleting(modifiedDelete)
//       axiosConfig.delete(`/doctors/delete-doctor/${deltedId}`,{
//         headers: {
//             "Authorization": `Bearer ${localStorage.getItem('token')}`
//         }
//     }).then(res=>{
//             let modifiedDelete:[Boolean]=[...isDeleting]
//             modifiedDelete[index]=false
//             setIsDeleting(modifiedDelete)
//             toast.success(t('Item Deleted Successfully'))
//             getAllItems()
//       }).catch(err=>{
//           let modifiedDelete:[Boolean]=[...isDeleting]
//           modifiedDelete[index]=false
//           setIsDeleting(modifiedDelete)
//           toast.error(err?.response?.data?.message)
//           console.log(err)
//       })
//   }
//   useEffect(()=>{
//     getAllItems()
//   },[currentPage,searchTerm])
//   return (
//     <>
//       <section className={styles['page-wrapper']}>
//         <Container>
//           <div className={styles['page-header']}>
//             <div className={styles['page-header__top-wrapper']}>
//               <select className={styles['form__content-form-input']}>
//                 <option value=''>{t('Maintenance Orders')}</option>
//                 <option value=''>{t('New Orders')}</option>
//               </select>
//               <Link to='add-order' className={styles['page-header__link']}>
//                 {t('Add Order')}
//                 <PlusIcon/>
//               </Link>
//             </div>
//           </div>
//         </Container>
//         <GuestOrdersTable/>
        
//       </section>
//     </>
//   )
// }

// export default GuestOrders
import styles from './GuestOrdersTable.module.css'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './GuestOrdersTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import { useSelector } from 'react-redux'
function GuestOrdersTable(props:any) {
    const {t}= useTranslation()
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const { items  } = props
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Order No')}</th>
                    {/* <th>{t('Client Name')}</th> */}
                    <th>{t('Technician')}</th>
                    {/* <th>{t('Order Status')}</th> */}
                    {/* <th>{t("Phone Number")}</th> */}
                    <th>{t("Created At")}</th>
                    {/* <th>{t('Whatsapp Number')}</th> */}
                    {/* <th>{t('Address')}</th> */}
                    <th></th>
                </thead>
                <tbody>
                    {
                        items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <Link to={`/orders/order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                        {item?.item_number}
                                    </Link>
                                </td>
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.provider?.name||'---'}</p>
                                </td>
                                {/* <td>
                                    <Link to={`/our-clients/client-details/${item?.user?.id}`} className={styles['appointments__table-link']}>
                                        {item?.user?.name}
                                    </Link>
                                </td> */}
                                {/* <td>
                                    <p className={styles['appointments__table-name']}>
                                        {
                                            item?.status ==1 ?t('New'):
                                            item?.status ==2 ?t('Completed'):
                                            item?.status ==3 ?t('Cancelled'):
                                            item?.status ==4 ?t('Reasigned'):
                                            ''
                                        }
                                    </p>
                                </td> */}
                                {/* <td>
                                    <p className={styles['appointments__table-name']}>{item?.provider?.phone_number||'---'}</p>
                                </td> */}
                                <td>
                                    <p className={styles['appointments__table-name']}>{item?.created_at}</p>
                                </td>
                                {/* <td>
                                    <p className={styles['appointments__table-name']}>{item?.user?.whatsapp_number}</p>
                                </td> */}
                                {/* <td>
                                    <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${item?.user_latitude},${item?.user_longitude}`} className={styles['form__content-form-input']}>
                                        <GoogleIcon className={styles['form__content-form-input-icon']}/>
                                    </a>
                                </td> */}
                                <td>
                                    <Link to={`order-details/${item?.id}`} className={styles['appointments__table-link']}>
                                        {t('Order Details')}
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default GuestOrdersTable
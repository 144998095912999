import { Navbar } from 'react-bootstrap'
import styles from './GuestNavBar.module.css'
import './GuestNavBar.css'
import { useTranslation } from 'react-i18next'
// import AfyaLogo from 'assets/imgs/AfyaLogo.png'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import userImg from 'assets/imgs/userImg.png'
import {ReactComponent as MenuIcon} from 'assets/icons/menuIcon.svg'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { changeTokenAction, changeUserDetails } from 'reduxStore/Global/GlobalActions'
import { useSelector } from 'react-redux'
import blankImage from 'assets/imgs/blank.png'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'

interface CustomComponentPropsType{
    setIsNavbarOpen:any,
    patient?:any,
    isNavbarOpen:boolean
}
function GuestNavBar(props:CustomComponentPropsType) {
    const {isNavbarOpen ,setIsNavbarOpen,patient} =props
    const {t} =useTranslation()
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    function logout(){
        navigate('/')
        localStorage.removeItem('token')        
        dispatch(changeTokenAction(''))
        dispatch(changeUserDetails({}))
    }
    return (
        <Navbar className={`${styles['navbar']} ${isNavbarOpen?styles['navbar--open']:''}`}>
            <ul className={`${styles['navbar__nav-list']} navbar__nav-listt`}>
                <li className={`${styles['navbar__nav-item--responsive']} ${styles['navbar__nav-item--absolute2']}`}>
                    <button className={styles['navbar__responsive-button']} onClick={()=>setIsNavbarOpen(false)}>
                        <MenuIcon className={styles['navbar__responsive-button']}/>
                    </button>
                </li>
                <li >
                    <div className={styles['sidebar__client-info-wrapper']}>
                        <img src={patient?.image?patient?.image:blankImage} className={styles['sidebar__client-info-img']}/>
                        <div className={styles['sidebar__client-info-content-wrapper']}>
                            <p className={styles['sidebar__client-info-name']}>{user?.name}</p>
                        </div>
                    </div>
                </li>
                <div className={styles["navbar__nav-language"]}>
                    <ChangeLangueList />
                </div>
                <li>
                    <NavLink to='/guest/orders' className={styles['navbar__nav-link-wrapper']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Previous Orders')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/guest/payments-and-installments' className={styles['navbar__nav-link-wrapper']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Payments & Installments')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/guest/personal-information' className={styles['navbar__nav-link-wrapper']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Personal Information')}</span>
                    </NavLink>
                </li>
                
                <li>
                    <button type='button' onClick={logout} className={styles['navbar__nav-link-logout']}>
                        <span className={styles['navbar__nav-link-text']}>{t('Logout')}</span>
                    </button>
                </li>
            </ul>
        </Navbar>
    )
}

export default GuestNavBar